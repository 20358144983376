import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'choose-time-popup',
  templateUrl: './choose-time-popup.component.html',
  styleUrls: ['./choose-time-popup.component.scss'],
})
export class ChooseTimePopupComponent  implements OnInit {
  @Output() isChooseTimePopupClose: EventEmitter<any> = new EventEmitter();

  isMobile!: boolean;
  isTablet!: boolean;
  isDesktopDevice!: boolean;
  deviceInfo: import("ngx-device-detector").DeviceInfo | undefined;

  constructor(private deviceService: DeviceDetectorService, private router: Router) { }
  ngOnInit() {
    this.epicFunction();
  }

  epicFunction() {
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    console.log("Mobile : " +this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log("Tablet : " +this.isTablet);  // returns if the device us a tablet (iPad etc)
    console.log("Desktop : " +this.isDesktopDevice); // returns if the app is running on a Desktop browser.
  }
  isSelectSlot:boolean = false;
  selectSlot(){
    this.isSelectSlot = !this.isSelectSlot;
  }
  closeChooseTimePopup(){
    this.isChooseTimePopupClose.emit(false);
  }
}
