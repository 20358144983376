import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pre-registration-edit-or-add-insurance-card',
  templateUrl: './pre-registration-edit-or-add-insurance-card.component.html',
  styleUrls: ['./pre-registration-edit-or-add-insurance-card.component.scss'],
})
export class PreRegistrationEditOrAddInsuranceCardComponent  implements OnInit {

  isDesktop: boolean;
  breakpoint: any = window.matchMedia('(min-width: 767px)');
  constructor() { 
    this.isDesktop = !!this.breakpoint.matches;
  }

  ngOnInit() {}
  public registerForm: FormGroup = new FormGroup({
   
   })
  isopenReplaceinsurancePopup:boolean=false
  openReplaceinsurancePopup() {
   this.isopenReplaceinsurancePopup=true;
  }
  onclose() {
    this.isopenReplaceinsurancePopup = false;
  }
  isfrontphoto:boolean=false;
  istakefrontphoto:boolean=true;
  frontphoto(){
    this.isfrontphoto=true;
    this.istakefrontphoto=false;
  }

  isbackphoto:boolean=false;
  istakebackphoto:boolean=true;
  backphoto(){
    this.isbackphoto=true;
    this.istakebackphoto=false;
  }

  openblank(){
  this.istakefrontphoto=true;
  this.isfrontphoto=false;
  }
  openbackblank(){
    this.istakebackphoto=true;
    this.isbackphoto=false;
    }
}
