<div class="clinic_practice_list_main main_container">
  <div class="clinic_practice_list_content">
    <div class="clinic_practice_list_heading px-10 py-20 borderBottomGray20 text-center">
      <ion-text class="h3-Montserrat" color="gray100">Practice List</ion-text>
    </div>
    <div class="clinic_practice_list py-20 px-10">
      <div class="d-flex align-items-center mb-20 px-20 ">
        <ion-searchbar placeholder="Search Provider" mode="q" ></ion-searchbar>
      </div>
      <div class="pratice_list px-20">
        <div class="pratice_name mb-10 cursor d-flex align-items-center" [class.active]="practice.active"  (click)="activePracticeList(practice)" *ngFor="let practice of practiceName; let index = index">
          <input type="radio" class="mr-5" kendoRadioButton [checked]="practice.active" hidden/>
          <ion-text class="h5-regular" color="gray100">{{practice.name}}</ion-text>
        </div>
        <!-- <div class="pratice_name mb-10 cursor" [class.active]="isActive"  (click)="activePracticeList()">
          <input type="radio" class="mr-5" kendoRadioButton [checked]="isActive" hidden/>
          <ion-text class="h5-regular" color="gray100">Practice name 2</ion-text>
        </div>
        <div class="pratice_name mb-10 cursor">
          <ion-text class="h5-regular" color="gray100">Practice name 3</ion-text>
        </div>
        <div class="pratice_name mb-10 cursor">
          <ion-text class="h5-regular" color="gray100">Practice name 4</ion-text>
        </div>
        <div class="pratice_name mb-10 cursor">
          <ion-text class="h5-regular" color="gray100">Practice name 5</ion-text>
        </div> -->
      </div>
    </div>
    <div class="clinic_practice_list_footer px-20 py-30 text-center borderTopGray20">
      <ion-button fill="solid" color="white" class="filled-icon-btn" routerLink="/clinic-dashboard" [disabled]='!isActive'>
        <ion-text class="capitalize roboto">Connect</ion-text>
      </ion-button>
    </div>
  </div>
</div>