import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StepType } from '@progress/kendo-angular-layout';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-confirm-appointment',
  templateUrl: './confirm-appointment.component.html',
  styleUrls: ['./confirm-appointment.component.scss'],
})
export class ConfirmAppointmentComponent  implements OnInit {

  public stepType: StepType = "indicator";
  public stepTypes: Array<StepType> = ["indicator", "label", "full"];
  public current = 0;

  isMobile!: boolean;
  isTablet!: boolean;
  isDesktopDevice!: boolean;
  deviceInfo: import("ngx-device-detector").DeviceInfo | undefined;

  constructor(private deviceService: DeviceDetectorService, private router: Router) { }

  ngOnInit() {
    this.epicFunction();
    this. updateMobileBtn();
  }
  epicFunction() {
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    console.log("Mobile : " +this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log("Tablet : " +this.isTablet);  // returns if the device us a tablet (iPad etc)
    console.log("Desktop : " +this.isDesktopDevice); // returns if the app is running on a Desktop browser.
  }
  public steps = [
    { label: "First step", isValid: true },
    { label: "Second step", isValid: true },
    { label: "Third step", isValid: true,},
  ];

  public prev(): void {
    this.router.navigate(['/app/patient-appointment-time']);
    if (this.current > 0) {
      this.current -= 1;
    }
  }

  public next(): void {
    this.router.navigate(['/app/booked-appointment']);
    if (this.current < this.steps.length - 1) {
      this.current += 1;
    }
  }

  isbookAppointment: boolean = false;

  onChange(e:any) {
    this.isbookAppointment = true;
    console.log(e)
  }

  isChooseTimePopup:boolean = false;
  openChooseTimePopup(){
    if(this.isDesktopDevice || this.isTablet){
      this.isChooseTimePopup = true;
    }
    if(this.isMobile == true){
      this.router.navigate(['/app/choose-time']);
    }
  }
  closeChooseTimePopup(e:any){
    if(e == false){
      this.isChooseTimePopup = false;
    }
    
  }
  public btn1text: string = '';
  public btn2text: string = '';
  updateMobileBtn(){
    if(this.isDesktopDevice || this.isTablet){
      this.btn1text = "Patient information";
      this.btn2text = "Book appointment";
    }else{
      this.btn1text = "Back";
      this.btn2text = "Book Now";
    }
  }
}
