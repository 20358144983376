<!-- <main-header></main-header> -->
<div class="patientCardMain scroll_main">
  <kendo-dialog-titlebar class="text-center" *ngIf="isMobile">
    <div class="ion-align-items-center w-100">
      <ion-text class="h3-M tabletFont mb-30" color="gray100 ">Your insurance card photo</ion-text>
    </div>
  </kendo-dialog-titlebar>
  <div class="patientCardBody scroll_body">
    <div class="patientCardImgBody d-flex align-items-center ">
      <div class="patientCardDelete pointer">
        <ion-icon src="../../../../../assets/svg/trash-2.svg" class="h3 ion-color-error60"></ion-icon>
      </div>
      <div class="patientCardNav pointer mr-20">
        <ion-icon class="ion-color-primary100" src="../../../../../assets/svg/arrow-left.svg"></ion-icon>
      </div>
      <div class="insurance_card">
        <div class="text-center d-flex flex-direction-column justify-content-center align-items-center mb-15 uppercase">
          <ion-text class="bodyM-txt pt-5 pb-8" color="gray100">Front</ion-text>
          <ion-img class="img-card" src="../../../../../assets/svg/Front_Card.svg"></ion-img>
        </div>
        <div class="text-center d-flex flex-direction-column justify-content-center align-items-center uppercase">
          <ion-text class="bodyM-txt pb-8" color="gray100">Back</ion-text>
          <ion-img class="img-card" src="../../../../../assets/svg/Back_Card.svg"></ion-img>
        </div>
      </div>
      <div class="patientCardNav pointer ml-20">
        <ion-icon class="ion-color-primary100" src="../../../../../assets/svg/arrow-right.svg"></ion-icon>
      </div>
    </div>
    <div class="upload-data d-flex flex-direction-column justify-content-center align-items-center pb-20">
      <div class="photo d-flex align-items-center py-15 pointer" (click)="openReplaceinsurancePopup()">
        <ion-icon class="bodyXL-txt pr-14" src="../../../../../assets/svg/camera.svg"></ion-icon>
        <ion-text class="bodyM-txt text-HLblue-color"><span class="bodyM-txt-bold">Take a new photos</span> of your
          card</ion-text>
      </div>

      <!-- <div class="photo d-flex align-items-center py-15 pointer" *ngIf="isMobile" routerLink="/app/Edit-or-Add-insurance-card">
        <ion-icon class="bodyXL-txt pr-14" src="../../../../../assets/svg/camera.svg"></ion-icon>
        <ion-text class="bodyM-txt text-HLblue-color"><span class="bodyM-txt-bold">Take a new photos</span> of your
          card</ion-text>
      </div> -->

      <div class="photo d-flex align-items-center py-10 pointer" (click)="openReplaceinsurancePopup()">
        <ion-icon class="bodyXL-txt pr-14" src="../../../../../assets/svg/upload.svg"></ion-icon>
        <ion-text class="bodyM-txt text-HLblue-color"><span class="bodyM-txt-bold">Upload</span> new photos of your
          card</ion-text>
      </div>

    </div>
  </div>
  <!-- <kendo-dialog-actions class="" *ngIf="isDesktopDevice || isTablet">
    <div class="py-10 row w-100 ion-justify-content-center align-items-center">
      <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn mr-20"
        (click)="closePatientCard()">
        <ion-text>close</ion-text>
      </ion-button>
      <ion-button fill="solid" expand="block" color="ion-color-white" class="filled-icon-btn capitalize info-btn">
        <ion-text>Done</ion-text>
      </ion-button>
    </div>
  </kendo-dialog-actions> -->
  <ion-footer class="ion-bg-unfocused-color page-bottom" *ngIf="isMobile">
    <div class="px-30 py-10 row w-100 ion-justify-content-center align-items-center">
      <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn mr-15"
      routerLink="/app/patient-insurance-popup">
        <ion-text>Cancel</ion-text>
      </ion-button>
      <ion-button fill="solid" expand="block" color="ion-color-white" class="filled-icon-btn capitalize info-btn">
        <ion-text>Done</ion-text>
      </ion-button>
    </div>
  </ion-footer>

  <div class="kendopopup upload-insu-card_popup">
    <kendo-dialog *ngIf="isopenReplaceinsurancePopup">
      <kendo-dialog-titlebar class="text-center" *ngIf="isDesktopDevice || isTablet">
        <div class="ion-align-items-center w-100">
          <ion-text class="h3-M tabletFont" color="gray100">Replace insurance card photo</ion-text>
        </div>
      </kendo-dialog-titlebar>
      <app-patient-insurance-add-edit-popup></app-patient-insurance-add-edit-popup>
      <kendo-dialog-actions>
        <div class="py-10 row w-100 ion-justify-content-center align-items-center">
          <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn mr-20"
            (click)="onclose()">
            <ion-text>Cancel</ion-text>
          </ion-button>
          <ion-button fill="solid" expand="block" color="ion-color-white" class="filled-icon-btn capitalize info-btn">
            <ion-text>Done</ion-text>
          </ion-button>
        </div>
      </kendo-dialog-actions>
    </kendo-dialog>
  </div>
</div>
<!-- <div class="py-10 row w-100 ion-justify-content-center align-items-center">
  <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn mr-20" (click)="onclose()">
    <ion-text>Cancel</ion-text>
  </ion-button>
  <ion-button fill="solid" expand="block" color="ion-color-white" class="filled-icon-btn capitalize info-btn">
    <ion-text>Done</ion-text>
  </ion-button>
</div> -->