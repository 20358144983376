import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';

@Component({
  selector: 'app-pre-registration-insurance-edit',
  templateUrl: './pre-registration-insurance-edit.component.html',
  styleUrls: ['./pre-registration-insurance-edit.component.scss'],
  // imports: [MatFormFieldModule, MatInputModule, MatDatepickerModule],

})

export class PreRegistrationInsuranceEditComponent  implements OnInit {


inactive: boolean = false;
ismorecontact:boolean=false;
hidebtn:boolean=true;
isDesktop: boolean;
// ismobile:boolean;
breakpoint: any = window.matchMedia('(min-width: 767px)');
// mobileview: any = window.matchMedia('(max-width: 768px)');
public value: Date = new Date(2000, 2, 10);

constructor() {
  this.isDesktop = !!this.breakpoint.matches;
  // this.ismobile = !!this.mobileview.matches;
 }

  // public mask = "(999) 000-0000";
  // public mask = "Phone";

  ngOnInit(): void {
  }
  
  // Add Modal
  public opened = false;

  public close(): void {
    this.opened = false;
  }
  public Name: Array<string> = [
   "Blue Cross of Michigan PPO"
  ];
  public selectedGender = "Male";
  public Genders : Array<string> = [
    "Male",
     "Female", 
     "Other"
   ];
  public registerForm: FormGroup = new FormGroup({
  //  email:new FormControl(),
    dobInput: new FormControl()
  })

  showmore(){
    this.ismorecontact=true;
    this.hidebtn=false;
  }
  removemorecontact(){
    this.ismorecontact=false;
  }
  issomeoneholder:boolean=false;

  showSomeOne(){
    
    const someOne1 = document.getElementById("primarySomeOne");
    if(someOne1 != null){
      console.log("someOne");
      if(someOne1.classList.contains("d-none")){
        someOne1.classList.add("d-block");
        someOne1.classList.remove("d-none");
      }else{
        someOne1.classList.remove("d-block");
        someOne1.classList.add("d-none");
      }
    }
  }
  someoneholder(){
    this.showSomeOne();
    this.issomeoneholder=!this.issomeoneholder;
    
  }
  isholder:boolean=true;
  isviewcard:boolean=false;
  viewcard(){
    this.isviewcard=!this.isviewcard;
    this.isholder=false;
  }

  isopenViewinsurancePopup:boolean=false
  openViewinsurancePopup() {
   this.isopenViewinsurancePopup=true;
  }
  onclose() {
    this.isopenViewinsurancePopup = false;
  }

  dob:string = '';  
  public maxlength = 10;
  formatDOB(event: any) {     
    const inputElement = event.target;     
    let inputValue = inputElement.value;    
    inputValue = inputValue.replace(/\D/g, '');     
    if (inputValue.length > 2 && inputValue.charAt(2) !== '/') {       
      inputValue = inputValue.slice(0, 2) + '/' + inputValue.slice(2);     
    }     if (inputValue.length > 5 && inputValue.charAt(5) !== '/') {       
      inputValue = inputValue.slice(0, 5) + '/' + inputValue.slice(5);    
     }
    inputElement.value = inputValue;   
  }

  //@ngneat/input-mask
  dateFC = new FormControl('');
  dateInputMask = createMask<Date>({
    alias: 'datetime',
    inputFormat: 'mm/dd/yyyy',
    parser: (value: string) => {
      const values = value.split('/');
      const year = +values[2];
      const month = +values[1] - 1;
      const date = +values[0];
      return new Date(year, month, date);
    }
  });
}
