<main-header></main-header>

<div class="mid-header-section ion-bg-unfocused-color mid-comment-border main_container">

  <div class="card-content main-div" [ngClass]="isInClinicTabDoneFooter == true ? '' : 'allDoneFooter'">
    <ion-grid class="grid-cards">
      <ion-item class="ion-no-padding bg-gray20 card-1"
        [ngClass]="status ? 'item-background-color' : 'ion-body-bg-color'">
        <div class="w-100" [ngClass]="isMobile ? '' : ' px-50 py-30'">
          <div class="d-flex card-data ion-justify-content-between w-100 mobileFlexColumn">

            <div class="column w-100">
              <!-- <ion-text class="h3-M text-blue-color align-items-md-center d-flex mb-15" *ngIf="ishideinsurance">
                <span class="bodyL-txt-medium text-blue-color stepper-circle mr-10">1</span>
                Is this information correct?
              </ion-text> -->
              <div class="stepper-title d-flex align-items-center mb-15" *ngIf="ishideinsurance">
                <div class="stepper-number">
                  <span class="stepper-circle">
                    <ion-text name="checkmark-outline" class=" bodyL-txt-medium text-blue-color tabletFont" color="">1</ion-text>
                  </span>
                </div>

                <ion-text class="h3-M text-blue-color h4-L-mobile tabletFont">Is this information correct?</ion-text>
              </div>

              <div class="justify-content-sb stepper-success row ion-align-items-center d-flex mb-15"
                *ngIf="isshowinsurance">
                <!-- <ion-text class="h3-M text-green-color d-flex align-items-md-center right-circle">
                  <ion-icon name="checkmark-outline" class="stepper-right mr-10 bodyS-txt-medium" color=""></ion-icon>
                  Information confirmed
                </ion-text> -->

                <div class="confirm-info d-flex align-items-center">
                  <div class="green-circle">
                    <span class="circle">
                      <ion-icon name="checkmark-outline" class=" ion-color-white" color=""></ion-icon>
                    </span>
                  </div>

                  <ion-text class="h3-success h4-L-success-mobile text-green-color right-circle tabletFont">Information confirmed</ion-text>
                </div>
                <ion-text class="bodyL-txt edit text-HLblue-color bodyS-txt-Mobile pointer pr-50" *ngIf="isDesktop"
                  (click)="openInfoPopup()">
                  Edit
                </ion-text>
                <ion-text class="bodyL-txt edit text-HLblue-color bodyS-txt-Mobile pointer pr-50" *ngIf="!isDesktop"
                  routerLink="/app/info-edit">
                  Edit
                </ion-text>
              </div>

              <div class="d-flex  align-items-baseline mobileFlexColumn">
                <div class="column pl-45 info-data focus w-65" id="patientEditBasicInfo">
                  <ion-text class="bodyXL-txt-bold sub-title mb-10 bodyM-txt-bold-mobile" color="gray100"
                    *ngIf="ishideinsurance">Charlotte
                    Nedermeir</ion-text>
                  <ion-text class="bodyXL-txt sub-title bodyM-txt-mobile mb-10" color="gray100"
                    *ngIf="isshowinsurance">Charlotte Nedermeir</ion-text>
    
                  <div class="d-flex add-cont">
                    <!-- <ion-text class="bodyM-txt add-info pr-30" color="gray100">
                      <div class="column">
                        <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100"> 123 NW Buckeye St</ion-text>
                        <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">Ann Arbor, MI 48104</ion-text>
                        <ion-text class="bodyL-txt-medium bodyM-txt-mobile contact-nb py-10"
                          color="gray100">352-724-7979</ion-text>
                        <ion-text class="bodyL-txt contact-nb py-10" color="gray100"
                          *ngIf="!isDesktop">352-724-7979</ion-text>
                        <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">cnedermeir7@gmail.com</ion-text>
                      </div>
                    </ion-text> -->
                    <div class="column mb-20">
                      <div class="row mb-10 ">
                        <ion-icon class="h3 mr-10" src="../../../../assets/svg/map-pin 1.svg" color="gray60"></ion-icon>
                        <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">123 NW Buckeye St
                          Ann Arbor, MI 48104</ion-text>
                      </div>
                      <!-- <div class="row mb-10 align-items-center">
                        <ion-icon class="h3 mr-10" src="../../../../assets/svg/map-pin 1.svg" color="gray60" style="width:25px;"></ion-icon>
                        <ion-text class="font16 bodyM-txt-mobile" color="gray60">Address not entered</ion-text>
                      </div> -->
                      <div class="row mb-10  align-items-center">
                        <ion-icon class="h3 mr-10" src="../../../../assets/svg/Phone 2.svg" color="gray60"></ion-icon>
                        <ion-text class="bodyL-txt-medium bodyM-txt-mobile" color="gray100">352-724-7979</ion-text>
                      </div>
                      <div class="row mb-10  align-items-center">
                        <ion-icon class="h3 ion-color-gray60 mr-10" src="../../../../assets/svg/home 1.svg" color="gray60"></ion-icon>
                        <ion-text class="font16 bodyM-txt-mobile" color="gray60">Mobile number not entered</ion-text>
                      </div>
                      <div class="row mb-10  align-items-center">
                        <ion-icon class="h3 ion-color-gray60 mr-10" src="../../../../assets/svg/mail 1.svg" color="gray60"></ion-icon>
                        <ion-text class="font16 bodyM-txt-mobile" color="gray60">Email not entered</ion-text>
                      </div>
                    </div>
                  </div>
    
                </div>
                <div class="mb-15 emergency-contact column mobile-pl-40 ">
                  <ion-text color="gray80" class="bodyM-txt bodyXS-txt-mobile mb-10">
                    EMERGENCY CONTACT
                  </ion-text>
                  <div class="row mb-10 align-items-center">
                    <ion-icon class="h3 ion-color-gray60 mr-10" src="../../../../assets/svg/user 2.svg" color="gray60"></ion-icon>
                    <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">John Nedermeir</ion-text>
                  </div>
                  <div class="row mb-10  align-items-center">
                    <ion-icon class="h3 ion-color-gray60 mr-10" src="../../../../assets/svg/Phone 2.svg" color="gray60"></ion-icon>
                    <ion-text class="font16 bodyM-txt-mobile" color="gray60">Phone number not entered</ion-text>
                  </div>
                    <div class="row mb-10  align-items-center">
                    <ion-icon class="h3 ion-color-gray60 mr-10" src="../../../../assets/svg/Phone 2.svg" color="gray60"></ion-icon>
                    <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">352-724-7979</ion-text>
                  </div>
                </div>
              </div>

              
            </div>

            <div class="buttons-flex " *ngIf="ishideinsurance">

              <ion-button fill="solid" expand="block" color="ion-color-white" class="filled-icon-btn capitalize btn mb-15"
                (click)="showinsurance()" (click)="showbtn()" (click)="unfocused()" (click)="mobilebtnshow()">
                <ion-icon name="checkmark-outline" color="white" class="mr-10"></ion-icon>
                <ion-text class="pr-10">Yes</ion-text>
              </ion-button>

              <!-- <ion-button fill="outline" expand="block" color="highlight" class="filled-outline-btn capitalize btn mb-15"
                (click)="showinsurance()" (click)="unfocused()" *ngIf="ishidebtn">
                <ion-icon name="checkmark-outline" color="white" class="mr-10"></ion-icon>
                <ion-text>Yes</ion-text>
              </ion-button> -->

              <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize btn" *ngIf="isDesktop"
                (click)="openInfoPopup()">

                <ion-icon src="../../../../../../../assets/svg/edit-2.svg" class="mr-10"></ion-icon>
                <ion-text>No</ion-text>
              </ion-button>
              <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize btn" *ngIf="!isDesktop"
                routerLink="/app/info-edit">

                <ion-icon src="../../../../../../../assets/svg/edit-2.svg" class="mr-10"></ion-icon>
                <ion-text>No</ion-text>
              </ion-button>

            </div>
          </div>
          <div class="d-flex mobileFlexColumn pt-20 px-40 refer_list justify-content-sb mobile-pl-40">
            <div class="mr-20 mobile-mb-10">
              <ion-text class="bodyM-txt uppercase" color="gray80">referring Provider</ion-text>
              <div class="d-flex mt-10">
                <div class="mr-5">
                  <ion-icon class="h3" color="" src="../../../../assets/svg/referring Provider.svg"></ion-icon>
                </div>
                <div class="w-100 max-280">
                  <ion-text class="bodyL-txt" color="gray100">Khaled Elias Jiries Abawi</ion-text>
                </div>
              </div>
            </div>
            <div class="mr-20 mobile-mb-10">
              <ion-text class="bodyM-txt uppercase" color="gray80">primary care provider</ion-text>
              <div class="d-flex mt-10">
                <div class="mr-5">
                  <ion-icon class="h3" color="" src="../../../../assets/svg/primary care provider.svg"></ion-icon>
                </div>
                <div class="w-100 max-280">
                  <ion-text class="bodyL-txt" color="gray100">Miguel E Allen</ion-text>
                </div>
              </div>
            </div>
            <div class="mobile-mb-10">
              <ion-text class="bodyM-txt uppercase" color="gray80">preferred Pharmacy </ion-text>
              <div class="d-flex mt-10">
                <div class="mr-5">
                  <ion-icon class="h3" color="" src="../../../../assets/svg/preferred Pharmacy.svg"></ion-icon>
                </div>
                <div class="w-100 max-280">
                  <ion-text class="bodyL-txt" color="gray100">00001</ion-text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ion-item>

      <ion-item class="ion-no-padding bg-gray20 card-1 mid-comment-section"
        [ngClass]="status2 ? 'item-background-color' : 'ion-body-bg-color'">
        <div class="d-flex card-data ion-justify-content-between w-100 px-50 py-30">

          <div class="column w-100">
            <div class="stepper-title d-flex justify-items-center align-items-center mb-15" *ngIf="ishideinsurance2">
              <div class="stepper-number">
                <span class="stepper-circle">
                  <ion-text name="checkmark-outline" class=" bodyL-txt-medium text-blue-color tabletFont" color="">2</ion-text>
                </span>
              </div>

              <ion-text class="h3-M text-blue-color h4-L-mobile tabletFont">Is this your insurance?</ion-text>
            </div>

            <div class="justify-content-sb row ion-align-items-center d-flex stepper-success mb-25"
              *ngIf="isshowinsurance2">
              <div class="confirm-info d-flex align-items-center">
                <div class="green-circle">
                  <span class="circle">
                    <ion-icon name="checkmark-outline" class=" ion-color-white" color=""></ion-icon>
                  </span>
                </div>

                <ion-text class="h3-success h4-L-success-mobile text-green-color right-circle tabletFont">Insurance confirmed</ion-text>
              </div>
              <ion-text class="bodyL-txt edit text-HLblue-color bodyS-txt-Mobile pointer pr-50" *ngIf="isDesktop"
                (click)="openInsurancePopup()">
                Edit
              </ion-text>
              <ion-text class="bodyL-txt edit text-HLblue-color bodyS-txt-Mobile pointer pr-50" *ngIf="!isDesktop"
                routerLink="/app/insurance-edit">
                Edit
              </ion-text>
            </div>

            <div class="column info-data pl-45 focus" id="patientEditBasicInfo">
              <ion-text class="bodyXL-txt-bold bodyM-txt-bold-mobile mb-10" color="gray100"
                *ngIf="ishideinsurance2">Blue Cross of Michigan
                PPO</ion-text>
              <ion-text class="bodyL-txt mb-10 bodyM-txt-mobile" color="gray100" *ngIf="isshowinsurance2">Blue Cross of
                Michigan
                PPO</ion-text>
              <div class="d-flex mb-20">
                <div class=" column pr-30">
                  <ion-text color="gray80" class="bodyM-txt bodyXS-txt-mobile pb-3">MEMBER NUMBER</ion-text>
                  <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">569684165</ion-text>
                </div>
                <div class=" column">
                  <ion-text color="gray80" class="bodyM-txt bodyXS-txt-mobile pb-3">GROUP NUMBER</ion-text>
                  <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">15685</ion-text>
                </div>
              </div>
              <div>
                <ion-text class="bodyL-txt mb-10 bodyM-txt-mobile d-block" color="gray100">Blue Cross of
                  Michigan
                  PPO</ion-text>
                <div class="d-flex  mb-20">
                  <div class=" column pr-30">
                    <ion-text color="gray80" class="bodyM-txt bodyXS-txt-mobile pb-3">MEMBER NUMBER</ion-text>
                    <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">569684165</ion-text>
                  </div>
                  <div class=" column">
                    <ion-text color="gray80" class="bodyM-txt bodyXS-txt-mobile pb-3">GROUP NUMBER</ion-text>
                    <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">15685</ion-text>
                  </div>
                </div>
                <ion-text class="bodyL-txt mb-10 bodyM-txt-mobile d-block" color="gray100">Blue Cross of
                  Michigan
                  PPO</ion-text>
                <div class="d-flex">
                  <div class=" column pr-30">
                    <ion-text color="gray80" class="bodyM-txt bodyXS-txt-mobile pb-3">MEMBER NUMBER</ion-text>
                    <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">569684165</ion-text>
                  </div>
                  <div class=" column">
                    <ion-text color="gray80" class="bodyM-txt bodyXS-txt-mobile pb-3">GROUP NUMBER</ion-text>
                    <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">15685</ion-text>
                  </div>
                </div>
              </div>

              <div class="" *ngIf="ishideinsurance2">
                <div class="">
                  <ion-text
                    class="bodyM-txt text-HLblue-color pointer d-inline-block add-insurance bodyS-txt-Mobile mt-10"
                    *ngIf="isshowbtn">+ Add Insurance Policy</ion-text>
                </div>
              </div>
            </div>
          </div>



          <div class="mobileShwoBtn" id="mobileBtn">
            <div class="buttons-flex" *ngIf="ishideinsurance2">

              <ion-button fill="outline" expand="block" color="highlight"
                class="filled-outline-btn capitalize btn mb-15" (click)="showinsurance2()" (click)="unfocused2()"
                *ngIf="ishidebtn">
                <ion-icon name="checkmark-outline" color="white" class="mr-10"></ion-icon>
                <ion-text class="pr-10">Yes</ion-text>
              </ion-button>

              <ion-button fill="solid" expand="block" color="ion-color-white"
                class="filled-icon-btn capitalize btn mb-15" (click)="showinsurance2()" (click)="unfocused2()"
                 (click)="showpay()" *ngIf="isshowbtn">
                <ion-icon name="checkmark-outline" color="white" class="mr-10"></ion-icon>
                <ion-text class="pr-10">Yes</ion-text>
              </ion-button>

              <ion-button *ngIf="isDesktop" fill="outline" color="highlight" class="filled-outline-btn capitalize btn"
                (click)="openInsurancePopup()">
                <ion-icon src="../../../../../../../assets/svg/edit-2.svg" class="mr-10"></ion-icon>
                <ion-text>No</ion-text>
              </ion-button>

              <ion-button *ngIf="!isDesktop" fill="outline" color="highlight" class="filled-outline-btn capitalize btn"
                routerLink="/app/insurance-edit">
                <ion-icon src="../../../../../../../assets/svg/edit-2.svg" class="mr-10"></ion-icon>
                <ion-text>No</ion-text>
              </ion-button>
            </div>
          </div>
        </div>
      </ion-item>

      <!-- <ion-item class="ion-no-padding bg-gray20 card-1 mid-comment-section"
        [ngClass]="status2 ? 'item-background-color' : 'ion-body-bg-color'">
        <div class="insu d-flex card-data ion-justify-content-between w-100 px-50 py-30">

          <div class="column w-100">
            <div class="stepper-title d-flex align-items-center mb-15" *ngIf="ishideinsurance4">
              <div class="stepper-number">
                <span class="stepper-circle">
                  <ion-text name="checkmark-outline" class=" bodyL-txt-medium text-blue-color" color="">2</ion-text>
                </span>
              </div>
             
              <ion-text class="h3-M text-blue-color h4-L-mobile">Do you have insurance?</ion-text>
            </div>

            <div class="justify-content-sb stepper-success row align-items-center d-flex mb-10" *ngIf="isshowinsurance4">
              

              <div class="confirm-info  d-flex">
                <div class="green-circle">
                  <span class="circle">
                    <ion-icon name="checkmark-outline" class=" ion-color-white" color=""></ion-icon>
                  </span>
                </div>
                <ion-text class="h3-success h4-L-success-mobile text-green-color right-circle">No insurance - Self paying </ion-text>
                
              </div>

              <ion-text class="bodyL-txt edit text-HLblue-color pointer pr-50 bodyS-txt-Mobile" *ngIf="isDesktop"
                (click)="openInfoPopup()">
                Edit
              </ion-text>
              <ion-text class="bodyL-txt edit text-HLblue-color pointer pr-50 bodyS-txt-Mobile" *ngIf="!isDesktop"
                routerLink="/app/info-edit">
                Edit
              </ion-text>
            </div>

            <div class="column pl-45 focus" id="patientEditBasicInfo">
              <ion-text class="bodyL-txt-bold-italic mb-10 bodyM-txt-bold-mobile" color="gray80" *ngIf="ishideinsurance4">No insurance on file</ion-text>
              <ion-text class="bodyL-txt-italic mb-10 bodyM-txt-mobile" color="gray80" *ngIf="isshowinsurance4">No insurance on file</ion-text>
            </div>
          </div>

          <div class="mobileShwoBtn" id="mobileBtn">
          <div class="buttons-flex" *ngIf="ishideinsurance4">

            <ion-button fill="outline" expand="block" color="highlight" class="filled-outline-btn capitalize btn mb-15"
              (click)="showinsurance4()" (click)="unfocused2()" (click)="mobilebtnshowpay()" *ngIf="ishidebtn">
              <ion-icon name="checkmark-outline" color="white" class="mr-10"></ion-icon>
              <ion-text>Yes</ion-text>
            </ion-button>

            <ion-button fill="solid" expand="block" color="ion-color-white"
                class="filled-icon-btn capitalize btn mb-15" (click)="showinsurance4()" (click)="unfocused2()" (click)="mobilebtnshowpay()"  (click)="showpay()" *ngIf="isshowbtn">
                <ion-icon name="checkmark-outline" color="white" class="mr-10"></ion-icon>
                <ion-text class="pr-10">Yes</ion-text>
              </ion-button>

            <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize btn"
              routerLink="/app/info-edit">
              <ion-icon src="../../../../../../../assets/svg/edit-2.svg" class="mr-10"></ion-icon>
              <ion-text>No</ion-text>
            </ion-button>

          </div>
        </div>
        </div>
      </ion-item> -->

      <ion-item class="ion-no-padding bg-gray20 card-1 mid-comment-section fillOutForm"
        [ngClass]="status3 ? 'item-background-color' : 'ion-body-bg-color'">
        <div class="d-flex card-data ion-justify-content-between w-100 px-50 pt-30 pb-20">
          <div class="column w-100">
            <div class="stepper-title d-flex "  *ngIf="!isFormFillOut" (click)="showFillFormSuccess()">
              <div class="stepper-number">
                <span class="stepper-circle">
                  <ion-text name="checkmark-outline" class=" bodyL-txt-medium text-blue-color tabletFont" color="">3</ion-text>
                </span>
              </div>
              <div class="column">
                <ion-text class="h3-M text-blue-color h4-L-mobile tabletFont">Fill out forms</ion-text>
                <ion-text class="bodyS-regular" color="gray80">Press a form to begin or press Fill out forms</ion-text>
              </div>
            </div>
            <div class="d-flex ion-align-items-center stepper-success mb-10 k-justify-content-between" *ngIf="isFormFillOut">
              <div class="confirm-info  d-flex align-items-center">
                <div class="green-circle">
                  <span class="circle">
                    <ion-icon name="checkmark-outline" class=" ion-color-white" color=""></ion-icon>
                  </span>
                </div>
                <ion-text class="h3-success h4-L-success-mobile text-green-color right-circle tabletFont" >Forms completed </ion-text>
              </div>
              <div *ngIf="!isDesktop">
                <ion-text class="bodyL-txt edit text-HLblue-color bodyS-txt-Mobile cursor pr-50" *ngIf="isFormFillOut" (click)="hideFillFormSuccess()" routerLink="/app/patient-form">
                  Edit
                </ion-text>
              </div>
            </div>
            <div class="pl-30 pt-10">
              <div class="d-flex flex-mob-col">
                <div class="d-flex align-items-center py-10 pr-10 mr-5 form-icon-container">
                  <span class="form-icon-main">
                    <ion-icon class="form-icon" src="../../../../assets/svg/consent.svg" ></ion-icon>
                    <span class="success-circle"  *ngIf="isFormFillOut">
                      <ion-icon name="checkmark-outline" class=" ion-color-white" color=""></ion-icon>
                    </span>
                  </span>
                  <ion-text class="h5-regular ml-15" color="highlight">Patient health history</ion-text>
                </div>
                <div class="d-flex align-items-center py-10 pr-10 form-icon-container">
                  <span class="form-icon-main">
                    <ion-icon class="form-icon" src="../../../../assets/svg/consent.svg" ></ion-icon>
                    <span class="success-circle"  *ngIf="isFormFillOut">
                      <ion-icon name="checkmark-outline" class=" ion-color-white" color=""></ion-icon>
                    </span>
                  </span>
                  <ion-text class="h5-regular ml-15" color="highlight">Family Medical History</ion-text>
                </div>
              </div>
              <div class="d-flex flex-mob-col">
                <div class="d-flex align-items-center py-10 pr-10 mr-5 form-icon-container">
                  <span class="form-icon-main">
                    <ion-icon class="form-icon" src="../../../../assets/svg/consent.svg" ></ion-icon>
                    <span class="success-circle"  *ngIf="isFormFillOut">
                      <ion-icon name="checkmark-outline" class=" ion-color-white" color=""></ion-icon>
                    </span>
                  </span>
                  <ion-text class="h5-regular ml-15" color="highlight">Consent form</ion-text>
                </div>
                <div class="d-flex align-items-center py-10 pr-10 form-icon-container">
                  <span class="form-icon-main">
                    <ion-icon class="form-icon" src="../../../../assets/svg/consent.svg" ></ion-icon>
                    <span class="success-circle" *ngIf="isFormFillOut">
                      <ion-icon name="checkmark-outline" class=" ion-color-white" color=""></ion-icon>
                    </span>
                  </span>
                  <ion-text class="h5-regular ml-15" color="highlight">Billing agreement</ion-text>
                </div>
              </div>      
            </div>
          </div>
          <div *ngIf="isDesktop">
            <ion-button fill="outline" expand="block" color="highlight"
                class="filled-outline-btn capitalize btn mb-15"  *ngIf="!isFormFillOut" (click)="showFillFormSuccess()">
                <ion-text>Fill out forms</ion-text>
                <ion-icon src="../../../../assets/svg/arrow-right.svg" color="highlight" class="ml-10"></ion-icon>
            </ion-button>
            <ion-text class="bodyL-txt edit text-HLblue-color bodyS-txt-Mobile cursor pr-50" *ngIf="isFormFillOut" (click)="hideFillFormSuccess()">
              Edit
            </ion-text>
          </div>
          <div *ngIf="!isDesktop">
            <div *ngIf="isFormFillOutBtn">
              <ion-button fill="solid" expand="block" color="ion-color-white"
                class="filled-icon-btn capitalize btn" (click)="showPaymentBtn()" routerLink="/app/patient-form">
                <ion-text class="">Fill out forms</ion-text>
            </ion-button>
            </div>
          </div>
        </div>
       
      </ion-item>

      <!-- Patient Card Section -->
      <ion-item class="ion-no-padding bg-gray20 card-1 mid-comment-section fillOutForm"
        [ngClass]="status3 ? 'item-background-color' : 'ion-body-bg-color'">
        <div class="d-flex card-data ion-justify-content-between w-100 px-50 pt-30 pb-20">
          <div class="column w-100">
            <div class="stepper-title d-flex ">
              <div class="stepper-number">
                <span class="stepper-circle">
                  <ion-text name="checkmark-outline" class=" bodyL-txt-medium text-blue-color tabletFont" color="">4</ion-text>
                </span>
              </div>
              <div class="column">
                <ion-text class="h3-M text-blue-color h4-L-mobile tabletFont">Patient Cards</ion-text>
                <ion-text class="bodyS-regular" color="gray80">View existing patient Insurance & Driving Licence Card</ion-text>
              </div>
            </div>
           
            <div class="pl-30 pt-10">
              <div class="grid-cols-2 flex-mob-col">
                <div class="d-flex align-items-center py-10 pr-10 mr-5 form-icon-container pointer">
                  <span class="form-icon-main">
                    <ion-icon class="form-icon" src="../../../../assets/svg/Insurence_card_1.svg" ></ion-icon>
                   
                  </span>
                  <ion-text class="h5-regular ml-15  d-inline-block" color="highlight" (click)="openPatientCard()">View/Update Insurance Card Photo</ion-text>
                </div>
                <div class="d-flex align-items-center py-10 pr-10 form-icon-container pointer">
                  <span class="form-icon-main">
                    <ion-icon class="form-icon" src="../../../../assets/svg/Driving_card.svg" ></ion-icon>
                   
                  </span>
                  <ion-text class="h5-regular ml-15  d-inline-block" color="highlight" (click)="openPatientCard()">View/Update Driving Card Photo</ion-text>
                </div>
              </div>    
            </div>
          </div>
        </div>
      </ion-item>
      <!-- End of Patient Card Section -->



      <ion-item class="ion-no-padding bg-gray20 card-1 mid-comment-section"
        [ngClass]="status4 ? 'item-background-color' : 'ion-body-bg-color'">
        <div class="d-flex card-data ion-justify-content-between w-100 px-50 py-30">

          <div class="column w-100">

            <div class="stepper-title d-flex align-items-center mb-15 " *ngIf="ishideinsurance3">
              <div class="stepper-number">
                <span class="stepper-circle">
                  <ion-text name="checkmark-outline" class=" bodyL-txt-medium text-blue-color tabletFont" color="">5</ion-text>
                </span>
              </div>

              <ion-text class="h3-M text-blue-color h4-L-mobile tabletFont">Make a payment</ion-text>
            </div>


            <div class="ion-align-items-center stepper-success mb-10" *ngIf="isshowinsurance3">
              <!-- <ion-text class="h3-M text-green-color d-flex align-items-center right-circle">
                <ion-icon name="checkmark-outline" class="stepper-right mr-10 bodyS-txt-medium" color=""></ion-icon>
                Paying at office
              </ion-text> -->

              <div class="confirm-info  d-flex mb-20">
                <div class="green-circle">
                  <span class="circle">
                    <ion-icon name="checkmark-outline" class=" ion-color-white" color=""></ion-icon>
                  </span>
                </div>
                <ion-text class="h3-success h4-L-success-mobile text-green-color right-circle tabletFont">Payment </ion-text>
                <!-- <ion-text class="h3-M text-green-color right-circle">Paying at office</ion-text> -->
              </div>
              <div class="pl-45">
                <ion-text color="gray100" class="bodyL-txt mb-10 pr-70 bodyM-txt-mobile">Payment processed CC...8478</ion-text>
                <ion-text class="bodyL-txt mb-10 bodyM-txt-mobile" color="gray100">$65.00</ion-text>
              </div>
            </div>
            <div class="column pl-45 info-data focus" id="patientEditBasicInfo" *ngIf="ishideinsurance3">
              <div class="column">
                <div class="d-flex">
                  <ion-text color="gray100" class="bodyL-txt mb-10" style="width: 385px;" *ngIf="isDesktop">Copayment
                    amount for today’s visit</ion-text>
                  <ion-text color="gray100" class="bodyL-txt mb-10 bodyM-txt-mobile" style="width: 385px;"
                    *ngIf="!isDesktop">Copayment</ion-text>
                  <ion-text class="bodyL-txt mb-10 bodyM-txt-mobile" color="gray100">$15.00</ion-text>


                </div>
                <div class="prev-bal d-flex">
                  <ion-text color="gray100" class="bodyL-txt bodyM-txt-mobile" style="width: 385px;">Previous
                    balance</ion-text>
                  <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">$50.00</ion-text>
                </div>
              </div>

            </div>

          </div>
          <div  id="mobileBtn2" > <!---->
            <div class="buttons-flex" *ngIf="ishideinsurance3">

              <ion-button fill="solid" expand="block"color="ion-color-white"
                class="filled-icon-btn capitalize btn mb-15" (click)="unfocused3()" *ngIf="ishidepay"
                (click)="openpaymentpopup($event)">

                <ion-text>Pay Now</ion-text>
              </ion-button>

              <!-- <div >
                <ion-button fill="solid" expand="block" color="ion-color-white" class="filled-icon-btn capitalize btn mb-15" (click)="unfocused3()" (click)="openPaymentPopup()" *ngIf="isshowpay">

                  <ion-text>Pay Now</ion-text>
                </ion-button>
              </div> -->

              <!-- <div *ngIf="!isDesktop">
                <ion-button fill="solid" expand="block" color="ion-color-white" routerLink="/app/payment" class="filled-icon-btn capitalize btn mb-15" (click)="unfocused3()" (click)="showinsurance3()" *ngIf="isshowpay">

                  <ion-text>Pay Now</ion-text>
                </ion-button>
              </div> -->

              <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize btn"
                (click)="showinsurance3()">
                <ion-text>Pay later</ion-text>
              </ion-button>

            </div>
          </div>
          <div>
          <!-- <div class="d-flex align-items-center mt-15 px-30 justify-content-sb" *ngIf="!isDesktop" >
          
          
          <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize btn"
            (click)="showinsurance3()">
            <ion-text>Pay later</ion-text>
          </ion-button>

          <ion-button fill="solid" expand="block" color="ion-color-white" routerLink="/app/payment" class="filled-icon-btn capitalize btn mr-15" (click)="unfocused3()" (click)="showinsurance3()"
          (click)="openpaymentpopup($event)" >

            <ion-text>Pay Now</ion-text>
          </ion-button>
          </div> -->
          </div>
        </div>
      </ion-item>

      

    </ion-grid>
  </div>

  <!-- Tablet-in-clinic patient-view -->
  <div class="tablet_in_clinic_footer2 d-flex justify-content-center align-items-center" *ngIf="!isInClinicTabDoneFooter">
    <div class="tablet_in_clinic_footer2_text mr-30">
      <ion-text color="white" class="d-block h1-Montserrat mb-20">You’re all done!</ion-text>
      <ion-text color="white" class="h3-Montserrat mb-20">Lock the tablet and <b>return it 
        to the front desk. </b></ion-text>
    </div>
    <div>
      <ion-button fill="fill" class="filled-outline-btn finish-btn capitalize ml-20" (click)="showModal($event)">
        <ion-icon src="../../../../assets/svg/lock-blue.svg" class="title-32-font-size mr-5 text-blue-color" color="text-blue-color"></ion-icon>
        <ion-text class="font20 montserrat text-blue-color" >Lock Tablet</ion-text>
      </ion-button>
    </div>
  </div>
  <!--End of Tablet-in-clinic patient-view -->

  <!-- Tablet-in-clinic patient-view -->
  <!-- <div class="tablet_in_clinic_footer">
    <clinic-footer></clinic-footer>
  </div> -->
  <!--End of Tablet-in-clinic patient-view -->

<ion-footer>
  
</ion-footer>

<!-- patient-inhome-footer -->
  <ion-footer class="page-bottom patient-inhome-footer" *ngIf="isInClinicTabDoneFooter">
    <div class="px-30 py-10 row w-100 justify-content-center align-items-center height100"  *ngIf="ishideinsurance3"
      [ngClass]="status4 ? 'ion-body-bg-color' : 'item-background-color'">
      <ion-text *ngIf="isDesktop" class="bodyL-txt footer-txt text-left mr-30" color="gray80">If you need to <span
          class="bodyL- txt-large">pause this process</span>, you can come back to it later.
      </ion-text>

      <ion-text class="bodyS-txt-Mobile footer-tab-txt text-right mr-30" color="gray80">
        Need to pause this process?
      </ion-text>

      <!-- <ion-text *ngIf="!isDesktop" class="bodyS-txt-Mobile footer-txt text-right mr-30" color="gray80">
        Need to pause this process?
      </ion-text> -->
      <ion-button fill="outline" color="gray80" class="filled-outline-btn finish-btn capitalize" (click)="showModal2($event)">
        <ion-text>Finish later</ion-text>
      </ion-button>
    </div>
    <div *ngIf="isDesktop">
      <div class="px-30 py-10 row w-100 justify-content-center align-items-center" *ngIf="isshowinsurance3"
      [ngClass]="status ? 'ion-body-bg-color' : 'item-background-color'">
        <ion-text class="bodyL-txt  text-left mr-30 onlyDesktop" color="gray80">Click “Done” to go<span class="bodyL-txt-bold"> back to appointment details</span>
        </ion-text>
          <ion-button fill="solid"  color="ion-color-white" class="filled-icon-btn capitalize info-btn" routerLink="/app/welcome">Done</ion-button>
      </div>
    </div>
    <div *ngIf="!isDesktop">
      <div class="px-30 py-10 ion-bg-unfocused-color"  *ngIf="isshowinsurance3">
        <ion-button fill="solid"  expand="block" color="ion-color-white" class="filled-icon-btn capitalize" routerLink="/app/welcome">
          <ion-text>Done</ion-text>
        </ion-button>
      </div>
    </div>
  </ion-footer>
</div>



<div class="kendopopup editInfo_popup">
  <kendo-dialog *ngIf="editInfoOpened">
    <div  class="scrollMain" *ngIf="isInfo">
      <kendo-dialog-titlebar class="text-center">
        <div class="ion-align-items-center w-100">
          <ion-text class="h3 tabletFont" color="gray100">Update your information</ion-text>
        </div>
      </kendo-dialog-titlebar>
      <div class="scrollBody">
        <app-pre-registration-patient-info-edit (openRefer)="openReferPopup()" (openPhysician)="openPhysiciansPopup()" (openPharmacy)="openPharmacyPopup()"></app-pre-registration-patient-info-edit>

      </div>
    </div>

    <div *ngIf="isinsurance">
      <kendo-dialog-titlebar class="text-center">
        <div class="ion-align-items-center w-100">
          <ion-text class="h3 tabletFont" color="gray100">Update your insurance</ion-text>
        </div>
      </kendo-dialog-titlebar>
      <app-pre-registration-insurance-edit></app-pre-registration-insurance-edit>
    </div>

    
    
    <kendo-dialog-actions class="border" >
      <div class="py-10 row w-100 ion-justify-content-center align-items-center" *ngIf="isDesktop">
        <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn mr-20"
          (click)="onclose()">
          <ion-text>Cancel</ion-text>
        </ion-button>
        <ion-button fill="solid" expand="block" color="ion-color-white" class="filled-icon-btn capitalize info-btn">
          <ion-text>done</ion-text>
        </ion-button>
      </div>
    </kendo-dialog-actions>
  </kendo-dialog>
  
  <kendo-dialog *ngIf="isPatientFormOpened">
    <div class="patient_form_main">
      <kendo-dialog-titlebar style="border-bottom: 1px solid #CCD4D8">
        <div class="d-flex w-100 align-items-center justify-content-sb ">
          <div class="text-center w-100"> 
            <ion-text class="h3-Montserrat tabletFont" color="gray100">Patient Health History</ion-text>
          </div>
            <ion-icon src="../../../../assets/svg/x.svg" color="gray80" class="h3 mr-10 cursor" (click)="hidePatientForm()"></ion-icon>
        </div>
      </kendo-dialog-titlebar>
    </div>
    <pre-registration-patient-form (hideformpopup)="hideformpopup($event)"></pre-registration-patient-form>
  </kendo-dialog>
</div>

<div class="patientCard">
  <kendo-dialog *ngIf="isPatientCardOpened">
    <app-pre-registration-view-insurance-card (isPatientCardClose)="closePatientCard($event)"></app-pre-registration-view-insurance-card>
  </kendo-dialog>
</div>


<div class="kendopopup editInfo_popup refered_dialog">
  <kendo-dialog *ngIf="isReferPopup">
    <div class="">
      <kendo-dialog-titlebar class="text-center">
        <div class="ion-align-items-center w-100">
          <ion-text class="h3 tabletFont" color="gray100">Referrals</ion-text>
        </div>
      </kendo-dialog-titlebar>
      <div class="">
        <refer-provider-popup (closeRefer)="closeReferPopup()" ></refer-provider-popup>
      </div>
    </div>
  </kendo-dialog>
</div>


<div class="kendopopup editInfo_popup refered_dialog">
  <kendo-dialog *ngIf="isPhysiciansPopup">
    <kendo-dialog-titlebar class="text-center">
      <div class="ion-align-items-center w-100">
        <ion-text class="h3 tabletFont" color="gray100">Physicians</ion-text>
      </div>
    </kendo-dialog-titlebar>
    <primary-care-provider-popup (closePhysicain)="closePhysicainPopup()" ></primary-care-provider-popup>
  </kendo-dialog>
</div>


<div class="kendopopup editInfo_popup refered_dialog">
  <kendo-dialog *ngIf="isPharmacyPopup">
    <kendo-dialog-titlebar class="text-center">
      <div class="ion-align-items-center w-100">
        <ion-text class="h3 tabletFont" color="gray100">Pharmacy</ion-text>
      </div>
    </kendo-dialog-titlebar>
    <preferred-pharmacy-popup (closePharmacy)="closePharmacyPopup()" ></preferred-pharmacy-popup>
  </kendo-dialog>
</div>