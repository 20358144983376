<div class="clinic_dashboard_patient_list_main">
  <div class="d-flex borderBottom20 p-10 align-items-center ">
    <ion-searchbar placeholder="Search Patients" mode="q" (change)="showNoResult()"></ion-searchbar>
    <ion-icon class="bodyXL-txt ml-10 pointer" color="gray80" src="../../../../../../assets/svg/filter.svg" id="patient-fliter"></ion-icon>
  </div>
  <ion-popover class="clinic_list_popup" trigger="patient-fliter" side="bottom" alignment="end" dismiss-on-select="true" show-backdrop="false" style="--offset-x: -0.65rem; --offset-y:0.5rem" >
      <ng-template>
        <div class="clinic_location_list d-flex align-items-center px-10 pointer">
          <input type="checkbox" kendoCheckBox value="All" [checked]="true" />
          <kendo-label class="k-checkbox-label" text="All"></kendo-label>
        </div>
        <div class="clinic_location_list d-flex align-items-center px-10 pointer">
          <input type="checkbox" kendoCheckBox value="Pre-registered" />
          <kendo-label class="k-checkbox-label" text="Pre-registered"></kendo-label>
        </div>
        <div class="clinic_location_list d-flex align-items-center px-10 pointer">
          <input type="checkbox" kendoCheckBox value="Not-registered" />
          <kendo-label class="k-checkbox-label" text="Not-registered"></kendo-label>
        </div>
      </ng-template>
    </ion-popover>
  <div class="patient_list_main listMain" *ngIf="!isNoResult">
    <div class="patient_list d-flex align-items-center justify-content-sb px-15 py-10 borderBottom05">
      <div class="d-flex align-items-center">
        <div>
          <ion-avatar class="small-avatar" slot="start">
            <img src="../../../../assets/avatar/avatar-01.jpg" />
          </ion-avatar>
        </div>
        <div class="">
          <div class="line-13">
            <ion-text class="font16 text-blue-color" color="text-blue-color"><b>Nedermeir,</b> Charlotte</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="font13-italic" color="gray80">23y F </ion-text>
            <ion-text class="bodyXS-txt" color="gray20">|</ion-text>
            <ion-text class="font13-italic" color="gray80"> 3/27/2000 </ion-text>
            <ion-text class="bodyXS-txt" color="gray20">|</ion-text>
            <ion-text class="font13-italic-regular pointer" color="success100"  routerLink="/pre-register-patient"> Pre-registered </ion-text>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="mr-15 minWidth125 text-right" >
          <div class="line-13 ion-text-right">
            <ion-text class="caption" color="gray80">8:00 AM</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="caption" color="gray80">B. Turlington</ion-text>
          </div>
        </div>
        <div  id="nav-popover-trigger2"  routerLink="/confirm-patient" class="pointer"> 
          <ion-icon src="../../../../../../assets/svg/D-chevron-right.svg"></ion-icon>
        </div>
      </div>
    </div>
    <div class="patient_list d-flex align-items-center justify-content-sb px-15 py-10 borderBottom05" routerLink="/confirm-patient">
      <div class="d-flex align-items-center">
        <ion-avatar class="pateint_text small-avatar" slot="start">
          AP
        </ion-avatar>
        <div class="">
          <div class="line-13">
            <ion-text class="font16 text-blue-color" color="text-blue-color"><b>Patient,</b> Another</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="font13-italic" color="gray80">99y F </ion-text>
            <ion-text class="bodyXS-txt" color="gray20">|</ion-text>
            <ion-text class="font13-italic" color="gray80"> 12/31/1900</ion-text>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="mr-15">
          <div class="line-13 ion-text-right">
            <ion-text class="caption" color="gray80">8:00 AM</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="caption" color="gray80">R. Markle</ion-text>
          </div>
        </div>
        <div>
          <ion-icon src="../../../../../../assets/svg/D-chevron-right.svg"></ion-icon>
        </div>
      </div>
    </div>
    <div class="patient_list d-flex align-items-center justify-content-sb px-15 py-10 borderBottom05 " routerLink="/confirm-patient">
      <div class="d-flex align-items-center">
        <ion-avatar class="pateint_text small-avatar" slot="start">
          AP
        </ion-avatar>
        <div class="">
          <div class="line-13">
            <ion-text class="font16 text-blue-color" color="text-blue-color"><b>Patient,</b> Another</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="font13-italic" color="gray80">99y F </ion-text>
            <ion-text class="bodyXS-txt" color="gray20">|</ion-text>
            <ion-text class="font13-italic" color="gray80"> 12/31/1900</ion-text>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="mr-15">
          <div class="line-13 ion-text-right">
            <ion-text class="caption" color="gray80">8:00 AM</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="caption" color="gray80">C. Calhoon</ion-text>
          </div>
        </div>
        <div>
          <ion-icon src="../../../../../../assets/svg/D-chevron-right.svg"></ion-icon>
        </div>
      </div>
    </div>
    <div class="patient_list d-flex align-items-center justify-content-sb px-15 py-10 borderBottom05">
      <div class="d-flex align-items-center">
        <ion-avatar class="pateint_text small-avatar" slot="start">
          AP
        </ion-avatar>
        <div class="">
          <div class="line-13">
            <ion-text class="font16 text-blue-color" color="text-blue-color"><b>Patient,</b> Another</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="font13-italic" color="gray80">99y F </ion-text>
            <ion-text class="bodyXS-txt" color="gray20"> | </ion-text>
            <ion-text class="font13-italic" color="gray80"> 12/31/1900</ion-text>
            <ion-text class="bodyXS-txt" color="gray20"> | </ion-text>
            <ion-text class="font13-italic-regular pointer" color="success100"  routerLink="/pre-register-patient"> Pre-registered </ion-text>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        
        <div class="mr-15 minWidth125 text-right" >
          <div class="line-13 ion-text-right">
            <ion-text class="caption" color="gray80">8:00 AM</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="caption" color="gray80">B. Turlington</ion-text>
          </div>
        </div>
        <div  id="nav-popover-trigger2"  routerLink="/confirm-patient" class="pointer"> 
          <ion-icon src="../../../../../../assets/svg/D-chevron-right.svg"></ion-icon>
        </div>
      </div>
    </div>
    <div class="patient_list d-flex align-items-center justify-content-sb px-15 py-10 borderBottom05 pointer" routerLink="/confirm-patient">
      <div class="d-flex align-items-center">
        <ion-avatar class="pateint_text small-avatar" slot="start">
          AP
        </ion-avatar>
        <div class="">
          <div class="line-13">
            <ion-text class="font16 text-blue-color" color="text-blue-color"><b>Patient,</b> Another</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="font13-italic" color="gray80">99y F </ion-text>
            <ion-text class="bodyXS-txt" color="gray20">|</ion-text>
            <ion-text class="font13-italic" color="gray80"> 12/31/1900</ion-text>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="mr-15">
          <div class="line-13 ion-text-right">
            <ion-text class="caption" color="gray80">8:00 AM</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="caption" color="gray80">R. Bigsby</ion-text>
          </div>
        </div>
        <div>
          <ion-icon src="../../../../../../assets/svg/D-chevron-right.svg"></ion-icon>
        </div>
      </div>
    </div>
    <div class="patient_list d-flex align-items-center justify-content-sb px-15 py-10 borderBottom05 pointer" routerLink="/confirm-patient">
      <div class="d-flex align-items-center">
        <ion-avatar class="pateint_text small-avatar" slot="start">
          AP
        </ion-avatar>
        <div class="">
          <div class="line-13">
            <ion-text class="font16 text-blue-color" color="text-blue-color"><b>Patient,</b> Another</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="font13-italic" color="gray80">99y F </ion-text>
            <ion-text class="bodyXS-txt" color="gray20">|</ion-text>
            <ion-text class="font13-italic" color="gray80"> 12/31/1900</ion-text>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="mr-15">
          <div class="line-13 ion-text-right">
            <ion-text class="caption" color="gray80">8:00 AM</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="caption" color="gray80">R. Bigsby</ion-text>
          </div>
        </div>
        <div>
          <ion-icon src="../../../../../../assets/svg/D-chevron-right.svg"></ion-icon>
        </div>
      </div>
    </div>
    <div class="patient_list d-flex align-items-center justify-content-sb px-15 py-10 borderBottom05 pointer">
      <div class="d-flex align-items-center">
        <ion-avatar class="pateint_text small-avatar" slot="start">
          AP
        </ion-avatar>
        <div class="">
          <div class="line-13">
            <ion-text class="font16 text-blue-color" color="text-blue-color"><b>Patient,</b> Another</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="font13-italic" color="gray80">99y F </ion-text>
            <ion-text class="bodyXS-txt" color="gray20">|</ion-text>
            <ion-text class="font13-italic" color="gray80"> 12/31/1900</ion-text>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="mr-15 minWidth125 text-right" >
          <div class="line-13 ion-text-right">
            <ion-text class="caption" color="gray80">8:00 AM</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="caption" color="gray80">B. Turlington</ion-text>
          </div>
        </div>
        <div  id="nav-popover-trigger2"  routerLink="/confirm-patient"> 
          <ion-icon src="../../../../../../assets/svg/D-chevron-right.svg"></ion-icon>
        </div>
      </div>
    </div>
    <div class="patient_list d-flex align-items-center justify-content-sb px-15 py-10 borderBottom05">
      <div class="d-flex align-items-center">
        <div>
          <ion-avatar class="small-avatar" slot="start">
            <img src="../../../../assets/avatar/avatar-01.jpg" />
          </ion-avatar>
        </div>
        <div class="">
          <div class="line-13">
            <ion-text class="font16 text-blue-color" color="text-blue-color"><b>Nedermeir,</b> Charlotte</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="font13-italic" color="gray80">23y F </ion-text>
            <ion-text class="bodyXS-txt" color="gray20">|</ion-text>
            <ion-text class="font13-italic" color="gray80"> 3/27/2000 </ion-text>
            <ion-text class="bodyXS-txt" color="gray20">|</ion-text>
            <ion-text class="font13-italic-regular pointer" color="success100" routerLink="/pre-register-patient"> Pre-registered </ion-text>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="mr-15" >
          <div class="line-13 ion-text-right">
            <ion-text class="caption" color="gray80">8:00 AM</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="caption" color="gray80">B. Turlington</ion-text>
          </div>
        </div>
        <div  id="nav-popover-trigger2 pointer">
          <ion-icon src="../../../../../../assets/svg/D-chevron-right.svg"></ion-icon>
        </div>
      </div>
    </div>
    <div class="patient_list d-flex align-items-center justify-content-sb px-15 py-10 borderBottom05 pointer">
      <div class="d-flex align-items-center">
        <ion-avatar class="pateint_text small-avatar" slot="start">
          AP
        </ion-avatar>
        <div class="">
          <div class="line-13">
            <ion-text class="font16 text-blue-color" color="text-blue-color"><b>Patient,</b> Another</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="font13-italic" color="gray80">99y F </ion-text>
            <ion-text class="bodyXS-txt" color="gray20">|</ion-text>
            <ion-text class="font13-italic" color="gray80"> 12/31/1900</ion-text>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="mr-15">
          <div class="line-13 ion-text-right">
            <ion-text class="caption" color="gray80">8:00 AM</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="caption" color="gray80">R. Markle</ion-text>
          </div>
        </div>
        <div>
          <ion-icon src="../../../../../../assets/svg/D-chevron-right.svg"></ion-icon>
        </div>
      </div>
    </div>
    <div class="patient_list d-flex align-items-center justify-content-sb px-15 py-10 borderBottom05 cursor">
      <div class="d-flex align-items-center">
        <ion-avatar class="pateint_text small-avatar" slot="start">
          AP
        </ion-avatar>
        <div class="">
          <div class="line-13">
            <ion-text class="font16 text-blue-color" color="text-blue-color"><b>Patient,</b> Another</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="font13-italic" color="gray80">99y F </ion-text>
            <ion-text class="bodyXS-txt" color="gray20">|</ion-text>
            <ion-text class="font13-italic" color="gray80"> 12/31/1900</ion-text>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="mr-15">
          <div class="line-13 ion-text-right">
            <ion-text class="caption" color="gray80">8:00 AM</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="caption" color="gray80">C. Calhoon</ion-text>
          </div>
        </div>
        <div>
          <ion-icon src="../../../../../../assets/svg/D-chevron-right.svg"></ion-icon>
        </div>
      </div>
    </div>
    <div class="patient_list d-flex align-items-center justify-content-sb px-15 py-10 borderBottom05 pointer">
      <div class="d-flex align-items-center">
        <ion-avatar class="pateint_text small-avatar" slot="start">
          AP
        </ion-avatar>
        <div class="">
          <div class="line-13">
            <ion-text class="font16 text-blue-color" color="text-blue-color"><b>Patient,</b> Another</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="font13-italic" color="gray80">99y F </ion-text>
            <ion-text class="bodyXS-txt" color="gray20">|</ion-text>
            <ion-text class="font13-italic" color="gray80"> 12/31/1900</ion-text>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="mr-15">
          <div class="line-13 ion-text-right">
            <ion-text class="caption" color="gray80">8:00 AM</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="caption" color="gray80">B. Turlington</ion-text>
          </div>
        </div>
        <div>
          <ion-icon src="../../../../../../assets/svg/D-chevron-right.svg"></ion-icon>
        </div>
      </div>
    </div>
    <div class="patient_list d-flex align-items-center justify-content-sb px-15 py-10 borderBottom05 cursor">
      <div class="d-flex align-items-center">
        <ion-avatar class="pateint_text small-avatar" slot="start">
          AP
        </ion-avatar>
        <div class="">
          <div class="line-13">
            <ion-text class="font16 text-blue-color" color="text-blue-color"><b>Patient,</b> Another</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="font13-italic" color="gray80">99y F </ion-text>
            <ion-text class="bodyXS-txt" color="gray20">|</ion-text>
            <ion-text class="font13-italic" color="gray80"> 12/31/1900</ion-text>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="mr-15">
          <div class="line-13 ion-text-right">
            <ion-text class="caption" color="gray80">8:00 AM</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="caption" color="gray80">R. Bigsby</ion-text>
          </div>
        </div>
        <div>
          <ion-icon src="../../../../../../assets/svg/D-chevron-right.svg"></ion-icon>
        </div>
      </div>
    </div>
    <div class="patient_list d-flex align-items-center justify-content-sb px-15 py-10 borderBottom05 pointer">
      <div class="d-flex align-items-center">
        <ion-avatar class="pateint_text small-avatar" slot="start">
          AP
        </ion-avatar>
        <div class="">
          <div class="line-13">
            <ion-text class="font16 text-blue-color" color="text-blue-color"><b>Patient,</b> Another</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="font13-italic" color="gray80">99y F </ion-text>
            <ion-text class="bodyXS-txt" color="gray20">|</ion-text>
            <ion-text class="font13-italic" color="gray80"> 12/31/1900</ion-text>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="mr-15">
          <div class="line-13 ion-text-right">
            <ion-text class="caption" color="gray80">8:00 AM</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="caption" color="gray80">R. Bigsby</ion-text>
          </div>
        </div>
        <div>
          <ion-icon src="../../../../../../assets/svg/D-chevron-right.svg"></ion-icon>
        </div>
      </div>
    </div>
    <div class="patient_list d-flex align-items-center justify-content-sb px-15 py-10 borderBottom05 cursor">
      <div class="d-flex align-items-center">
        <ion-avatar class="pateint_text small-avatar" slot="start">
          AP
        </ion-avatar>
        <div class="">
          <div class="line-13">
            <ion-text class="font16 text-blue-color" color="text-blue-color"><b>Patient,</b> Another</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="font13-italic" color="gray80">99y F </ion-text>
            <ion-text class="bodyXS-txt" color="gray20">|</ion-text>
            <ion-text class="font13-italic" color="gray80"> 12/31/1900</ion-text>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="mr-15">
          <div class="line-13 ion-text-right">
            <ion-text class="caption" color="gray80">8:00 AM</ion-text>
          </div>
          <div class="line-15">
            <ion-text class="caption" color="gray80">B. Turlington</ion-text>
          </div>
        </div>
        <div>
          <ion-icon src="../../../../../../assets/svg/D-chevron-right.svg"></ion-icon>
        </div>
      </div>
    </div>
    
    
  </div>
  <clinic-dashboard-search-noresult *ngIf="isNoResult"></clinic-dashboard-search-noresult>
</div>
