import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'clinic-dashboard-header',
  templateUrl: './clinic-dashboard-header.component.html',
  styleUrls: ['./clinic-dashboard-header.component.scss'],
})
export class ClinicDashboardHeaderComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
