<ion-header class="main_container">
  <div class="ion-header-bg-color mid-container header">   
    <div class="w-100 justify-content-sb d-flex heading-block mid-header-section align-items-center" >
      <div class="w-100 d-flex top-div">
        <div class="column ion-text-center">
          <ion-text color="gray80" class="bodyL-txt-large pb-3 ion-text-nowrap main-line"><span class="title">Michigan Institute of Urology</span></ion-text>
          <ion-text color="gray80" class="bodyS-txt pt-3 tagline">Our tagline is the best tagline for you</ion-text>
          </div>
      </div>  
     
        <!-- Tablet-in-clinic patient-view -->
        <!-- <div class="row txt-end">
          <div class="column text-right">
            <ion-text class="bodyXL-txt "  color="secondary100">8:00 AM</ion-text>
            <ion-text class="bodyXS-txt text-blue-color" color="text-blue-color">Dr Jessica Smith</ion-text>
          </div>
        </div> -->
        <!--End of Tablet-in-clinic patient-view -->

        <div class="row txt-end w-100">
          <div class="column border-right text-right pr-10 ">
            <ion-text class="bodyM-txt "  color="secondary100">8:00 AM</ion-text>
            <ion-text class="bodyM-txt" color="secondary100">12/20/23</ion-text>
          </div>
          <div class="column pl-10">
            <ion-text class="bodyXS-txt text-center" color="gray80">Downtown Clinic</ion-text>
            <ion-text class="number-txt ion-color-gray80">(734) 455-8888</ion-text>
          </div>
        </div>
        </div>
     </div>
   </ion-header>