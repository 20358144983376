<div class="patient-information-main scroll_main">
    <self-scheduling-header></self-scheduling-header>

    <!-- <div class="mid-header-section ion-bg-unfocused-color mid-comment-border main_container scroll_body"> -->
    <div class="container p-0">
        <div class="patient-information scroll_body">
            <div class="card-content main-div " [ngClass]="isInClinicTabDoneFooter == true ? '' : 'allDoneFooter'">
                <ion-grid class="grid-cards">
                    <ion-item class="ion-no-padding bg-gray20 schedule-card"
                        [ngClass]="status ? 'item-background-color' : 'item-background-color'">
                        <div class="d-flex card-data ion-justify-content-between w-100 px-50 pt-30 pb-15">
                            <div class="w-70 mobile100">
                                <div class="stepper-title d-flex align-items-center mb-14" *ngIf="ishideinsurance">
                                    <div class="stepper-number">
                                        <span class="stepper-circle">
                                            <ion-text name="checkmark-outline"
                                                class=" bodyL-txt-medium text-blue-color tabletFont">
                                                1
                                            </ion-text>
                                        </span>
                                    </div>

                                    <ion-text class="h3-M-secondary text-blue-color bodyL-txt-bold-mobile tabletFont">
                                        Enter patient information
                                    </ion-text>
                                </div>

                                <div class="justify-content-sb stepper-success row ion-align-items-center d-flex mb-15"
                                    *ngIf="isshowinsurance">
                                    <div class="confirm-info d-flex align-items-center">
                                        <div class="green-circle">
                                            <span class="circle">
                                                <ion-icon name="checkmark-outline" class="ion-color-white"
                                                    color=""></ion-icon>
                                            </span>
                                        </div>

                                        <ion-text
                                            class="h3-success h4-L-success-mobile text-green-color right-circle tabletFont">Information
                                            entered</ion-text>
                                    </div>
                                    <!-- <ion-text class="bodyL-txt edit text-HLblue-color bodyS-txt-Mobile pointer"
                                        *ngIf="isDesktop" (click)="openInfoPopup()">
                                        Edit
                                    </ion-text> -->
                                    <ion-text class="bodyL-txt edit text-HLblue-color bodyS-txt-Mobile pointer"
                                        *ngIf="isMobile" routerLink="/app/patient-info-popup">
                                        Edit
                                    </ion-text>
                                </div>

                                <div class="d-flex align-items-baseline">
                                    <div class="pl-45 w-100" *ngIf="!isshowinsurance">
                                        <div class=" mb-15 w-100">
                                            <ion-text class="bodyXL-txt-bold  sub-title bodyM-txt-mobile mb-10" color="gray100"
                                                >Patient Name</ion-text>
                                        </div>
                                        <div class="d-flex  align-items-baseline mobileColumn">
                                            <div class="column info-data focus w-50 mobile100" id="patientEditBasicInfo">
                                              <div class="d-flex add-cont mr-20">
                                                <div class="column mb-20 mobilemb-0">
                                                  <div class="d-flex mb-10 ">
                                                    <div class="mr-10">
                                                        <ion-icon class="h3" src="../../../../../assets/svg/map-pin 1.svg" color="gray60"></ion-icon>
                                                    </div>
                                                    <div>
                                                        <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray80">Address</ion-text>
                                                        <!-- <ion-text class="bodyM-txt bodyM-txt-mobile" color="gray80">Address not entered</ion-text> -->
                                                        <!-- <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">123 NW Buckeye St Ann Arbor, MI 48104</ion-text> -->
                                                    </div>
                                                  </div>
                                                  <div class="d-flex mb-10 ">
                                                    <div class="mr-10">
                                                        <ion-icon class="h3 ion-color-gray60 " src="../../../../../assets/svg/add-calender.svg" ></ion-icon>
                                                    </div>
                                                    <div>
                                                        <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray80">Date of birth</ion-text>
                                                    </div>
                                                  </div> 
                                                  <div class="d-flex mb-10 ">
                                                    <div class="mr-10">
                                                        <ion-icon class="h3 ion-color-gray60" src="../../../../../assets/svg/gender1.svg"></ion-icon>
                                                    </div>
                                                    <div>
                                                        <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray80">Sex</ion-text>
                                                    </div>
                                                  </div>
                                                  <div class="d-flex mb-10 ">
                                                    <div class="mr-10">
                                                        <ion-icon class="h3" src="../../../../../assets/svg/mail 1.svg" color="gray60"></ion-icon>
                                                    </div>
                                                    <div>
                                                        <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray80">Email</ion-text>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                
                                            </div>
                                            <div class="mb-15 emergency-contact column w-50 mobile100">             
                                                <div class="d-flex mb-10 ">
                                                    <div class="mr-10">
                                                        <ion-icon class="h3" src="../../../../../assets/svg/Phone 2.svg" color="gray60"></ion-icon>
                                                    </div>
                                                    <div>
                                                        <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray80">Phone</ion-text>
                                                    </div>
                                                </div>
                                                <div class="d-flex mb-10 ">
                                                    <div class="mr-10">
                                                        <ion-icon class="h3" src="../../../../../assets/svg/home 1.svg" color="gray60"></ion-icon>
                                                    </div>
                                                    <div>
                                                        <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray80">Home Number</ion-text>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="column info-data focus w-65" id="patientEditBasicInfo" >
                                        <ul class="m-0 pl-70">
                                            <li class="point bodyL-txt bodyM-txt-mobile mb-8">
                                                <ion-text color="gray80">Name</ion-text>
                                            </li>
                                            <li class=" point mb-8 bodyL-txt bodyM-txt-mobile">
                                                <ion-text color="gray80">Date of birth</ion-text>
                                            </li>
                                            <li class="point mb-8 bodyL-txt bodyM-txt-mobile">
                                                <ion-text color="gray80">Sex</ion-text>
                                            </li>
                                            <li class="point mb-8 bodyL-txt bodyM-txt-mobile">
                                                <ion-text color="gray80">Email</ion-text>
                                            </li>
                                            <li class="point mb-8 bodyL-txt bodyM-txt-mobile">
                                                <ion-text color="gray80">Phone</ion-text>
                                            </li>
                                            <li class="point bodyL-txt bodyM-txt-mobile">
                                                <ion-text color="gray80">Zip code</ion-text>
                                            </li>
                                        </ul>
                                    </div> -->

                                    <!-- <div class="pl-46 column info-data focus" id="patientEditBasicInfo" *ngIf="isshowinsurance">
                                        <div class="column mb-12">
                                            <ion-text class="bodyXL-txt-bold sub-title bodyM-txt-bold-mobile"
                                                color="gray100" *ngIf="ishideinsurance">Charlotte “Charlie”
                                                Nedermeir</ion-text>
                                            <ion-text class="bodyL-txt-italic bodyM-txt-mobile" color="gray100">63y
                                                Female (born 02/13/1954)</ion-text>
                                        </div>

                                        <div class="column">
                                            <ion-text class="bodyL-txt bodyM-txt-mobile mb-12" color="gray100"> <span
                                                    class="bodyS-txt-medium text-gray80-color uppercase">Email</span>
                                                cnedermeir7@gmail.com
                                            </ion-text>
                                            <ion-text class="bodyL-txt bodyM-txt-mobile mb-12" color="gray100">
                                                <span class="bodyS-txt-medium text-gray80-color uppercase">Mobile</span>
                                                (352)724-7979
                                            </ion-text>
                                            <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">
                                                <span class="bodyS-txt-medium text-gray80-color uppercase">ZIP</span>
                                                48104
                                            </ion-text>
                                        </div>
                                    </div> -->
                                    <div class="pl-45 w-100" *ngIf="isshowinsurance">
                                        <div class=" mb-15 w-100">
                                            <ion-text class="bodyXL-txt-bold  sub-title bodyM-txt-mobile mb-10" color="gray100"
                                                >Charlotte “Charlie”
                                                Nedermeir</ion-text>
                                        </div>
                                        <div class="d-flex  align-items-baseline mobileColumn">
                                            <div class="column info-data focus w-50 mobile100" id="patientEditBasicInfo">
                                              <div class="d-flex add-cont mr-20">
                                                <div class="column mb-20 mobilemb-0">
                                                  <div class="d-flex mb-10 ">
                                                    <div class="mr-10">
                                                        <ion-icon class="h3" src="../../../../../assets/svg/map-pin 1.svg" color="gray60"></ion-icon>
                                                    </div>
                                                    <div>
                                                        <!-- <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray80">Address</ion-text> -->
                                                        <!-- <ion-text class="bodyM-txt bodyM-txt-mobile" color="gray80">Address not entered</ion-text> -->
                                                        <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">123 NW Buckeye St Ann Arbor, MI 48104</ion-text>
                                                        
                                                    </div>
                                                  </div>
                                                  <div class="d-flex mb-10 ">
                                                    <div class="mr-10">
                                                        <ion-icon class="h3 ion-color-gray60 " src="../../../../../assets/svg/add-calender.svg" ></ion-icon>
                                                    </div>
                                                    <div>
                                                        <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">02/13/1954</ion-text>
                                                        <!-- <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray80">Date of birth</ion-text> -->
                                                    </div>
                                                  </div> 
                                                  <div class="d-flex mb-10 ">
                                                    <div class="mr-10">
                                                        <ion-icon class="h3 ion-color-gray60" src="../../../../../assets/svg/gender1.svg"></ion-icon>
                                                    </div>
                                                    <div>
                                                        <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">Female</ion-text>
                                                        <!-- <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray80">Sex</ion-text> -->
                                                    </div>
                                                  </div>
                                                  <div class="d-flex mb-10 ">
                                                    <div class="mr-10">
                                                        <ion-icon class="h3" src="../../../../../assets/svg/mail 1.svg" color="gray60"></ion-icon>
                                                    </div>
                                                    <div>
                                                        <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">charlee@gmail.com</ion-text>>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                
                                            </div>
                                            <div class="mb-15 emergency-contact column w-50 mobile100">             
                                                <div class="d-flex mb-10 ">
                                                    <div class="mr-10">
                                                        <ion-icon class="h3" src="../../../../../assets/svg/Phone 2.svg" color="gray60"></ion-icon>
                                                    </div>
                                                    <div>
                                                        <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray80">Phone</ion-text>
                                                    </div>
                                                </div>
                                                <div class="d-flex mb-10 align-items-center">
                                                    <div class="mr-10">
                                                        <ion-icon class="h3" src="../../../../../assets/svg/home 1.svg" color="gray60"></ion-icon>
                                                    </div>
                                                    <div>
                                                        <ion-text class="bodyM-txt bodyM-txt-mobile" color="gray80">Home number not entered</ion-text>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mobile100">
                                <!-- <div *ngIf="isshowinsurance"> -->
                                <div class="buttons-flex" *ngIf="isshowinsurance">
                                    <ion-button fill="" expand="block" class="filled-clear-btn capitalize btn mb-15"
                                        *ngIf="isDesktop">
                                        <ion-text class="bodyL-txt btn edit text-HLblue-color bodyS-txt-Mobile pointer"
                                            (click)="openInfoPopup()">
                                            Edit
                                        </ion-text>
                                    </ion-button>
                                </div>
                                <div class="buttons-flex " *ngIf="ishideinsurance">
                                    <ion-button fill="solid" expand="block" color="ion-color-white"
                                        class="filled-icon-btn capitalize btn mb-15" (click)="openInfoPopup()"
                                        (click)="showbtn()" (click)="unfocused()" (click)="mobilebtnshow()">

                                        <ion-text class="bodyL-txt-bold">Start</ion-text>
                                    </ion-button>
                                </div>
                            </div>
                        </div>

                    </ion-item>

                    <!-- Insurance card  -->

                    <ion-item class="ion-no-padding schedule-card"
                        [ngClass]="status ? 'item-background-color' : 'item-background-color'">
                        <div class="d-flex card-data ion-justify-content-between w-100 px-50 pt-15 pb-15">
                            <div class="w-60 mobile100">
                                <div class="stepper-title d-flex k-align-content-baseline mb-14" *ngIf="ishideinsurance">
                                    <div class="stepper-number">
                                        <span class="stepper-circle">
                                            <ion-text name="checkmark-outline"
                                                class=" bodyL-txt-medium text-blue-color tabletFont"
                                                color="">2</ion-text>
                                        </span>
                                    </div>

                                    <div>
                                        <div class="mb-5">
                                            <ion-text class="h3-M-secondary text-blue-color bodyL-txt-bold-mobile tabletFont">Choose your insurance  ?</ion-text>
                                        </div>
                                        <div>
                                            <ion-text class="bodyM-txt d-block" color="gray80">Press "Yes" to provide your details now or "No" to skip</ion-text>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="justify-content-sb stepper-success row ion-align-items-center d-flex mb-15"
                                *ngIf="isshowinsurance" >
                                    <div class="confirm-info d-flex align-items-center">
                                        <div class="green-circle">
                                            <span class="circle">
                                                <ion-icon name="checkmark-outline" class="ion-color-white"
                                                    color=""></ion-icon>
                                            </span>
                                        </div>

                                        <ion-text
                                            class="h3-success h4-L-success-mobile text-green-color right-circle tabletFont">Insurance
                                            confirmed</ion-text>
                                    </div>

                                    <ion-text class="bodyL-txt edit text-HLblue-color bodyS-txt-Mobile pointer pr-50"
                                        *ngIf="isMobile" routerLink="/app/patient-insurance-popup">
                                        Edit
                                    </ion-text>
                                </div>

                                <!-- <div class="justify-content-sb stepper-success row ion-align-items-center d-flex mb-15" *ngIf="isshowinsurance">


                                    <div class="confirm-info d-flex align-items-center">
                                        <div class="green-circle">
                                            <span class="circle">
                                                <ion-icon name="checkmark-outline" class="ion-color-white"
                                                    color=""></ion-icon>
                                            </span>
                                        </div>

                                        <ion-text
                                            class="h3-success h4-L-success-mobile text-green-color right-circle tabletFont">Self Pay</ion-text>
                                    </div>

                                    <ion-text class="bodyL-txt edit text-HLblue-color bodyS-txt-Mobile pointer pr-50"
                                        *ngIf="!isDesktop" routerLink="/app/patient-insurance-popup">
                                        Edit
                                    </ion-text>
                                </div> -->

                                <div class="d-flex align-items-baseline">
                                    <div class="column info-data focus w-100" id="patientEditBasicInfo">
                                        <!-- <ion-text class="bodyL-txt sub-title mb-8 bodyM-txt-mobile" color="gray80"
                                        *ngIf="ishideinsurance">-Insurance or self pay</ion-text>
                                        <ion-text class="bodyXL-txt sub-title bodyM-txt-mobile mb-10" color="gray100"
                                        *ngIf="isshowinsurance">Charlotte Nedermeir</ion-text> -->

                                        <!-- <div class="column info-data pl-46 w-100">
                                                <ion-text class="bodyL-txt bodyM-txt-mobile ">Payment will be due at visit</ion-text>
                                            </div> -->

                                        <div class="d-flex add-cont">
                                            <!-- <div class="column info-data" *ngIf="!isshowinsurance">

                                                <ul class="m-0 pl-70">
                                                    <li class="point bodyL-txt bodyM-txt-mobile mb-8">
                                                        <ion-text color="gray80">Insurance or self pay</ion-text>
                                                    </li>
                                                    <li class=" point mb-8 bodyL-txt bodyM-txt-mobile">
                                                        <ion-text color="gray80">Insurance company</ion-text>
                                                    </li>
                                                    <li class="point mb-8 bodyL-txt bodyM-txt-mobile">
                                                        <ion-text color="gray80">Member number</ion-text>
                                                    </li>
                                                    <li class="point mb-8 bodyL-txt bodyM-txt-mobile">
                                                        <ion-text color="gray80">Group number</ion-text>
                                                    </li>
                                                    <li class="point mb-8 bodyL-txt bodyM-txt-mobile">
                                                        <ion-text color="gray80">Policy holder</ion-text>
                                                    </li>
                                                    <li class="point bodyL-txt bodyM-txt-mobile">
                                                        <ion-text color="gray80">Photo of card (optional)</ion-text>
                                                    </li>
                                                </ul>
                                            </div> -->
                                            <div class="column info-data focus pl-46 w-100" id="patientEditBasicInfo" *ngIf="isshowinsurance">
                                                <div class="column mb-12">
                                                    <ion-text
                                                        class="bodyXL-txt-bold sub-title bodyM-txt-bold-mobile mb-12"
                                                        color="gray100" *ngIf="ishideinsurance">Blue Cross of Michigan
                                                        PPO</ion-text>
                                                    <div class="row-new mb-10">
                                                        <div class="d-flex">
                                                            <div class=" column pr-30">
                                                                <ion-text color="gray80" class="bodyM-txt bodyXS-txt-mobile pb-3">MEMBER NUMBER</ion-text>
                                                                <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">789654352</ion-text>
                                                            </div>
                                                            <div class=" column">
                                                                <ion-text color="gray80" class="bodyM-txt bodyXS-txt-mobile pb-3">GROUP NUMBER</ion-text>
                                                                <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">856420</ion-text>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div class="insurance-card-img d-flex mb-20">
                                                        <div class="mr-20">
                                                            <ion-img class="img-card"
                                                                src="../../../../../assets/svg/Insurance card.svg"></ion-img>
                                                        </div>
                                                        <div class="">
                                                            <ion-img class="img-card"
                                                                src="../../../../../assets/svg/Insurance card_2.svg"></ion-img>
                                                        </div>
                                                    </div>

                                                    <div class="pointer">
                                                        <ion-text class="bodyM-txt cursor text-HLblue-color"
                                                            (click)="openInsurancePopup()">+ Add Insurance
                                                            Policy</ion-text>
                                                    </div>

                                                </div>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="mobile100">
                                <div class="buttons-flex d-flex" *ngIf="isshowinsurance">
                                    <ion-button fill="" expand="block"  *ngIf="isDesktopDevice || isTablet"
                                        class="filled-clear-btn capitalize btn mb-15">
                                        <ion-text class="bodyL-txt btn edit bodyS-txt-Mobile pointer" color="error60" (click)="openDeleteInsurance()">
                                            Delete
                                        </ion-text>
                                    </ion-button>
                                    <ion-button fill="" expand="block" class="filled-clear-btn capitalize btn mb-15"
                                        *ngIf="isDesktop">
                                        <ion-text class="bodyL-txt btn edit text-HLblue-color bodyS-txt-Mobile pointer"
                                            (click)="openInsurancePopup()">
                                            Edit
                                        </ion-text>
                                    </ion-button>
                                </div>
                                <div class="buttons-flex insurance-btn" *ngIf="ishideinsurance">

                                    <ion-button fill="solid" expand="block" color="ion-color-white"
                                        class="filled-icon-btn capitalize btn mb-15" (click)="openInsurancePopup()"
                                        (click)="showbtn()" (click)="unfocused()" (click)="mobilebtnshow()"
                                        *ngIf="isDesktop">
                                        <ion-icon name="checkmark-outline" color="white" class="mr-10"></ion-icon>
                                        <ion-text class="pr-10">Yes</ion-text>
                                    </ion-button>

                                    <ion-button fill="solid" expand="block" color="ion-color-white"
                                        class="filled-icon-btn capitalize btn ml-15" *ngIf="isMobile"
                                        routerLink="/app/patient-insurance-popup">

                                        <ion-text>Yes</ion-text>
                                    </ion-button>

                                    <!-- <ion-button fill="outline" expand="block" color="highlight" class="filled-outline-btn capitalize btn mb-15"
                          (click)="showinsurance()" (click)="unfocused()" *ngIf="ishidebtn">
                          <ion-icon name="checkmark-outline" color="white" class="mr-10"></ion-icon>
                          <ion-text>Yes</ion-text>
                        </ion-button> -->

                                    <ion-button fill="outline" color="highlight"
                                        class="filled-outline-btn capitalize btn" *ngIf="isDesktopDevice || isTablet"
                                        (click)="openInfoPopup()">

                                        <ion-icon src="../../../../../../../assets/svg/edit-2.svg"
                                            class="mr-10"></ion-icon>
                                        <ion-text>No</ion-text>
                                    </ion-button>
                                    <ion-button fill="outline" color="highlight"
                                        class="filled-outline-btn capitalize mr-15 btn no-btn" *ngIf="isMobile"
                                        routerLink="/app/patient-scheduling">
                                        <ion-text>No</ion-text>
                                    </ion-button>

                                </div>
                            </div>
                        </div>
                    </ion-item>


                </ion-grid>
            </div>
        </div>
    </div>

    <div class="schedule-footer">
        <kendo-dialog-actions style="border-top: 1px solid #CCD4D8;" class="p-0">
            <div class="w-100 scheduleStepper px-30 py-20">
                <kendo-stepper class="" [steps]="steps" [(currentStep)]="current">
                </kendo-stepper>

                <div class=" w-100 d-flex mt-15 align-items-center justify-content-sb" *ngIf="isDesktop">
                    <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize btn m-0"
                        routerLink="/app/patient-appointment-time">
                        <ion-icon src="../../../../assets/svg/arrow-left.svg" color="gray100"></ion-icon>
                        <ion-text class="ml-10">Appointment time</ion-text>
                    </ion-button>

                    <ion-button fill="solid" class="filled-icon-btn capitalize btn m-0" (click)="next()">
                        <ion-text color="white">Confirm and book</ion-text>
                        <ion-icon src="../../../../assets/svg/arrow-right.svg" color="white" class="ml-10"></ion-icon>
                    </ion-button>
                </div>
                <div class=" w-100 d-flex mt-15 align-items-center justify-content-sb" *ngIf="!isDesktop">
                    <ion-button fill="outline" color="highlight" routerLink="/app/patient-appointment-time"
                        class="filled-outline-btn capitalize btn m-0 w-100 mr-15">
                        <ion-icon src="../../../../assets/svg/arrow-left.svg" color="gray100"></ion-icon>
                        <ion-text class="ml-10">Back</ion-text>
                    </ion-button>

                    <ion-button fill="solid" class="filled-icon-btn capitalize btn m-0 w-100" (click)="next()">
                        <ion-text color="white">Next</ion-text>
                        <ion-icon src="../../../../assets/svg/arrow-right.svg" color="white" class="ml-10"></ion-icon>
                    </ion-button>
                </div>
            </div>
        </kendo-dialog-actions>
    </div>

</div>





<div class="kendopopup patientInfo_popup">
    <kendo-dialog *ngIf="patientInfoOpened">
        <div *ngIf="isInfo">
            <kendo-dialog-titlebar class="text-center">
                <div class="ion-align-items-center w-100">
                    <ion-text class="h3 tabletFont" color="gray100">Patient Information</ion-text>
                </div>
            </kendo-dialog-titlebar>
            <app-patient-information-popup></app-patient-information-popup>
        </div>

        <div *ngIf="isinsurance">
            <kendo-dialog-titlebar class="text-center">
                <div class="ion-align-items-center w-100">
                    <ion-text class="h3 tabletFont" color="gray100">Add your insurance</ion-text>
                </div>
            </kendo-dialog-titlebar>
            <patient-insurance-popup></patient-insurance-popup>
        </div>

        <kendo-dialog-actions class="border">
            <div class="py-10 row w-100 ion-justify-content-center align-items-center" *ngIf="isDesktopDevice || isTablet">
                <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn mr-20"
                    (click)="onclose()">
                    <ion-text>Cancel</ion-text>
                </ion-button>
                <ion-button fill="solid" expand="block" color="ion-color-white"
                    class="filled-icon-btn capitalize info-btn" (click)="showinsurance()" (click)="onclose()">
                    <ion-text>done</ion-text>
                </ion-button>
            </div>
        </kendo-dialog-actions>
    </kendo-dialog>

    <!-- <kendo-dialog *ngIf="isPatientFormOpened">
        <div class="patient_form_main">
            <kendo-dialog-titlebar style="border-bottom: 1px solid #ccd4d8">
                <div class="d-flex w-100 align-items-center justify-content-sb">
                    <div class="text-center w-100">
                        <ion-text class="h3-Montserrat tabletFont" color="gray100">Patient Health History</ion-text>
                    </div>
                    <ion-icon src="../../../../assets/svg/x.svg" color="gray80" class="h3 mr-10 cursor"
                        (click)="hidePatientForm()"></ion-icon>
                </div>
            </kendo-dialog-titlebar>
        </div>
        <pre-registration-patient-form (hideformpopup)="hideformpopup($event)"></pre-registration-patient-form>
    </kendo-dialog> -->
</div>

<!-- <div class="patientCard">
    <kendo-dialog *ngIf="isPatientCardOpened">
        <app-pre-registration-view-insurance-card
            (isPatientCardClose)="closePatientCard($event)"></app-pre-registration-view-insurance-card>
    </kendo-dialog>
</div> -->
<div class="deleteInsurance">
    <kendo-dialog class="deleteInsurancePopup" *ngIf="isDeleteInsuranceOpened">
        <div class="main-div ion-justify-content-center align-items-center note-box">
        <div class="payment-info mb-20 px-30 column py-15 text-center">
            <ion-text class="h3-Montserrat bodyL-txt-bold-mobile tabletFont line-28" color="gray100">
            Hey, you want to delete an insurance details?
            </ion-text>
        </div>
        <div class="btn-box w-100 py-20 text-center">
            <ion-button fill="outline" color="highlight"
            class="filled-outline-btn capitalize  w-70  mb-25">
            <ion-text>Yes</ion-text>
            </ion-button>
            <ion-button fill="solid" color="ion-color-white" class="filled-icon-btn capitalize w-70"
            (click)="closeDeleteInsurance()">
            <ion-text>No</ion-text>
            </ion-button>
        </div>
        </div>
    </kendo-dialog>
</div>