import { Component, OnInit } from '@angular/core';
import { StepType } from '@progress/kendo-angular-layout';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router  } from '@angular/router';

@Component({
  selector: 'app-patient-appointment-time',
  templateUrl: './patient-appointment-time.component.html',
  styleUrls: ['./patient-appointment-time.component.scss'],
})
export class PatientAppointmentTimeComponent  implements OnInit {
  public stepType: StepType = "indicator";
  public stepTypes: Array<StepType> = ["indicator", "label", "full"];
  public current = 0;

  isMobile!: boolean;
  isTablet!: boolean;
  isDesktopDevice!: boolean;
  deviceInfo: import("ngx-device-detector").DeviceInfo | undefined;
  
  constructor(private deviceService: DeviceDetectorService, private router: Router) { }

  ngOnInit() {
    this.epicFunction();
    this.mobileWorkflow();
  }
  epicFunction() {
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    console.log("Mobile : " +this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log("Tablet : " +this.isTablet);  // returns if the device us a tablet (iPad etc)
    console.log("Desktop : " +this.isDesktopDevice); // returns if the app is running on a Desktop browser.
  }

  public selectedPatient = "New Patient";
  public patientList : Array<string> = [
      "New Patient",
      "New Patient", 
      "New Patient"
   ];
   public selectedExistingPatient = "Follow up";
   public existingPatientList : Array<string> = [
    "Follow-up",
    "Follow-up", 
    "Follow-up"
 ];
  public locationList : Array<string> = [
      "Location 1",
      "Location 2", 
      "Location 3"
   ];
  public providerList : Array<string> = [
    "Provider 1",
    "Provider 2", 
    "Provider 3"
  ];
  public reasonList : Array<string> = [
    "Reason 1",
    "Reason 2", 
    "Reason 3"
  ];
  public value: Date = new Date(2024, 4, 25);
  public disabledDates: Date[] = [
    new Date(2024, 4, 1),
    new Date(2024, 4, 2),
    new Date(2024, 4, 3),
    new Date(2024, 4, 4),
    new Date(2024, 4, 9),
    new Date(2024, 4, 12),
    new Date(2024, 4, 22),
  ];

  public steps = [
    { label: "First step", isValid: true },
    { label: "Second step", isValid: true },
    { label: "Third step", isValid: true,},
  ];

  public prev(): void {
    if (this.current > 0) {
      this.current -= 1;
    }
  }

  public next(): void {
    this.router.navigate(['/app/patient-scheduling']);
    if (this.current < this.steps.length - 1) {
      this.current += 1;
    }
  }

  isSelectSlot:boolean = false;
  selectSlot(){
    this.isSelectSlot = !this.isSelectSlot;
  }

  appointmentList = true;
  appointmentListFooter = false;
  appointmentFilter = true;
  appointmentFilterFooter = false;
  isDesktopFooter = true;
  mobileWorkflow(){
    if(this.isMobile == true){
      this.appointmentList = false;
      this.isDesktopFooter = false;
      this.appointmentFilterFooter = true;
    }
    if(this.isDesktopDevice == true || this.isTablet == true){
      this.isDesktopFooter = true;
      this.appointmentList = true;
    }
  }
  showAppointementList(){
    this.appointmentList = true;
    this.appointmentFilter = false;
    this.appointmentListFooter = true;
    this.appointmentFilterFooter = false;
  }
  showAppointmentFilter(){
    this.appointmentList = false;
    this.appointmentFilter = true;
    this.appointmentListFooter = false;
    this.appointmentFilterFooter = true;
  }

}
