<div class="patient_schedule_main scroll_main" >
  <div class="ion-header-bg-color header" *ngIf="isTablet || isDesktopDevice">
    <div class="row-new text-center mid-header-section px-30 ">
      <div class="col-8-lg col-6-md text-center d-flex align-items-center justify-content-center">
        <div class="column">
          <ion-text color="gray80" class="bodyL-txt-large pb-3 ion-text-nowrap"><span class="title">Seattle Institute of Urology</span></ion-text>
          <ion-text color="gray80" class="bodyS-txt pt-3">Our tagline is the best tagline for you</ion-text>
        </div>
      </div>
      <div class="col-4-lg col-6-md">
        <img src="../../../../assets/svg/Banner Image.svg" class="ml-5 img-fluid"/>
      </div>
    </div>
  </div>
  
  <div class="ion-header-bg-color headermobile" *ngIf="isMobile">
    <self-scheduling-header></self-scheduling-header>
  </div>
   
  <div class="scroll_body">
    <div class="container schedule_main ">
      <div class="schedule_title text-center py-20 px-50">
        <ion-text class="h1-Montserrat-regular h3-Montserrat-mobile text-blue-color">Schedule an appointment</ion-text>
      </div>
      <div class="tablet-steps" *ngIf="isTabletDevice || isMobile">
        <ion-text class="number-txt montserrat" color="gray80">It’ll take less than 5 minutes. To get started, just fill in the info below and press Find Appointment</ion-text>
      </div>
      <div class="row-new px-20 py-10">
        <div class="col-6-lg  col-12-sm schedule_steps p-50" *ngIf="!isTabletDevice && !isMobile">
          <div class="text-center mb-25">
            <ion-text class="bodyXL-txt-bold text-blue-color">It’s as easy as 1, 2, 3!</ion-text>
          </div>
          <div class="schedule_steps_item d-flex mb-25">
            <div class="schedule_steps_count mr-15">
              <ion-text class="bodyL-txt-bold" color="highlight">1</ion-text>
            </div>
            <div class="column">
              <ion-text class="bodyL-txt text-blue-color mb-5">Select an appointment time</ion-text>
              <ion-text class="bodyM-txt" color="gray100">Just tell us why your coming and which location and provider you want to visit.</ion-text>
            </div>
          </div>
          <div class="schedule_steps_item d-flex mb-25">
            <div class="schedule_steps_count mr-15">
              <ion-text class="bodyL-txt-bold" color="highlight">2</ion-text>
            </div>
            <div class="column">
              <ion-text class="bodyL-txt text-blue-color mb-5">Tell us a little about yourself</ion-text>
              <ion-text class="bodyM-txt" color="gray100">Don’t worry, just the basics, it’ll take less than 5 minutes.</ion-text>
            </div>
          </div>
          <div class="schedule_steps_item d-flex">
            <div class="schedule_steps_count mr-15">
              <ion-text class="bodyL-txt-bold" color="highlight">3</ion-text>
            </div>
            <div class="column">
              <ion-text class="bodyL-txt text-blue-color mb-5">Book it!</ion-text>
              <ion-text class="bodyM-txt" color="gray100">Our office will get notified, and you’ll receive a confirmation email.</ion-text>
            </div>
          </div>
        </div>
        
        <div class="col-6-lg col-6-md col-12-sm schedule_forms">
          <kendo-tabstrip tabPosition="top">
            <kendo-tabstrip-tab title="New Patient" [selected]="true">
              <ng-template kendoTabContent>
                <div class="mt-20">
                  <ion-text class="mb-3" class="bodyM-txt ion-text-uppercase mb-5" color="gray80">Reason for visit </ion-text><ion-text class="text-lowercase italic" color="gray80">(Required)</ion-text>
                  <kendo-combobox [data]="patientList" placeholder="Select Patient" [suggest]="true" class="bodyM-txt" [value]="selectedPatient">
                  </kendo-combobox>
                </div>
                <div class="mt-20">
                  <ion-text class="mb-3" class="bodyM-txt ion-text-uppercase mb-5" color="gray80">Location</ion-text>
                  <kendo-combobox [data]="locationList" placeholder="Any location" [suggest]="true" class="bodyM-txt">
                  </kendo-combobox>
                </div>
                <div class="mt-20">
                  <ion-text class="mb-3" class="bodyM-txt ion-text-uppercase mb-5" color="gray80">Provider</ion-text>
                  <kendo-combobox [data]="providerList" placeholder="Any provider" [suggest]="true" class="bodyM-txt">
                  </kendo-combobox>
                </div>
                <div class="mt-20 mb-15">
                  <ion-text class="mb-3" class="bodyM-txt ion-text-uppercase mb-5" color="gray80">Time of Day</ion-text>
                  <kendo-buttongroup selection="single" class="w-100">
                    <button class="bodyM-txt " id="btn1" kendoButton [toggleable]="true">
                      Any time
                    </button>
                    <button class="bodyM-txt ion-text-capitalize" id="btn2" kendoButton [toggleable]="true"
                        [selected]="true" >
                        AM
                    </button>
                    <button class="bodyM-txt ion-text-capitalize" id="btn3" kendoButton [toggleable]="true">
                        PM
                    </button>
                </kendo-buttongroup>
                </div>
                <div class="py-10 text-center"  *ngIf="isTablet || isDesktopDevice">
                  <ion-button fill="solid" color="ion-color-white" class="filled-icon-btn capitalize" routerLink="/app/patient-appointment-time">
                    <ion-text> Find Appointment</ion-text>
                    <ion-icon class="h3" src="../../../../../assets/svg/arrow-right.svg" class="ml-5"></ion-icon>
                  </ion-button>
                </div>
              </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="Existing Patient" >
              <ng-template kendoTabContent>
                <div class="mt-20">
                  <kendo-textbox class="bodyM-txt" placeholder="Username" color="gray100"></kendo-textbox>
                </div>
                <div class="mt-20">
                  <kendo-textbox placeholder="Password" class="bodyM-txt" #passwordInput="kendoTextBox"></kendo-textbox>
                </div>
                <div class="mt-20 mb-15">
                  <kendo-combobox [data]="reasonList" placeholder="Reason for visit *" [suggest]="true" class="bodyM-txt">
                  </kendo-combobox>
                </div>
                <div class="py-10 text-center"  *ngIf="isTablet || isDesktopDevice">
                  <ion-button fill="solid" color="ion-color-white" class="filled-icon-btn capitalize" routerLink="/app/patient-appointment-time">
                    <ion-text> Find Appointment</ion-text>
                    <ion-icon class="h3" src="../../../../../assets/svg/arrow-right.svg" class="ml-5"></ion-icon>
                  </ion-button>
                </div>
              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>
        </div>
      </div>
    </div>
  </div>

  <ion-footer *ngIf="isMobile" class="borderTopGray20 bg-white">
    <div class="py-10 px-30 text-center">
      <ion-button fill="solid" color="ion-color-white" class="filled-icon-btn capitalize w-100" routerLink="/app/patient-appointment-time">
        <ion-text> Find Appointment</ion-text>
        <ion-icon class="h3" src="../../../../../assets/svg/arrow-right.svg" class="ml-5"></ion-icon>
      </ion-button>
    </div>
  </ion-footer>
</div>