<div class="clinic_lock_screen_main align-items-center justify-content-center">
  <div class="clinic_lock_screen">
    <div class="lock_icon_main d-flex align-items-center justify-content-sb">
      <div><ion-icon src="../../../../../assets/svg/lock-screen.svg"  class="lock_icon" color="white"></ion-icon></div>
      <div class="text-center"><ion-text class="bodyXXL-txt-light" color="gray05">Your device is locked</ion-text></div>
    </div>
    <div class="clinic_lock_form_main">
      <div class="text-center pt-30 pb-10 width240">
        <ion-text class="bodyL-txt montserrat" color="gray05">Verify your <span class="semibold-font-weight">date of birth</span> to unlock and continue</ion-text>
      </div>
      <div class="pin_form_main p-10">
        <div class="d-flex align-items-center justify-content-sb  px-10">
          <kendo-datepicker format="MM/DD/YYYY" formatPlaceholder="formatPattern" class="h3-Montserrat"></kendo-datepicker>
        </div>
        <div class="pin_keypad pt-30 p-10">
          <div><button kendoButton class="bodyXL-txt mr-15 mb-15">1</button></div>
          <div><button kendoButton class="bodyXL-txt mr-15 mb-15">2</button></div>
          <div><button kendoButton class="bodyXL-txt mr-15 mb-15">3</button></div>
          <div><button kendoButton class="bodyXL-txt mr-15 mb-15">4</button></div>
          <div><button kendoButton class="bodyXL-txt mr-15 mb-15">5</button></div>
          <div><button kendoButton class="bodyXL-txt mr-15 mb-15">6</button></div>
          <div><button kendoButton class="bodyXL-txt mr-15 mb-15">7</button></div>
          <div><button kendoButton class="bodyXL-txt mr-15 mb-15">8</button></div>
          <div><button kendoButton class="bodyXL-txt mr-15 mb-15">9</button></div>
          <div></div>
          <div><button kendoButton class="bodyXL-txt mr-15">0</button></div>
          <div class="d-flex align-items-center justify-content-center">
            <!-- <ion-icon class="bodyXL-txt" color="gray80" src="../../../../../assets/svg/backspace_24px.svg"></ion-icon> -->
          </div>
        </div>
        <div class="pt-20 text-center">
          <ion-button fill="solid" color="P_blue" class="filled-icon-btn P_blue" routerLink="/app/welcome">
            <ion-text class="capitalize roboto" color="P_blue"  >Verify</ion-text>
            <ion-icon src="../../../../../../../assets/svg/arrow-right.svg" class="ml-10" color="P_blue"></ion-icon>
          </ion-button>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center">
      <div class="admin_lock_icon cursor">
        <div class="line-10 text-center"><ion-icon src="../../../../assets/svg/admin-lock.svg" class="h2" color="white"></ion-icon></div>
        <div class="line-10 text-center"><ion-text class="caption" color="white">admin</ion-text></div>
      </div>
    </div>
  </div>
</div>