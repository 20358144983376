import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'welcome-header',
  templateUrl: './welcome-header.component.html',
  styleUrls: ['./welcome-header.component.scss'],
})
export class WelcomeHeaderComponent  implements OnInit {
  isDesktop: boolean;
  // ismobile:boolean;
  breakpoint: any = window.matchMedia('(min-width: 767px)');
  // mobileview: any = window.matchMedia('(max-width: 768px)');
  constructor() {
    this.isDesktop = !!this.breakpoint.matches;
    // this.ismobile = !!this.mobileview.matches;
   }
  ngOnInit() {}

}
