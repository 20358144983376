import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { StepType } from '@progress/kendo-angular-layout';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'patient-information',
  templateUrl: './patient-information.component.html',
  styleUrls: ['./patient-information.component.scss'],
})
export class PatientInformationComponent  implements OnInit {

  isshowinsurance: boolean = false;
  ishideinsurance: boolean = true;
  isshowinsurance2: boolean = false;
  ishideinsurance2: boolean = true;

  isshowinsurance3: boolean = false;
  ishideinsurance3: boolean = true;

  isshowinsurance4: boolean = false;
  ishideinsurance4: boolean = true;

  isshowbtn: boolean = false;
  ishidebtn: boolean = true;

  isshowpay: boolean = false;
  ishidepay: boolean = true;

  bsModalRef!: BsModalRef;

  isFormFillOut: boolean = false;
  isFormFillOutBtn: boolean = false;

  isPatientFormOpened: boolean = false;
  isshowpaymentbtn:boolean=false;
  //   constructor() { }

  //   ngOnInit() {
 
  //   }
  showinsurance() {
    this.isshowinsurance = true;
    this.ishideinsurance = false;
  }

  showinsurance2() {
    this.isFormFillOutBtn = true;
    this.isshowinsurance2 = true;
    this.ishideinsurance2 = false;
  }

  showinsurance3() {
    this.isshowinsurance3 = true;
    this.ishideinsurance3 = false;
    this.isInClinicTabDoneFooter = false;
  }

  showinsurance4() {
    this.isshowinsurance4 = true;
    this.ishideinsurance4 = false;
  }

  showbtn(){
    this.isshowbtn = true;
    this.ishidebtn = false;
  }

  showpay(){
    this.isshowpay = true;
    this.ishidepay = false;
  }
  // showBasicEdit(){
  //   const showEdit = document.getElementById('patientEditBasicInfo');
  //   if(showEdit != null){
  //     if(showEdit.classList.contains('focus')){
  //       showEdit.classList.remove('focus');
  //       showEdit.classList.add('item-background-color')
  //     }
  //   }
  // }
  status: boolean = true;
  status2: boolean = false;
  status3: boolean = false;
  status4:boolean = false;

  unfocused() {
    this.status = !this.status;
    this.status2 = !this.status2;
  }

  unfocused2() {
    this.status2 = !this.status2;
    this.status3 = !this.status3;
  }
  
  unfocused3() {
    this.status3 = !this.status3;
  }
  

  // }   
  public stepType: StepType = "indicator";
  public stepTypes: Array<StepType> = ["indicator", "label", "full"];
  public current = 0;
  isshowcontinue: boolean = false;
  ishidecontinue: boolean = true;
  isshownote: boolean = false;
  ishidetext: boolean = true;
  isDesktop: boolean;
  
  breakpoint: any = window.matchMedia('(min-width: 767px)');
  isMobile!: boolean;
  isTablet!: boolean;
  isDesktopDevice!: boolean;
  deviceInfo: import("ngx-device-detector").DeviceInfo | undefined;
  constructor( private platform: Platform, private modalService: BsModalService ,public modalController: ModalController,private router: Router, private deviceService: DeviceDetectorService) {
    this.isDesktop = !!this.breakpoint.matches;
    this.platform.keyboardDidShow.subscribe(() => {
      this.isInputFocus();
    });
  
    this.platform.keyboardDidHide.subscribe(() => {
      // Move input back to original location
      this.isInputNotFocus();
    });
  }

  ngOnInit() {
    this.epicFunction();
  }

  epicFunction() {
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    console.log("Mobile : " +this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log("Tablet : " +this.isTablet);  // returns if the device us a tablet (iPad etc)
    console.log("Desktop : " +this.isDesktopDevice); // returns if the app is running on a Desktop browser.
  }
  
  ngOnChanges() {
   
  }

  public steps = [
    { label: "First step", isValid: true },
    { label: "Second step", isValid: true },
    { label: "Third step", isValid: true,},
  ];

  public prev(): void {
    if (this.current > 0) {
      this.current -= 1;
    }
  }

  public next(): void {
    this.router.navigate(['/app/confirm-appointment']);
    if (this.current < this.steps.length - 1) {
      this.current += 1;
    }
  }

  showcontinue() {
    this.isshowcontinue = true;
    this.ishidecontinue = false;
  }
  shownote() {
    this.isshownote = true;
    this.isshowcontinue = false;
    this.ishidetext = false;
  }
  patientInfoOpened: boolean = false;
  isInfo: boolean = false;
  ispayment:boolean = false;
  isinsurance:boolean=false;
  isshowfooter:boolean=false;
  isabandoned:boolean=false;
  addstatus:boolean=true;
  isInClinicTabDoneFooter:boolean=true;

  isBackDisable:boolean = true;
  openInfoPopup() {
   
    this.isInfo = true;
    this.ispayment =false;
    this.isinsurance=false;
    this.isabandoned=false;
    this.isshowfooter=true;
    this.addstatus=true;
    if(this.isDesktopDevice || this.isTablet){
      this.patientInfoOpened = true;
    }
    if(this.isMobile == true){
      this.router.navigate(['/app/patient-info-popup']);
    }
  }
  openInsurancePopup(){
    this.patientInfoOpened = true;
    this.isinsurance=true;
    this.isInfo = false;
    this.ispayment=false;
    this.isabandoned=false;
    this.isshowfooter=true;
    this.addstatus=true;
  } 
  openPaymentPopup(){
    this.patientInfoOpened = true;
    this.isInfo = false;
    this.isinsurance=false;
    this.isabandoned=false;
    this.ispayment =true;
    this.isshowfooter=false;
    this.addstatus=false;
  }
  openAbandonedPopup(){
    this.patientInfoOpened = true;
    this.isinsurance=false;
    this.isInfo = false;
    this.ispayment=false;
    this.isabandoned=true;
    this.isshowfooter=false;
  }
  onclose() {
    this.patientInfoOpened = false;
  }
  onClose() {
    this.bsModalRef.hide();
  }
  //Btn Show
  mobilebtnshow(){
    var mobileshow = document.getElementById('mobileBtn');
    if(mobileshow != null){
      mobileshow.classList.add('showMobileBtn')
    }
  }
  mobilebtnshowpay(){
    var mobileshow = document.getElementById('mobileBtn2');
    if(mobileshow != null){
      mobileshow.classList.add('showMobileBtn')
    }
  }
  // public showModal(data: any): void {
  //   this.bsModalRef = this.modalService.show(PreRegistrationTimeoutWarningComponent, {
  //     animated: true,
  //     backdrop: 'static',
  //     class: 'modal-sm'
  //   });
  // }
  // public showModal2(data:any):void{
  //   this.bsModalRef = this.modalService.show(PreRegistrationAbandonedComponent, {
  //     animated: true,
  //     backdrop: 'static',
  //     class: 'modal-sm'
  //   });
  // }

  showFillFormSuccess(){
    this.isFormFillOut = true;
    this.showPatientForm();
  }

  hideFillFormSuccess(){
    this.isFormFillOut = false;
    this.showPatientForm();
  }
  showPaymentBtn(){
    this.status3 = false;
    this.status4 = true;
    this.isFormFillOut = true;
    this.isFormFillOutBtn = false;
    this.mobilebtnshowpay();
    
  }

  showPatientForm(){
    this.isPatientFormOpened = true;
  }

  hidePatientForm(){
    this.isPatientFormOpened = false;
  }
  hideformpopup(e:any){
    this.hidePatientForm()
  }

  isInputFocus(){
    const d = document.querySelector('.patientInfo_popup .k-dialog') as HTMLInputElement;
    if(d != null){
      d.style.transition = 'all .2s ease-in-out'
      d.style.transform = "translateY(-100px)"
    } 
  }
  isInputNotFocus(){
    const d = document.querySelector('.patientInfo_popup .k-dialog') as HTMLInputElement;
    if(d != null){
      d.style.transition = 'all .2s ease-in-out'
      d.style.transform = "translateY(0px)"
    } 
  }
  onClosepayment() {
    this.bsModalRef.hide();
  }

  showpaymentbtn(){
    this.isshowpaymentbtn=true;
  }

  isPatientCardOpened:boolean = false;
  openPatientCard() {
    this.isPatientCardOpened = true;
  }
  closePatientCard(e: any){
    if(e == false){
      this.isPatientCardOpened = false;
    }
  }
  public accountList: Array<string> = [
    "Account number 1",
    "Account number 2"
  ];
  isDeleteInsuranceOpened: boolean = false;
  openDeleteInsurance(){
    this.isDeleteInsuranceOpened = true;
  }
  closeDeleteInsurance(){
    this.isDeleteInsuranceOpened = false;
  }
}
