import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { StepType } from '@progress/kendo-angular-layout';
import { AdaptiveMode } from "@progress/kendo-angular-dateinputs";

@Component({
  selector: 'pre-registration-patient-form',
  templateUrl: './pre-registration-patient-form.component.html',
  styleUrls: ['./pre-registration-patient-form.component.scss'],
})
export class PreRegistrationPatientFormComponent  implements OnInit {

  @Output() hideformpopup: EventEmitter<boolean> = new EventEmitter();

  isTextbox:boolean = false;
  isBold:boolean = false;
  isDigitalSign:boolean = true;
  isCompleted:boolean = false;
  count:string = "3";


  isPatientHealthHistory:boolean = true;
  isFamilyHealthHistory:boolean = false;
  isConsentForm:boolean = false;
  isBillingForm:boolean = false;
  isFormSummary:boolean = false;

  isFormDone:boolean = false;

  isBackDisable:boolean = true;

  isDesktop: boolean;

  MobilePatientformTitle:string = "Patient Health History";
  
  breakpoint: any = window.matchMedia('(min-width: 767px)');
  constructor( ) {
    this.isDesktop = !!this.breakpoint.matches;
  }

  ngOnInit() {}

  public stepType: StepType = "indicator";
  public current = 0;

  public steps = [
    { label: "First step", isValid: true },
    { label: "Second step", isValid: true },
    { label: "Third step", isValid: true,},
    { label: "Fourth step", isValid: true },
  ];


  showTextbox(e:any){
    if(e.target.checked){
      this.isTextbox = true;
      this.isBold = true;
    }else{
      this.isTextbox = false;
      this.isBold = false;
    }
  }
  showBoldText(e:any){
    if(e.target.checked){
      this.isBold = true;
      this.isDigitalSign = false;
    }else{
      this.isBold = false;
      this.isDigitalSign = true;
    }
  }
  toggleComplete(){
    this.isCompleted = !this.isCompleted
    if(this.isCompleted == true){
      this.count = "4"
    }else{
      this.count = "3"
    }
  }
  public prev(): void {

    if (this.current > 0) {
      this.current -= 1;  
       this.isPatientHealthHistory = true;
        this.isFamilyHealthHistory = false;
        this.isConsentForm = false;
        this.isBillingForm = false;
        this.isFormSummary = false;
        this.isFormDone = false;
        this.isBackDisable = true;
        this.MobilePatientformTitle = "Patient Health History";
      if(this.current == 1){
        this.isPatientHealthHistory = false;
        this.isFamilyHealthHistory = true;
        this.isConsentForm = false;
        this.isBillingForm = false;
        this.isFormSummary = false;
        this.isFormDone = false;
        this.isBackDisable = false;
        this.MobilePatientformTitle = "Family Medical History";
      }else{
        if(this.current == 2){
          this.isPatientHealthHistory = false;
          this.isFamilyHealthHistory = false;
          this.isConsentForm = true;
          this.isBillingForm = false;
          this.isFormSummary = false;
          this.isFormDone = false;
          this.isBackDisable = false;
          this.MobilePatientformTitle = "Consent Form";
        }else{
          if(this.current == 3){
            this.isPatientHealthHistory = false;
            this.isFamilyHealthHistory = false;
            this.isConsentForm = false;
            this.isBillingForm = true;
            this.isFormSummary = false;
            this.isFormDone = false;
            this.isBackDisable = false;
            this.MobilePatientformTitle = "Billing Form";
          }else{
            if(this.current == 4){
              this.isPatientHealthHistory = false;
              this.isFamilyHealthHistory = false;
              this.isConsentForm = false;
              this.isBillingForm = false;
              this.isFormSummary = true;
              this.isFormDone = true;
              this.MobilePatientformTitle = "Forms Summary";
            }
          }
        }
      }
    }
  }

  public next(): void {
    
    if (this.current < this.steps.length) {
      this.current += 1;
      if(this.current == 1){
        this.isPatientHealthHistory = false;
        this.isFamilyHealthHistory = true;
        this.isConsentForm = false;
        this.isBillingForm = false;
        this.isFormSummary = false;
        this.isFormDone = false;
        this.isBackDisable = false;
        this.MobilePatientformTitle = "Family Medical History";
      }else{
        if(this.current == 2){
          this.isPatientHealthHistory = false;
          this.isFamilyHealthHistory = false;
          this.isConsentForm = true;
          this.isBillingForm = false;
          this.isFormSummary = false;
          this.isFormDone = false;
          this.isBackDisable = false;
          this.MobilePatientformTitle = "Consent Form";
        }else{
          if(this.current == 3){
            this.isPatientHealthHistory = false;
            this.isFamilyHealthHistory = false;
            this.isConsentForm = false;
            this.isBillingForm = true;
            this.isFormSummary = false;
            this.isFormDone = false;
            this.isBackDisable = false;
            this.MobilePatientformTitle = "Billing Form";
          }else{
            if(this.current == 4){
              this.isPatientHealthHistory = false;
              this.isFamilyHealthHistory = false;
              this.isConsentForm = false;
              this.isBillingForm = false;
              this.isFormSummary = true;
              this.isFormDone = true;
              this.isBackDisable = false;
              this.MobilePatientformTitle = "Forms Summary";
            }
          }
        }
      }
    }
  }

  hideFormPopup(){
    this.hideformpopup.emit(true);
  }
  public optionList: Array<string> = [
    "Yes",
    "No"
  ];
  public value: Date = new Date();
  public adaptiveMode: AdaptiveMode = "none";

  onScroll(){
    const x = document.getElementById('formcontainer') as HTMLElement;
    const title = document.getElementById('title') as HTMLElement;
    x.addEventListener('scroll', function(){
      const s = window.scrollY;
      console.log(s);
      // if(title.classList.contains('boxShadowTop')){
      //   title.classList.remove('boxShadowTop');
      // }else{
      //   title.classList.add('boxShadowTop');
      // }
      title.classList.add('boxShadowTop');
      
    })
  }
  ngAfterViewInit(): void{
    this.onScroll()
  }
}
