import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PopupModule } from '@progress/kendo-angular-popup';
import { GridModule } from '@progress/kendo-angular-grid';
import { DateInputsModule, DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownListModule, ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { CalendarModule } from '@progress/kendo-angular-dateinputs';
import { DateInputModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";


//for kendo stepper


import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { IconsModule } from "@progress/kendo-angular-icons";
import { LayoutModule } from '@progress/kendo-angular-layout';


@NgModule({
  declarations: [],
  exports: [
    PopupModule,
    GridModule,
    DatePickerModule,
    ButtonsModule,
    UploadsModule,
    DropDownsModule,
    LabelModule, InputsModule, DropDownListModule, DateInputsModule, CalendarModule, ComboBoxModule, DateInputModule, DialogsModule,
   LayoutModule,
   IconsModule,
   FormsModule,
],
  imports: [
  ]
})
export class KendoModule { }
