import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginService } from '../service/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public userloggedin: boolean = false;
  breakpoint: any = window.matchMedia('(min-width: 991px)');
  isDesktop: boolean;

  constructor(
    private router: Router,
    private loginService: LoginService,

  ) {
    this.isDesktop = !!this.breakpoint.matches;

  }

  ngOnInit() {

  }



  async onSubmit(loginForm: any) {
    if (loginForm.valid == true) {
      let un = loginForm.value.username;
      let pw = loginForm.value.password;
      this.loginService
        .doLogin({
          username: un,
          password: pw,
        })
        .then(
          (token ) => {
            if(token){
              sessionStorage.setItem('isLoggedIn', 'true');
              this.router.navigate(['/mobile']);
            }
          },
          (err) => {
            console.log('Error Code: ' + err.code);
          }
        );
    }
  }
}
