import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoginService } from 'src/app/alt-views/service/login.service';

@Component({
  selector: 'app-clinic-lock-screen',
  templateUrl: './clinic-lock-screen.component.html',
  styleUrls: ['./clinic-lock-screen.component.scss'],
})
export class ClinicLockScreenComponent  implements OnInit {
  @ViewChild('passwordInput')
  private passwordInput!: TextBoxComponent;
  isDesktop!: boolean;
  breakpoint: any = window.matchMedia('(min-width: 680px)');
  deviceInfo: import("ngx-device-detector").DeviceInfo | undefined;

  isIncorrect:boolean = true;
  isResetPasswordForm:boolean = true;
  isResetPassword:boolean = true;
  isForgetLink:boolean = true;
  public maxlength = 1;

  isPinError:boolean = false;
  isResetBtn:boolean = true;

  loginForm!: FormGroup<any>;
 isMobile!: boolean;
 isTablet!: boolean;
 isDesktopDevice!: boolean;

  constructor(private fb: FormBuilder,
    private router: Router,private _loginService: LoginService, private deviceService: DeviceDetectorService) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    })

    this.isDesktop = !!this.breakpoint.matches;
    this.epicFunction();
   }

  ngOnInit() {
  }

  public ngAfterViewInit() {
    this.passwordInput.input.nativeElement.type = 'password';
  }

    epicFunction() {
      console.log('hello `Home` component');
      this.deviceInfo = this.deviceService.getDeviceInfo();
      this.isMobile = this.deviceService.isMobile();
      this.isTablet = this.deviceService.isTablet();
      this.isDesktopDevice = this.deviceService.isDesktop();
      console.log(this.deviceInfo);
      console.log(this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
      console.log(this.isTablet);  // returns if the device us a tablet (iPad etc)
      console.log(this.isDesktopDevice); // returns if the app is running on a Desktop browser.
    }

  showIncorrectPin(){
    this.isIncorrect = false;
  }
  showForgetPass(){
    this.isResetPassword = false;
  }
  showBacktoLogin(){
    this.isResetPasswordForm = false;
  }
  showForgetLink(){
    this.isForgetLink = false;
  }
  backtoLogin(){
    this.isResetPassword = true;
  }

  async onSubmit() {

      let username = this.loginForm.value.username;
      let password = this.loginForm.value.password;
      this._loginService.doLogin({ username: username, password: password }).then(
        (token ) => {
          if(token){
            sessionStorage.setItem('isClinicLoggedIn','true');
            this.router.navigateByUrl('/practice-list');
          }
        },
        (err) => {
          // console.log('Error Code: ' + err.code);

        }
      );
  }
  showPinError(){
    this.isPinError = true;
  }
  showResetBtn(){
    this.isResetBtn = false;
  }
}
