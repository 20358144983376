import { Component, OnInit } from '@angular/core';
import { StepType } from '@progress/kendo-angular-layout';

@Component({
  selector: 'pre-registration-welcome',
  templateUrl: './pre-registration-welcome.component.html',
  styleUrls: ['./pre-registration-welcome.component.scss'],
})
export class PreRegistrationWelcomeComponent  implements OnInit {
  public stepType: StepType = "indicator";
  public stepTypes: Array<StepType> = ["indicator", "label", "full"];
  public current = 0;
  isshowcontinue:boolean=false;
  ishidecontinue:boolean=true;
  isshownote:boolean=false;
  ishidetext:boolean=true;
  isDesktop: boolean;
  breakpoint: any = window.matchMedia('(min-width: 767px)');
  constructor() {
    this.isDesktop = !!this.breakpoint.matches;
   }

  ngOnInit() {
  }


  public steps = [
    { label: "Confirm your patient information", disabled:true,  isValid: true, text:"1",},
    { label: "Update your insurance", disabled:true, isValid: false, text:"2", },
    { label: "Fill out forms", disabled:true, isValid: false, text:"3", },
    { label: "Make a payment", disabled:true, isValid: true, text:"4", },
  ];

  public prev(): void {
    if (this.current > 0) {
      this.current -= 1;
    }
  }

  public next(): void {
    if (this.current < this.steps.length - 1) {
      this.current += 1;
    }
  }
  
  showcontinue(){
   this.isshowcontinue=true;
   this.ishidecontinue=false;
  }
  shownote(){
    this.isshownote=true;
    this.isshowcontinue=false;
    this.ishidetext=false;
  }

}
