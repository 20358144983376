import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-patient-insurance-view-popup',
  templateUrl: './patient-insurance-view-popup.component.html',
  styleUrls: ['./patient-insurance-view-popup.component.scss'],
})
export class PatientInsuranceViewPopupComponent  implements OnInit {
  @Output() isPatientCardClose: EventEmitter<any> = new EventEmitter();
  constructor(private deviceService: DeviceDetectorService) { }

  isMobile!: boolean;
  isTablet!: boolean;
  isDesktopDevice!: boolean;
  deviceInfo: import("ngx-device-detector").DeviceInfo | undefined;

  ngOnInit() {
    this.epicFunction();
  }

  epicFunction() {
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    console.log("Mobile : " +this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log("Tablet : " +this.isTablet);  // returns if the device us a tablet (iPad etc)
    console.log("Desktop : " +this.isDesktopDevice); // returns if the app is running on a Desktop browser.
  }


  isopenReplaceinsurancePopup:boolean=false
  openReplaceinsurancePopup() {
   this.isopenReplaceinsurancePopup=true;
  }
  onclose() {
    this.isopenReplaceinsurancePopup = false;
  }

  closePatientCard(){
    this.isPatientCardClose.emit(false);
  }

}
