import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pre-registration-view-insurance-card',
  templateUrl: './pre-registration-view-insurance-card.component.html',
  styleUrls: ['./pre-registration-view-insurance-card.component.scss'],
})
export class PreRegistrationViewInsuranceCardComponent  implements OnInit {
  @Output() isPatientCardClose: EventEmitter<any> = new EventEmitter();

  isDesktop: boolean;
  breakpoint: any = window.matchMedia('(min-width: 767px)');

  constructor() {
    this.isDesktop = !!this.breakpoint.matches;
   }

  ngOnInit() {}

  isopenReplaceinsurancePopup:boolean=false
  openReplaceinsurancePopup() {
   this.isopenReplaceinsurancePopup=true;
  }
  onclose() {
    this.isopenReplaceinsurancePopup = false;
  }

  closePatientCard(){
    this.isPatientCardClose.emit(false);
  }
}
