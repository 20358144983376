import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logout-messages',
  templateUrl: './logout-messages.component.html',
  styleUrls: ['./logout-messages.component.scss'],
})
export class LogoutMessagesComponent  implements OnInit {

  
  constructor() { }
  public opened = false;

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
  ngOnInit() {}
  editInfoOpened: boolean = false;
  isInfo: boolean = false;
    openInfoPopup() {
      this.editInfoOpened = true;
      this.isInfo = true;
    }
  
}
