<div scroll_main>
  <div *ngIf="isMobile">
    <self-scheduling-header></self-scheduling-header>
  </div>
  <!-- <div class="main-div pb-20">
    <form class="k-form pr-40 pl-40 pt-20">
      <div class="column px-30 pt-20 patientInfoMobileHeading" *ngIf="isMobile">
        <ion-text class="bodyL-txt-large text-blue-color ion-align-items-center d-flex">
          <span class="bodyXS-txt-bold text-blue-color stepper-circle mr-10">1</span>
          Patient information
        </ion-text>
      </div>
      <div class="mb-25 pl-20">
        <div class="d-flex py-9">
          <li class="d-flex align-items-center mr-40">
            <input type="radio" name="Policy holder" value="Self" #Self kendoRadioButton />
            <kendo-label [for]="Self" text="Insurance" class="ml-5 bodyM-txt ion-color-gray80" color="gray80"></kendo-label>
          </li>
          <li class="d-flex align-items-center mr-40">
            <input type="radio" name="Policy holder" value="Someone Else" #SomeoneElse kendoRadioButton />
            <kendo-label [for]="SomeoneElse" text="Self Pay" class="ml-5 bodyM-txt ion-color-gray80"
              color="gray80"></kendo-label>
          </li>
        </div>
      </div>
  
      <div class="update-info">
        <div class="justify-content-sb w-100 d-flex mb-25">
          <ion-text class="bodyS-txt-large" gray="100">
            Primary Insurance
          </ion-text>
          <ion-text class="bodyS-txt text-HLblue-color">
            Remove
          </ion-text>
        </div>
        <div class="mb-15">
          <kendo-combobox placeholder="*Insurance Carrier" [suggest]="true" class="bodyM-txt">
          </kendo-combobox>
        </div>
        <div class="row-new mb-20">
          <div class="col-6-sm col-12-xs mt-10">
            <div  [ngClass] ="isMobile == true? '' : 'mr-10' ">
              <label class="k-form" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">Member Number</label>
              <kendo-textbox class="bodyM-txt txt-field ion-color-gray100" color="gray100" placeholder="*Member Number">
              </kendo-textbox>
            </div>
          </div>
        
          <div class="col-6-sm col-12-xs mt-10">
            <div  [ngClass] ="isMobile == true? '' : 'ml-10' ">
              <label class="k-form" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">Group Number</label>
              <kendo-textbox class="bodyM-txt txt-field ion-color-gray100" color="gray100" placeholder="*Group Number">
              </kendo-textbox>
            </div>
          </div>
  
        </div>
  
  
        <div class="mb-35">
          <label class="" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">Policy holder</label>
          <div class="d-flex py-9">
            <li class="d-flex align-items-center mr-40">
              <input type="radio" name="Policy holder" value="Self" #Self kendoRadioButton />
              <kendo-label [for]="Self" text="Self" class="ml-5 bodyM-txt ion-color-gray80" color="gray80"></kendo-label>
            </li>
            <li class="d-flex align-items-center mr-40">
              <input type="radio" name="Policy holder" value="Someone Else" #SomeoneElse kendoRadioButton />
              <kendo-label [for]="SomeoneElse" text="Someone Else" class="ml-5 bodyM-txt ion-color-gray80"
                color="gray80"></kendo-label>
            </li>
          </div>
        </div>
  
        <div>
          <ion-text class="bodyS-txt text-HLblue-color pointer" *ngIf="isDesktopDevice || isTablet">
            View/Update insurance card photo
          </ion-text>
          <ion-text class="bodyS-txt text-HLblue-color pointer" *ngIf="isMobile" routerLink="/app/view-insurance-card">
            View/Update insurance card photo
          </ion-text>
        </div>
  
      </div>
    </form>
  </div> -->
  
  
  <div class="main-div" scroll_body>
    <form class="k-form " [formGroup]="registerForm">
      <div class="p-20">
        <div class="column pb-20 patientInfoMobileHeading" *ngIf="isMobile">
          <ion-text class="bodyL-txt-large text-blue-color ion-align-items-center d-flex">
            <span class="bodyXS-txt-bold text-blue-color stepper-circle mr-10">2</span>
            Insurance <span class="bodyXS-txt-italic text-gray80-color pl-8">Optional</span>
          </ion-text>
        </div>
        <div class="mb-20" [ngClass]="isMobile == true? 'pl-10' : 'pl-20' ">
          <div class="d-flex py-9">
            <li class="d-flex align-items-center mr-40">
              <input type="radio" name="Policy holder" value="Self" #Self kendoRadioButton />
              <kendo-label [for]="Self" text="Insurance" class="ml-5 bodyM-txt ion-color-gray80"
                color="gray80"></kendo-label>
            </li>
            <li class="d-flex align-items-center mr-40">
              <input type="radio" name="Policy holder" value="Someone Else" #SomeoneElse kendoRadioButton />
              <kendo-label [for]="SomeoneElse" text="Self Pay" class="ml-5 bodyM-txt ion-color-gray80"
                color="gray80"></kendo-label>
            </li>
          </div>
        </div>
        <!-- <div class="row-new">
          <ion-text class="bodyL-txt bodyM-txt-mobile pl-10">Payment will be due at visit</ion-text>
        </div> -->
        <div class="update-info">
          <!-- <div class="justify-content-sb w-100 d-flex mb-30"> -->
  
          <div class="row-new mb-30 primary-insurance">
            <div class="col-6-sm">
              <ion-text class="bodyS-txt-large" gray="100">
                Primary Insurance
              </ion-text>
            </div>
            <div class="col-6-sm text-align-right reomove-btn">
              <ion-text class="bodyS-txt text-HLblue-color ">
                Remove
              </ion-text>
            </div>
          </div>
          <div class="mb-15">
            <kendo-combobox placeholder="Name of insurer" [suggest]="true" class="bodyM-txt" [data]="Name">
            </kendo-combobox>
          </div>
          <div class="mb-15 d-flex mobile-direction-column">
            <div class="w-50 mobile-100 mb-35">
              <label class="mb-3" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">Member
                number</label>
              <kendo-textbox class="bodyM-txt  ion-color-gray100" color="gray100"
                placeholder="Member number"></kendo-textbox>
            </div>
            <div class="mx-15"></div>
            <div class="w-50 mobile-100">
              <label class="mb-3" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">Group
                number</label>
              <kendo-textbox class="bodyM-txt ion-color-gray100 " color="gray100"
                placeholder="Group number"></kendo-textbox>
            </div>
  
          </div>
  
  
          <div class="mb-35">
            <label class="" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">Policy holder</label>
            <div class="d-flex py-9">
              <li class="d-flex align-items-center mr-40">
                <input type="radio" name="Policy holder" value="Self" #Self kendoRadioButton />
                <kendo-label [for]="Self" text="Self" class="ml-5 bodyM-txt ion-color-gray80"
                  color="gray80"></kendo-label>
              </li>
              <li class="d-flex align-items-center mr-40">
                <input type="radio" name="Policy holder" value="Someone Else" #SomeoneElse kendoRadioButton
                  (click)="someoneholder()" />
                <kendo-label [for]="SomeoneElse" text="Someone Else" class="ml-5 bodyM-txt ion-color-gray80"
                  color="gray80"></kendo-label>
              </li>
              <div class="w-50 someoneselect" *ngIf="issomeoneholder">
                <kendo-combobox placeholder="*Subscriber relationship" [suggest]="true" class="bodyM-txt">
                </kendo-combobox>
              </div>
            </div>
          </div>
          <div class="d-none" id="primarySomeOne">
            <div class="mb-35">
              <label class="mb-3" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">Subscriber
                Name</label>
              <div class="d-flex">
                <div class="mr-10">
                  <kendo-textbox class="bodyM-txt  ion-color-gray100" color="gray100" placeholder="*Last Name">
                  </kendo-textbox>
                </div>
                <div class="mr-10">
                  <kendo-textbox class="bodyM-txt  ion-color-gray100" color="gray100" placeholder="Middle Name">
                  </kendo-textbox>
                </div>
                <div>
                  <kendo-textbox class="bodyM-txt  ion-color-gray100" color="gray100" placeholder="*First Name">
                  </kendo-textbox>
                </div>
              </div>
            </div>
            <div class="d-flex mb-35 ">
              <div class="w-50 mr-10">
                <label class="mb-3" class="bodyS-txt ion-text-uppercase" color="gray80">*Date of birth</label>
                <input class="bodyM-txt dobInputBox" [formControl]="dateFC" [inputMask]="dateInputMask"
                  placeholder="MM/DD/YYYY" />
  
  
              </div>
  
              <div class="w-50">
                <kendo-formfield>
                  <label class="mb-3" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">*Gender</label>
                  <kendo-combobox [data]="Genders" placeholder="Select Gender" [suggest]="true" class="bodyM-txt"
                    [value]="selectedGender">
                  </kendo-combobox>
                </kendo-formfield>
              </div>
            </div>
          </div>
          <div>
            <ion-text class="bodyS-txt text-HLblue-color pointer" *ngIf="isDesktopDevice || isTablet" (click)="viewcard()">
              View/Update insurance card photo
            </ion-text>
            <ion-text class="bodyS-txt text-HLblue-color pointer" *ngIf="isMobile"
              routerLink="/app/patient-insurance-view-popup">
              View/Update insurance card photo
            </ion-text>
  
            <!-- <div class="insurance-card-img d-flex mb-20">
              <div class="mr-20">
                <ion-img class="img-card" src="../../../../../assets/svg/Insurance card.svg"></ion-img>
              </div>
              <div class="">
                <ion-img class="img-card" src="../../../../../assets/svg/Insurance card_2.svg"></ion-img>
              </div>
            </div> -->
          </div>
          <div class="insurancecard w-50 column mt-20 pointer" *ngIf="isviewcard" (click)="openViewinsurancePopup()">
            <ion-text class="bodyS-txt text-HLblue-color mb-15">
              Add insurance card photo
            </ion-text>
            <div class="d-flex justify-content-sb iconcard">
              <ion-icon class="text-HLblue-color h1" src="../../../../assets/svg/camera.svg"></ion-icon>
              <ion-icon class="text-HLblue-color h1" src="../../../../assets/svg/upload.svg"></ion-icon>
            </div>
          </div>
        </div>
      </div>
      <hr class="seperator">
      <div *ngIf="ismorecontact">
        <div class="update-info m-20">
          <div class="justify-content-sb w-100 d-flex mb-30">
            <ion-text class="bodyS-txt-large" gray="100">
              Secondary Insurance
            </ion-text>
            <ion-text class="bodyS-txt text-HLblue-color" (click)="removemorecontact()">
              Remove
            </ion-text>
          </div>
          <div class="mb-35">
            <kendo-combobox placeholder="Name of insurer" [suggest]="true" class="bodyM-txt" [data]="Name">
            </kendo-combobox>
          </div>
  
          <div class="mb-35 d-flex mobile-direction-column">
            <div class="w-50 mobile-100 mb-35">
              <label class="mb-3" class="bodyS-txt ion-text-uppercase" color="gray80">Member number</label>
              <kendo-textbox class="bodyM-txt ion-color-gray100" color="gray100"></kendo-textbox>
            </div>
            <div class="mx-15"></div>
            <div class="w-50 mobile-100">
              <label class="mb-3" class="bodyS-txt ion-text-uppercase" color="gray80">Group number</label>
              <kendo-textbox class="bodyM-txt ion-color-gray100 " color="gray100"></kendo-textbox>
            </div>
          </div>
          <div class="mb-35">
            <label class="mb-10" class="bodyS-txt ion-text-uppercase" color="gray80">Policy holder</label>
            <div class="d-flex mt-8">
              <li class="d-flex align-items-center mr-40">
                <input type="radio" name="Policy holder" value="Self" #Self kendoRadioButton />
                <kendo-label [for]="Self" text="Self" class="ml-5 bodyM-txt" color="gray80"></kendo-label>
              </li>
              <li class="d-flex align-items-center mr-40">
                <input type="radio" name="Policy holder" value="Someone Else" #SomeoneElse kendoRadioButton
                  (click)="someoneholder()" />
                <kendo-label [for]="SomeoneElse" text="Someone Else" class="ml-5 bodyM-txt ion-color-gray80"
                  color="gray80"></kendo-label>
              </li>
              <div class="w-50 someoneselect" *ngIf="issomeoneholder">
                <kendo-combobox placeholder="" [suggest]="true" class="bodyM-txt">
                </kendo-combobox>
              </div>
            </div>
          </div>
          <div class="ml-30" *ngIf="issomeoneholder">
            <div class="mb-35">
              <label class="mb-3" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">Subscriber
                Name</label>
              <div class="d-flex">
                <div class="mr-10">
                  <kendo-textbox class="bodyM-txt  ion-color-gray100" color="gray100" placeholder="Last Name">
                  </kendo-textbox>
                </div>
                <div class="mr-10">
                  <kendo-textbox class="bodyM-txt  ion-color-gray100" color="gray100" placeholder="Middle Name">
                  </kendo-textbox>
                </div>
                <div>
                  <kendo-textbox class="bodyM-txt  ion-color-gray100" color="gray100" placeholder="First Name">
                  </kendo-textbox>
                </div>
              </div>
            </div>
            <div class="mb-35">
              <label class="mb-3" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">Date of
                birth</label>
              <kendo-textbox class="bodyM-txt ion-color-gray100" color="gray100"></kendo-textbox>
            </div>
  
          </div>
          <div>
            <ion-text class="bodyS-txt text-HLblue-color pointer" *ngIf="isDesktopDevice || isTablet" (click)="viewcard()">
              View/Update insurance card photo
            </ion-text>
            <ion-text class="bodyS-txt text-HLblue-color pointer" *ngIf="isMobile"
              routerLink="/app/view-insurance-card">
              View/Update insurance card photo
            </ion-text>
          </div>
          <div class="insurancecard w-50 column mt-20 pointer" *ngIf="isviewcard" (click)="openViewinsurancePopup()">
            <ion-text class="bodyS-txt text-HLblue-color mb-15">
              Add insurance card photo
            </ion-text>
            <div class="d-flex justify-content-sb iconcard">
              <ion-icon class="text-HLblue-color h1" src="../../../../assets/svg/camera.svg"></ion-icon>
              <ion-icon class="text-HLblue-color h1" src="../../../../assets/svg/upload.svg"></ion-icon>
            </div>
          </div>
        </div>
        <hr class="seperator">
      </div>
      <div class="px-20 pb-20 d-flex align-items-center pointer" (click)="showmore()">
        <ion-button slot="only-icon" shape="round" fill="clear" class="ion-no-padding round-icon-button">
          <ion-icon class="header-icon simple-icon ion-color-highlight" color="highlight"
            src="../../../../assets/svg/plus.svg"></ion-icon>
        </ion-button>
        <ion-text class="bodyS-txt text-HLblue-color">
          Add another insurance policy
        </ion-text>
      </div>
  
    </form>
  </div>
  <ion-footer *ngIf="isMobile" style="border-top: 1px solid #ccd4d8;">
    <div class="px-30 py-10 row w-100 ion-justify-content-center align-items-center ion-bg-unfocused-color">
      <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn mr-15"
        routerLink="/app/patient-scheduling">
        <ion-text>Cancel</ion-text>
      </ion-button>
      <ion-button fill="solid" expand="block" color="ion-color-white" class="filled-icon-btn capitalize info-btn"
        routerLink="/app/patient-scheduling">
        <ion-text>Save</ion-text>
      </ion-button>
    </div>
  </ion-footer>
  </div>
  <div class="kendopopup editInfo_popup">
    <kendo-dialog *ngIf="isopenViewinsurancePopup">
      <kendo-dialog-titlebar class="text-center">
        <div class="ion-align-items-center w-100">
          <ion-text class="h3-M tabletFont mb-30" color="gray100">Your insurance card photo</ion-text>
        </div>
      </kendo-dialog-titlebar>
      <app-patient-insurance-view-popup></app-patient-insurance-view-popup>
      <kendo-dialog-actions *ngIf="isDesktopDevice || isTablet">
        <div class="py-10 row w-100 ion-justify-content-center align-items-center">
          <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn mr-20"
            (click)="onclose()">
            <ion-text>Cancel</ion-text>
          </ion-button>
          <ion-button fill="solid" expand="block" color="ion-color-white" class="filled-icon-btn capitalize info-btn">
            <ion-text>Done</ion-text>
          </ion-button>
        </div>
      </kendo-dialog-actions>
    </kendo-dialog>
  </div>
  
  <!-- <ion-footer *ngIf="isMobile">
    <div class="px-30 py-10 row w-100 ion-justify-content-center align-items-center ion-bg-unfocused-color">
      <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn mr-15"
        routerLink="/app/patient-scheduling">
        <ion-text>Cancel</ion-text>
      </ion-button>
      <ion-button fill="solid" expand="block" color="ion-color-white" class="filled-icon-btn capitalize info-btn">
        <ion-text>Save</ion-text>
      </ion-button>
    </div>
  </ion-footer> -->