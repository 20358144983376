import { Component, OnInit, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { Router  } from '@angular/router';


@Component({
  selector: 'app-patient-appointment',
  templateUrl: './patient-appointment.component.html',
  styleUrls: ['./patient-appointment.component.scss'],
})
export class PatientAppointmentComponent  implements OnInit {
  isMobile!: boolean;
  isTablet!: boolean;
  isDesktopDevice!: boolean;
  deviceInfo: import("ngx-device-detector").DeviceInfo | undefined;

  @ViewChild('passwordInput')
  private passwordInput!: TextBoxComponent;

  isTabletDevice: boolean;

  breakpoint2: any = window.matchMedia('(min-width: 768px) and (max-width: 1024px)');// tablet

  constructor(private deviceService: DeviceDetectorService, private router: Router) { 
    this.isTabletDevice = !!this.breakpoint2.matches;
  }

  ngOnInit() {
    this.epicFunction();
  }
  public ngAfterViewInit() {
    this.passwordInput.input.nativeElement.type = 'password';
  }
  //Device Dectector
  epicFunction() {
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    console.log("Mobile" +this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log("Tablet" +this.isTablet);  // returns if the device us a tablet (iPad etc)
    console.log("Desktop" +this.isDesktopDevice); // returns if the app is running on a Desktop browser.
  }

  //patientList
  public selectedPatient = "New Patient";
  public patientList : Array<string> = [
      "New Patient",
      "New Patient", 
      "New Patient"
   ];
  public locationList : Array<string> = [
      "Location 1",
      "Location 2", 
      "Location 3"
   ];
  public providerList : Array<string> = [
    "Provider 1",
    "Provider 2", 
    "Provider 3"
  ];
  public reasonList : Array<string> = [
    "Reason 1",
    "Reason 2", 
    "Reason 3"
  ];

}
