<div class="book_appointment_main scroll_main">
  <div>
    <self-scheduling-header></self-scheduling-header>
  </div>
  <div class="book_appointment_container container scroll_body">
    <div>
      <div class="confirm-info d-flex align-items-center justify-content-center">
        <div class="green-circle">
          <span class="circle">
            <ion-icon name="checkmark-outline" class="ion-color-white" color=""></ion-icon>
          </span>
        </div>
        <ion-text class="h3-success text-green-color">Your appointment is booked</ion-text>
      </div>
    </div>
    <div class="book_appointment_details">
      <div class="d-flex mb-25">
        <div class="" [ngClass]="isMobile == true ? 'mr-20' : 'mr-25 p-10 pt-5' ">
          <ion-icon class="bodyXXL-txt" src="../../../../../assets/svg/add-calender.svg" color="gray80"></ion-icon>
        </div>
        <div class="column">
          <ion-text class="bodyL-txt" [ngClass]="isMobile == true ? 'bodyM-txt' : 'bodyL-txt' " color="gray100">Wednesday, Mar 13, 2024</ion-text>
          <div>
            <ion-text class="" [ngClass]="isMobile == true ? 'h1' : 'booked_text_lg' "  color="gray100">9:15 </ion-text><ion-text class="h3" color="gray100"> AM </ion-text>
          </div>
          <ion-text class="" [ngClass]="isMobile == true ? 'bodyS-txt' : 'bodyL-txt' " color="gray80">(arrive at 9:00 AM)</ion-text>
        </div>
      </div>
      <div class="d-flex mb-25">
        <div class="" [ngClass]="isMobile == true ? 'mr-20' : 'mr-25 p-10 pt-5' ">
          <ion-icon class="bodyXXL-txt" src="../../../../../assets/svg/open-maps.svg" color="gray80"></ion-icon>
        </div>
        <div class="column">
          <ion-text class="mb-5" [ngClass]="isMobile == true ? 'bodyM-txt-bold' : 'bodyXL-txt-bold ' " color="gray100">Dr. John Smithsonian</ion-text>
          <div class="column maxWidth170">
            <ion-text class="" [ngClass]="isMobile == true ? 'bodyM-txt' : 'bodyL-txt' " color="gray100"> Downtown Clinic 5275 S Main Street Seattle, WA 85471</ion-text>
            <ion-text class="" [ngClass]="isMobile == true ? 'bodyM-txt' : 'bodyL-txt' " color="gray100"> (734) 455-999</ion-text>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div class="" [ngClass]="isMobile == true ? 'mr-20' : 'mr-25 p-10 pt-5' ">
          <ion-icon class="bodyXXL-txt" src="../../../../../assets/svg/message-chat-medical-cross_1.svg" color="gray80"></ion-icon>
        </div>
        <div class="column">
          <ion-text class=" mb-10" [ngClass]="isMobile == true ? 'bodyM-txt-italic' : 'bodyL-txt-italic' "  color="gray100">We ask all of our patients to arrive 15 minutes before their appointment to ensure timely service.  </ion-text>
          <ion-text class="" [ngClass]="isMobile == true ? 'bodyM-txt-italic' : 'bodyL-txt-italic' "   color="gray100">Please also bring your drivers license, your insurance card, and a list of any medications you currently take.</ion-text>
        </div>
      </div>
    </div>
    <div class="next_main">
      <div class="column">
        <ion-text class="h3-Montserrat text-blue-color mt-10 " [ngClass]="isMobile == true ? 'mb-20' : 'mb-10' ">What’s Next?</ion-text>
        <ion-text class="mb-20" [ngClass]="isMobile == true ? 'bodyS-txt' : 'bodyL-txt' " color="gray100">You'll receive an email with the details of this appointment, and if you provided your phone number, we’ll also send you text messages to remind you as your appointment time approaches.</ion-text>
        <ion-text class="" color="gray100" [ngClass]="isMobile == true ? 'bodyS-txt' : 'bodyL-txt' ">Thanks for booking an appointment with us.  We look forward to seeing you!</ion-text>
      </div>
    </div>
  </div>
</div>