<div class="choose_time_main scroll_main">
  <div *ngIf="isMobile">
    <self-scheduling-header></self-scheduling-header>
  </div>
  <div class="choose_time_container">
    <div class="choose_time_header  text-center py-15" [ngClass]="isMobile == true ? '' : 'borderBottom20'">
      <ion-text class="h3-Montserrat" color="gray100" *ngIf="isDesktopDevice || isTablet">Choose another time</ion-text>
      <ion-text class="h3-Montserrat text-blue-color" *ngIf="isMobile">Choose another time</ion-text>
    </div>
    <div class="choose-time-content py-20 px-40 pb-0 scroll_body">
      <div class="warning_box d-flex align-items-center justify-content-center">
        <div class="mr-15">
          <ion-icon class="bodyXXL-txt" src="../../../../../assets/svg/alert-triangle2.svg" color="warning100"></ion-icon>
        </div>
        <div>
          <ion-text class="bodyM-txt" color="primarydk">Sorry, <b>Mar 13 at 9:15</b> is no longer available. Please choose another time below</ion-text>
        </div>
      </div>
      <div class="time_slot mt-35 mb-35">
        <div class="text-center mb-10">
          <ion-text class="bodyM-txt-medium" color="gray80">Wednesday, March 13, 2024</ion-text>
        </div>
        <div class="mb-10">
          <ion-text class="bodyM-txt-bold mr-5" color="gray80">Downtown Partners</ion-text>
          <ion-text class="bodyXS-txt" color="gray80">5275 S Main Street, Seattle, WA 85471</ion-text>
        </div>
        <div class="mb-10">
          <ion-text class="bodyM-txt" color="gray80">Dr. John Smithsonian</ion-text>
        </div>
        <div class="slot_list mb-10">
          <div class="time_slots">
            <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">8:30 AM</ion-text>
          </div>
          <div class="time_slots" [ngClass] ="isSelectSlot == true ? 'active_slot' : '' " (click)="selectSlot()" >
            <span class="success-circle" *ngIf="isSelectSlot">
              <ion-icon name="checkmark-outline" class=" ion-color-white" color=""></ion-icon>
            </span>
            <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">9:15 AM</ion-text>
          </div>
          <div class="time_slots">
            <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">11:00 AM</ion-text>
          </div>
          <div class="time_slots">
            <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">12:15 PM</ion-text>
          </div> 
          <div class="time_slots">
            <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">1:00 PM</ion-text>
          </div> 
          <div class="time_slots">
            <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">3:15 PM</ion-text>
          </div>
          <div class="time_slots">
            <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">4:45 PM</ion-text>
          </div>
        </div>
      </div>
      <div class="text-center">
        <ion-text class="font20-regular pointer" color="highlight" *ngIf="isDesktopDevice || isTablet" routerLink="/app/patient-appointment-time">Click here to see more options</ion-text>
        <ion-text class="font20-regular pointer pb-20" color="highlight" *ngIf="isMobile" routerLink="/app/patient-appointment-time">See more options</ion-text>
      </div>
    </div>
    <div class="choose_time_footer text-center p-20 pb-30" *ngIf="isDesktopDevice || isTablet">
      <div class="w-100 text-center">
        <ion-button fill="solid" class="filled-icon-btn capitalize btn m-0" (click)="closeChooseTimePopup()" >
            <ion-text color="white">Book appointment</ion-text>
            <ion-icon src="../../../../assets/svg/arrow-right.svg" color="white" class="ml-10"></ion-icon>
        </ion-button>
       </div>
    </div>
    <ion-footer *ngIf="isMobile" class="borderTopGray20 bg-white choose_time_footer_mobile">
      <div class="py-10 px-30 text-center">
        <ion-button fill="solid" color="ion-color-white" class="filled-icon-btn capitalize w-100" routerLink="/app/booked-appointment">
          <ion-text> Next</ion-text>
          <ion-icon src="../../../../../../../assets/svg/arrow-right.svg" class="ml-5"></ion-icon>
        </ion-button>
      </div>
    </ion-footer>
  </div>
  
</div>