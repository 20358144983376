
<div class="schedule_appointment_time_main scroll_main">
  <div>
    <self-scheduling-header></self-scheduling-header>
  </div>
  <div class="container p-0">
    <div class="schedule_appointment_time ">
      <div class="row-new">
        <div class="col-4-lg col-5-md col-12-sm schedule_appointment_time_left scroll_body p-20" *ngIf="appointmentFilter">
          <div class="pick_time mb-20" *ngIf="isMobile">
            <ion-text class="h3-Montserrat text-blue-color">Search by</ion-text>
          </div>
          <div class="">
            <kendo-combobox [data]="patientList" placeholder="Select Patient" [suggest]="true" class="bodyM-txt" [value]="selectedPatient">
            </kendo-combobox>
          </div>
          <!-- for existing patient -->
          <!-- <div class="">
            <kendo-combobox [data]="existingPatientList" placeholder="Follow up" [suggest]="true" class="bodyM-txt" [value]="selectedExistingPatient">
            </kendo-combobox>
          </div> -->
          <div class="mt-20">
            <kendo-combobox [data]="locationList" placeholder="Any location" [suggest]="true" class="bodyM-txt">
            </kendo-combobox>
          </div>
          <div class="mt-20">
            <kendo-combobox [data]="providerList" placeholder="Any provider" [suggest]="true" class="bodyM-txt">
            </kendo-combobox>
          </div>
          <div class="mt-20 mb-20">
            <kendo-buttongroup selection="single" class="w-100">
              <button class="bodyM-txt " id="btn1" kendoButton [toggleable]="true">
                Any time
              </button>
              <button class="bodyM-txt ion-text-capitalize" id="btn2" kendoButton [toggleable]="true"
                  [selected]="true" >
                  AM
              </button>
              <button class="bodyM-txt ion-text-capitalize" id="btn3" kendoButton [toggleable]="true">
                  PM
              </button>
            </kendo-buttongroup>
          </div>
          <div class="text-center">
            <kendo-calendar [navigation]="false" [value]="value" [disabledDates]="disabledDates"></kendo-calendar>
          </div>
        </div>
        <div class="col-8-lg col-7-md col-12-sm schedule_appointment_time_right scroll_body" *ngIf="appointmentList"> 
          <div class="pick_time text-center mb-15">
            <ion-text class="h3-Montserrat text-blue-color">Pick an available time</ion-text>
          </div>
          <div class="show_result_main d-flex justify-content-sb align-items-center" *ngIf="isMobile">
            <div class="column w-80">
              <ion-text class="bodyS-txt uppercase d-inline-block mb-5" color="gray80">showing results for:</ion-text>
              <div class="">
                <ion-text class="bodyXS-txt-bold" color="gray100">New patient</ion-text>
                <ion-text class="bodyXS-txt-bold d-inline-block mx-5" color="gray100">|</ion-text>
                <ion-text class="bodyXS-txt-italic" color="gray100">All locations</ion-text>
                <ion-text class="bodyXS-txt-bold d-inline-block mx-5" color="gray100">|</ion-text>
                <ion-text class="bodyXS-txt-italic" color="gray100">All providers</ion-text>
                <ion-text class="bodyXS-txt-bold d-inline-block mx-5" color="gray100">|</ion-text>
                <ion-text class="bodyXS-txt-italic" color="gray100">Anytime</ion-text>
              </div>
            </div>
            <div class="w-20 text-right">
              <ion-text  class="bodyS-txt pointer" color="highlight"  (click)="showAppointmentFilter()">Change</ion-text>
            </div>
          </div>
          <div class="schedule_appointment_time_nav mb-10">
            <div class="appointment_nav_btn mr-10 btn-disabled">
              <ion-icon class="h3" src="../../../../../assets/svg/chevron-left.svg" color="highlight"></ion-icon>
            </div>
            <div class="text-center pointer" id="cal-date-popup">
              <ion-text class="bodyL-txt ion-color-primarydk">Wednesday, March 13, 2024</ion-text>
            </div>
            <div class="appointment_nav_btn ml-10">
              <ion-icon class="h3" src="../../../../../assets/svg/chevron-right.svg" color="highlight"></ion-icon>
            </div>
          </div>
          <div class="schedule_slot_main pt-10">
            <div class="mb-10">
              <ion-text class="bodyM-txt-bold mr-5" color="gray80">Downtown Partners</ion-text>
              <ion-text class="bodyXS-txt" color="gray80">5275 S Main Street, Seattle, WA 85471</ion-text>
            </div>
            <div class="slots mb-10">
              <ion-text class="bodyM-txt mb-10 d-inline-block" color="gray80">Dr. John Smithsonian</ion-text>
              <div class="slot_list">
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">8:30 AM</ion-text>
                </div>
                <div class="time_slots" [ngClass] ="isSelectSlot == true ? 'active_slot' : '' " (click)="selectSlot()" >
                  <span class="success-circle">
                    <ion-icon name="checkmark-outline" class=" ion-color-white" color=""></ion-icon>
                  </span>
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">9:15 AM</ion-text>
                </div>
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">11:00 AM</ion-text>
                </div>
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">12:15 PM</ion-text>
                </div> 
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">1:00 PM</ion-text>
                </div> 
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">3:15 PM</ion-text>
                </div>
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">4:45 PM</ion-text>
                </div>
              </div>
            </div>
            <div class="slots pb-15 borderBottom20">
              <ion-text class="bodyM-txt mb-10 d-inline-block" color="gray80">Dr. Yvonne Jenson</ion-text>
              <div class="slot_list">
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">9:15 AM</ion-text>
                </div>
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">9:45 AM</ion-text>
                </div>
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">11:00 AM</ion-text>
                </div>
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">12:15 PM</ion-text>
                </div> 
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">1:00 PM</ion-text>
                </div> 
              </div>
            </div>
            <div class="mb-10 mt-15">
              <ion-text class="bodyM-txt-bold mr-5" color="gray80">Northside Clinic</ion-text>
              <ion-text class="bodyXS-txt" color="gray80">1655 NW Smithson Ave, Seattle, WA 85471</ion-text>
            </div>
            <div class="slots mb-10">
              <ion-text class="bodyM-txt mb-10 d-inline-block" color="gray80">Jeff Larson, P.A.</ion-text>
              <div class="slot_list">
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">11:00 AM</ion-text>
                </div>
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">12:15 PM</ion-text>
                </div>
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">1:00 PM</ion-text>
                </div>
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">3:15 PM</ion-text>
                </div> 
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">4:45 PM</ion-text>
                </div> 
              </div>
            </div>
            <div class="slots pb-15 borderBottom20">
              <ion-text class="bodyM-txt mb-10 d-inline-block" color="gray80">Dr. Yvonne Jenson</ion-text>
              <div class="slot_list">
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">9:15 AM</ion-text>
                </div>
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">9:45 AM</ion-text>
                </div>
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">11:00 AM</ion-text>
                </div>
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">12:15 PM</ion-text>
                </div> 
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">1:00 PM</ion-text>
                </div> 
              </div>
            </div>
            <div class="mb-10 mt-15">
              <ion-text class="bodyM-txt-bold mr-5" color="gray80">Turner Center Doctors</ion-text>
              <ion-text class="bodyXS-txt" color="gray80">165 Turner Blvd, Seattle, WA 85471</ion-text>
            </div>
            <div class="slots mb-10">
              <ion-text class="bodyM-txt mb-10 d-inline-block" color="gray80">Dr. Barry Blevinston.</ion-text>
              <div class="slot_list">
                <div class="time_slots mb-10 ">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">8:30 AM</ion-text>
                </div>
            </div>
            <div class="slots mb-10">
              <ion-text class="bodyM-txt mb-10 d-inline-block" color="gray80">Dr. Thorgen Norseman</ion-text>
              <div class="slot_list">
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">11:00 AM</ion-text>
                </div>
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">12:15 PM</ion-text>
                </div>
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">1:00 PM</ion-text>
                </div>
              </div>
            </div>
            <div class="slots pb-15">
              <ion-text class="bodyM-txt mb-10 d-inline-block" color="gray80">Dr. Bartholomew Simpson, III</ion-text>
              <div class="slot_list">
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">11:00 AM</ion-text>
                </div>
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">12:15 PM</ion-text>
                </div>
                <div class="time_slots">
                  <ion-text class="bodyM-txt-medium montserrat ion-color-primary100">1:00 PM</ion-text>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white">
    <div class="schedule-footer">
      <div class="w-100 scheduleStepper px-30 py-20" style="border-top: 1px solid #CCD4D8;">
        <kendo-stepper class="" [steps]="steps" [(currentStep)]="current" *ngIf="appointmentList">
        </kendo-stepper>
        <div class="w-100 text-right mt-15" *ngIf="isDesktopFooter">
            <ion-button fill="solid" class="filled-icon-btn text-transform-initial btn m-0" (click)="next()" [disabled]="!isSelectSlot" >
                <ion-text color="white">Patient information</ion-text>
                <ion-icon src="../../../../assets/svg/arrow-right.svg" color="white" class="ml-10"></ion-icon>
            </ion-button>
        </div>
        <div class="w-100 text-right mt-15" *ngIf="appointmentListFooter">
          <ion-button fill="solid" class="filled-icon-btn capitalize btn m-0 w-100 " (click)="next()" [disabled]="!isSelectSlot">
              <ion-text color="white">Next</ion-text>
              <ion-icon src="../../../../assets/svg/arrow-right.svg" color="white" class="ml-10"></ion-icon>
          </ion-button>
        </div>
        <div class=" w-100 d-flex align-items-center justify-content-sb"  *ngIf="appointmentFilterFooter" >
          <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize btn m-0 mr-15 w-100">
            <ion-text>Cancel</ion-text>
          </ion-button>
  
          <ion-button fill="solid" class="filled-icon-btn capitalize btn m-0 w-100" (click)="showAppointementList()">
            <ion-text color="white">Update</ion-text>
          </ion-button>
        </div>
      </div> 
    </div>
  </div>
</div>


<ion-popover [keepContentsMounted]="true" trigger="cal-date-popup" class="ApptcalenderPopover customionpopover"
    side="bottom" alignment="center" popover-animation="true" arrow="false">
    <ng-template>
        <div class="calender-section">
            <kendo-calendar #calendar type="classic"></kendo-calendar>
        </div>
    </ng-template>
</ion-popover>