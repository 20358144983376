import { Component, OnInit } from '@angular/core';
// import { DeviceDetectorService } from 'ngx-device-detector';
import { FormControl, FormGroup } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'patient-insurance-popup',
  templateUrl: './patient-insurance-popup.component.html',
  styleUrls: ['./patient-insurance-popup.component.scss'],
})
export class PatientInsurancePopupComponent  implements OnInit {
  inactive: boolean = false;
ismorecontact:boolean=false;
hidebtn:boolean=true;
isDesktop: boolean;
// ismobile:boolean;
breakpoint: any = window.matchMedia('(min-width: 767px)');
// mobileview: any = window.matchMedia('(max-width: 768px)');
public value: Date = new Date(2000, 2, 10);

constructor(private deviceService: DeviceDetectorService) {
  this.isDesktop = !!this.breakpoint.matches;
 }

 isMobile!: boolean;
 isTablet!: boolean;
 isDesktopDevice!: boolean;
 deviceInfo: import("ngx-device-detector").DeviceInfo | undefined;

 ngOnInit(): void {
  this.epicFunction();
}
  epicFunction() {
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    console.log("Mobile : " +this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log("Tablet : " +this.isTablet);  // returns if the device us a tablet (iPad etc)
    console.log("Desktop : " +this.isDesktopDevice); // returns if the app is running on a Desktop browser.
  }
  
  // Add Modal
  public opened = false;

  public close(): void {
    this.opened = false;
  }
  public Name: Array<string> = [
   "Blue Cross of Michigan PPO"
  ];
  public selectedGender = "Male";
  public Genders : Array<string> = [
    "Male",
     "Female", 
     "Other"
   ];
  public registerForm: FormGroup = new FormGroup({
  //  email:new FormControl(),
    dobInput: new FormControl()
  })

  showmore(){
    this.ismorecontact=true;
    this.hidebtn=false;
  }
  removemorecontact(){
    this.ismorecontact=false;
  }
  issomeoneholder:boolean=false;

  showSomeOne(){
    
    const someOne1 = document.getElementById("primarySomeOne");
    if(someOne1 != null){
      console.log("someOne");
      if(someOne1.classList.contains("d-none")){
        someOne1.classList.add("d-block");
        someOne1.classList.remove("d-none");
      }else{
        someOne1.classList.remove("d-block");
        someOne1.classList.add("d-none");
      }
    }
  }
  someoneholder(){
    this.showSomeOne();
    this.issomeoneholder=!this.issomeoneholder;
    
  }
  isholder:boolean=true;
  isviewcard:boolean=false;
  viewcard(){
    this.isviewcard=!this.isviewcard;
    this.isholder=false;
  }

  isopenViewinsurancePopup:boolean=false
  openViewinsurancePopup() {
   this.isopenViewinsurancePopup=true;
  }
  onclose() {
    this.isopenViewinsurancePopup = false;
  }

  dob:string = '';  
  public maxlength = 10;
  formatDOB(event: any) {     
    const inputElement = event.target;     
    let inputValue = inputElement.value;    
    inputValue = inputValue.replace(/\D/g, '');     
    if (inputValue.length > 2 && inputValue.charAt(2) !== '/') {       
      inputValue = inputValue.slice(0, 2) + '/' + inputValue.slice(2);     
    }     if (inputValue.length > 5 && inputValue.charAt(5) !== '/') {       
      inputValue = inputValue.slice(0, 5) + '/' + inputValue.slice(5);    
     }
    inputElement.value = inputValue;   
  }

  //@ngneat/input-mask
  dateFC = new FormControl('');
  dateInputMask = createMask<Date>({
    alias: 'datetime',
    inputFormat: 'mm/dd/yyyy',
    parser: (value: string) => {
      const values = value.split('/');
      const year = +values[2];
      const month = +values[1] - 1;
      const date = +values[0];
      return new Date(year, month, date);
    }
  });

}
