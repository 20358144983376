import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clinic-dashboard-practice-list',
  templateUrl: './clinic-dashboard-practice-list.component.html',
  styleUrls: ['./clinic-dashboard-practice-list.component.scss'],
})
export class ClinicDashboardPracticeListComponent  implements OnInit {

  isActive: boolean = false;

  constructor() { }

  ngOnInit() {}
  
  practiceName : { name: string; active: boolean }[] = [
    {
      name: 'Practice 1',
      active: false
    },
    {
      name: 'Practice 2',
      active: false
    },
    {
      name: 'Practice 3',
      active: false
    },
    {
      name: 'Practice 4',
      active: false
    },
    {
      name: 'Practice 5',
      active: false
    },
    {
      name: 'Practice 6',
      active: false
    },
    {
      name: 'Practice 7',
      active: false
    },
    {
      name: 'Practice 8',
      active: false
    },
    {
      name: 'Practice 9',
      active: false
    },
    {
      name: 'Practice 10',
      active: false
    },
    {
      name: 'Practice 11',
      active: false
    }
  ];
  activePracticeList(clickItem: { name: string; active: boolean }){
    this.practiceName.forEach((practice) => (practice.active = false));
    clickItem.active = true
    this.isActive = !this.isActive;
  }

}
