<main-header></main-header>
<div class="main-div">
      <div class="column px-30 pt-20 patientInfoMobileHeading">
        <ion-text class="bodyL-txt-large text-blue-color ion-align-items-center d-flex">
          <span class="bodyXS-txt-bold text-blue-color stepper-circle mr-10" >1</span>
          Update your information
        </ion-text>  
        </div>
        <div class="update-info p-30">
            <form  class="k-form " [formGroup]="registerForm">
              <div class="mb-35">
                <label class="k-form" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">Full Name</label>
                <div class="d-flex mb-35">
                  <div class="mr-10">
                    <!-- <label class="k-form" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">name</label> -->
                    <kendo-textbox class="bodyM-txt  ion-color-gray100" color="gray100" placeholder="*Last Name"
                      formControlName="lname">
                    </kendo-textbox>
                  </div>
                  <div class="mr-10">
                    <!-- <label class="k-form" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">name</label> -->
                    <kendo-textbox class="bodyM-txt  ion-color-gray100" color="gray100" placeholder="Middle Name"
                      formControlName="mname">
                    </kendo-textbox>
                  </div>
                  <div>
                    <!-- <label class="k-form" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">name</label> -->
                    <kendo-textbox class="bodyM-txt  ion-color-gray100" color="gray100" placeholder="*First Name"
                      formControlName="fname">
                    </kendo-textbox>
                  </div>
                </div>
              </div>
              <div class="mb-35">
                <label class="mb-3" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">Address</label>
                <kendo-textbox  class="bodyM-txt mb-8 ion-color-gray100" color="gray100" placeholder="Address  1"></kendo-textbox >
                <kendo-textbox  class="bodyM-txt mb-8 ion-color-gray100" color="gray100" placeholder="Address  2"></kendo-textbox >
                <div class="d-flex align-items-center">
                  <div class="w-50 mr-10">
                    <kendo-multicolumncombobox
                    [data]="zipList"
                    textField="zip"
                    valueField="zip"
                    placeholder="*Zip"
                    class="body2-txt"
                    >
                    <kendo-combobox-column field="zip" title="Zip" [width]="75" [headerStyle]="{ 'font-size': '14px;','font-weight': 'light', 'color':'#4B5961;', 'background-color' : '#F4F4F5'}"  >
                        <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
                          <span class="bodyS-txt">{{ dataItem.zip }}</span>
                        </ng-template>
                    </kendo-combobox-column>
                    <kendo-combobox-column field="city" title="City" [width]="150" [headerStyle]="{ 'font-size': '14px;','font-weight': 'light', 'color':'#4B5961;', 'background-color' : '#F4F4F5'}">
                        <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
                          <span class="bodyS-txt">{{ dataItem.city }}</span>
                        </ng-template>
                    </kendo-combobox-column>
                  </kendo-multicolumncombobox>
                  </div>
                  <div class="w-50 ">
                    <kendo-textbox class="bodyM-txt mb-8 ion-color-gray100" color="gray100" placeholder="City"></kendo-textbox>
                  </div>
                  <!-- <div>
                    <kendo-textbox class="bodyM-txt mb-8 ion-color-gray100" color="gray100" placeholder="County"></kendo-textbox>
                  </div> -->
                </div>
              </div>
              <div class="mb-35" >
                <label  class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">Phone</label>
                <div class="d-flex align-items-center">
                  <kendo-maskedtextbox class="body2-txt contactNo mobileNo mr-12 w-50" [mask]="mask" formControlName="mobile" (focus)="focus2()">
                  </kendo-maskedtextbox>
                  <ion-text class="bodyS-txt ion-text-uppercase w-50 ion-color-gray80" color="gray80">Mobile
                  </ion-text>
                </div>
                <div class="d-flex ion-align-items-center">
                  <kendo-maskedtextbox class="body2-txt contactNo mobileNo mr-12 w-50" [mask]="mask2"  formControlName="home" (focus)="focus3()">
                  </kendo-maskedtextbox>
                  <div class="w-50 d-flex ion-align-items-center">
                    <ion-text class="bodyS-txt ion-text-uppercase w-50 ion-color-gray80" color="gray80">Home
                    </ion-text>
                    <div class="w-100 ion-text-right" *ngIf="hidebtn">
                      <ion-button [disabled]="inactive" slot="only-icon" shape="round" fill="clear"
                        class="ion-no-padding round-icon-button" (click)="showmore()">
                        <ion-icon class="header-icon simple-icon ion-color-highlight" color="highlight"
                          src="../../../../assets/svg/plus.svg"></ion-icon>
                      </ion-button>
                    </div>
                  </div>
                </div>
                <div class="d-flex ion-align-items-center mb-8" *ngIf="ismorecontact">
                  <kendo-maskedtextbox class="body2-txt contactNo mobileNo mr-12 w-50" id="mobileNumber" [mask]="mask3" (focus)="focus4()"
                    formControlName="office">
                  </kendo-maskedtextbox>
                  <div class="w-50 d-flex ion-align-items-center">
                    <ion-text class="bodyS-txt ion-text-uppercase w-50 ion-color-gray80" color="gray80">Office
                    </ion-text>
                    <div class="w-100 ion-text-right">
                      <ion-button [disabled]="inactive" slot="only-icon" shape="round" fill="clear"
                        class="ion-no-padding round-icon-button" (click)="showmore()">
                        <ion-icon class="header-icon simple-icon ion-color-highlight" color="highlight"
                          src="../../../../assets/svg/plus.svg"></ion-icon>
                      </ion-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="wrapper mb-35" >
                    <label class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">Email</label>
                    <div class="wrapper body2-txt">
                      <kendo-formfield>
                      <kendo-textbox  [disabled]="inactive"  class="body2-txt" placeholder="Email"></kendo-textbox>
                      </kendo-formfield>
                   </div>
              </div>
              <div class="wrapper mb-35" >
                <label class="" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">Emergency Contact</label>
                <div class="d-flex align-items-center">
                  <kendo-textbox class="bodyM-txt w-60 mr-20 ion-color-gray100" color="gray100"
                    formControlName="emergency_contact" placeholder="Emergency Contact Name"></kendo-textbox>
                  <kendo-textbox class="bodyM-txt w-40  ion-color-gray100" color="gray100"
                    formControlName="emergency_mobile" placeholder="Contact number"></kendo-textbox>
                </div>
              </div>
              <div class="refer_care_preference_main">
                <div class="mb-10">
                  <label class="" class="bodyS-txt ion-text-uppercase text-gray80-color">referring Provider </label>
                  <div class="d-flex align-items-center justify-content-sb">
                    <div class="w-100 mr-10">
                      <kendo-textbox class="bodyM-txt mr-20 ion-color-gray100" color="gray100"  placeholder=""></kendo-textbox>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center p-5 mr-5 pointer" (click)="openReferPopup($event)">
                        <ion-icon class="bodyXL-txt" src="../../../../assets/svg/search.svg" color="highlight"></ion-icon>
                      </div>
                      <div class="d-flex align-items-center p-5 pointer">
                        <ion-icon class="bodyXL-txt" src="../../../../assets/svg/x.svg" color="highlight"></ion-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-10">
                  <div class="d-flex justify-content-sb  mr-10">
                    <div>
                      <label class="" class="bodyS-txt ion-text-uppercase text-gray80-color">primary care Provider  </label>
                    </div>            
                    <div class="d-flex align-items-center">
                      <label class="" class="bodyS-txt ion-text-uppercase text-gray80-color mr-10" *ngIf="!isMobile">same as above</label>
                      <label class="" class="bodyS-txt ion-text-uppercase text-gray80-color mr-10" *ngIf="isMobile">same as ab...</label>
                      <input type="checkbox" kendoCheckBox />
                    </div>            
                  </div>
                  <div class="d-flex align-items-center justify-content-sb">
                    <div class="w-100 mr-10">
                      <kendo-textbox class="bodyM-txt mr-20 ion-color-gray100" color="gray100" placeholder=""></kendo-textbox>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center p-5 mr-5 pointer" (click)="openPhysiciansPopup($event)">
                        <ion-icon class="bodyXL-txt" src="../../../../assets/svg/search.svg" color="highlight"></ion-icon>
                      </div>
                      <div class="d-flex align-items-center p-5 pointer">
                        <ion-icon class="bodyXL-txt" src="../../../../assets/svg/x.svg" color="highlight"></ion-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-5">
                  <label class="" class="bodyS-txt ion-text-uppercase text-gray80-color">preferred pharmacy </label>
                  <div class="d-flex align-items-center justify-content-sb">
                    <div class="w-100 mr-10">
                      <kendo-textbox class="bodyM-txt mr-20 ion-color-gray100" color="gray100"  placeholder=""></kendo-textbox>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center p-5 mr-5 pointer" (click)="openPharmacyPopup($event)">
                        <ion-icon class="bodyXL-txt" src="../../../../assets/svg/search.svg" color="highlight"></ion-icon>
                      </div>
                      <div class="d-flex align-items-center p-5 pointer">
                        <ion-icon class="bodyXL-txt" src="../../../../assets/svg/x.svg" color="highlight"></ion-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            
        </div>
  </div>
<ion-footer *ngIf="!isDesktop">
  <div class="px-30 py-10 row w-100 ion-justify-content-center align-items-center ion-bg-unfocused-color">
    <ion-button fill="outline" color="highlight"  class="filled-outline-btn capitalize info-btn mr-15" routerLink="/app/info-focus">
      <ion-text>Cancel</ion-text>
    </ion-button>
    <ion-button fill="solid"  expand="block" color="ion-color-white" class="filled-icon-btn capitalize info-btn">
      <ion-text>Save</ion-text>
    </ion-button>
    </div>
  </ion-footer>
