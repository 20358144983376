<div class="warningScreen_main">
  <div class="warningScreen_content">
    <ion-icon src="../../../../assets/svg/info.svg" class="warning_icon d-block mb-20"></ion-icon>
    <ion-text class="bodyL-txt-large  mb-20" color="gray100">Please use a tablet or 
      a web browser to access 
      the application</ion-text>
    <div>
      <ion-button fill="solid" color="white" class="filled-btn warning_btn">
        <ion-text class="h5 roboto text-uppercase">OK</ion-text>
      </ion-button>
    </div>  
  </div>
</div>