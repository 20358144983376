<div class="tablet_in_clinic_footer main_container">
  <ion-footer class="page-bottom  ion-header-color">
    <div class="p-15 d-flex justify-content-center align-items-center">
      <div class="tabletFooterText">
        <ion-text class="number-txt montserrat text-right mr-20 " color="gray80">
          If you need to pause this process hit the lock button and hand back to front desk
        </ion-text>
      </div>
      <div>
        <ion-button fill="outline" color="gray80" class="filled-outline-btn finish-btn capitalize" routerLink="/app/patient-lock">
          <ion-icon src="../../../../assets/svg/dashbaord-lock.svg" class="title-32-font-size mr-5" color="gray80"></ion-icon>
          <ion-text class="number-txt montserrat" color="gray80">Lock Tablet</ion-text>
        </ion-button>
      </div>
    </div>
  </ion-footer>
</div>
