<main-header></main-header>
<div class="paymentpopup">
<kendo-dialog>
<div class="">
  <!-- <div class="column px-30 pt-20 patientInfoMobileHeading" *ngIf="!isDesktop">
    <ion-text class="bodyL-txt-large text-blue-color ion-align-items-center d-flex">
      <span class="bodyXS-txt-bold text-blue-color stepper-circle mr-10">3</span>
      Payment
    </ion-text>
  </div> -->
  <div class="payment-info">
    <div class="heading mb-20 column px-40">
      <ion-text class="h3-M-secondary tabletFont "> Make a payment to</ion-text>
      <ion-text class="h3-B tabletFont" color="gray100">
        Michigan Institute of Urology
      </ion-text>
    </div>
    <div class="px-40 payment-main mobileviewpayment">
      <div class="w-100 d-flex ">
        <div class="ion-justify-content-between w-50 d-flex-m mr-25 mb-20 ">
          <ion-text class="bodyM-txt" color="gray100">Copay</ion-text>
          <ion-text class="bodyL-txt-bold" color="gray100">15.00</ion-text>
        </div>
        <div class="ion-justify-content-between w-50 d-flex-m mb-20 ">
          <ion-text class="bodyM-txt" color="gray100">Previous balance</ion-text>
          <ion-text class="bodyL-txt-bold" color="gray100">50.00</ion-text>
        </div>
      </div>
      
      <div class="d-flex mobile-direction-column ">
        <div class="w-50 mobile-100 mr-25 mb-20">
          <!-- <label class="mb-3" class="bodyS-txt ion-text-uppercase ion-color-gray80">first name</label> -->
          <kendo-textbox class="bodyM-txt ion-color-gray100" color="gray100"  placeholder="First Name"></kendo-textbox>
        </div>
        <div class="w-50 mobile-100 mb-20">
          <!-- <label class="mb-3" class="bodyS-txt ion-text-uppercase ion-color-gray80">last Name</label> -->
          <kendo-textbox class="bodyM-txt ion-color-gray100 " color="gray100" placeholder="Last Name"></kendo-textbox>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="w-50 mobile-100 mr-25 mb-20">
          <kendo-multicolumncombobox
          [data]="zipList"
          textField="zip"
          valueField="zip"
          placeholder="Zip"
          class="body2-txt"
          >
          <kendo-combobox-column field="zip" title="Zip" [width]="75" [headerStyle]="{ 'font-size': '14px;','font-weight': 'light', 'color':'#4B5961;', 'background-color' : '#F4F4F5'}"  >
              <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
                <span class="bodyS-txt">{{ dataItem.zip }}</span>
              </ng-template>
          </kendo-combobox-column>
          <kendo-combobox-column field="city" title="City" [width]="150" [headerStyle]="{ 'font-size': '14px;','font-weight': 'light', 'color':'#4B5961;', 'background-color' : '#F4F4F5'}">
              <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
                <span class="bodyS-txt">{{ dataItem.city }}</span>
              </ng-template>
          </kendo-combobox-column>
        </kendo-multicolumncombobox>
        </div>
        <div class="w-50 mobile-100 mb-20">
          <!-- <label class="mb-3" class="bodyS-txt ion-text-uppercase ion-color-gray80">Patient Number</label> -->
          <kendo-textbox class="bodyM-txt ion-color-gray100 " color="gray100" placeholder="Patient Number"></kendo-textbox>
        </div>
      </div>
      <!-- <div class="mb-42 ion-justify-content-between w-100 d-flex align-items-center">
                <ion-text class="bodyM-txt-bold" color="gray100">Total</ion-text>
                <ion-text class="bodyL-txt-bold" color="gray100">$65.00</ion-text>
              </div> -->
      <div class="column payment-details">
        <ion-text class="bodyL-txt-medium mb-20">Payment Details</ion-text>

        <kendo-textbox class="bodyM-txt ion-color-gray100 mb-10 px-5" color="gray100" placeholder="Card Number">
          <ng-template kendoTextBoxPrefixTemplate>
            <ion-icon
              class="ion-color-gray80 bodyXL-txt mr-10" src="../../../../assets/svg/DebtCard.svg">></ion-icon>
            </ng-template>
        </kendo-textbox>
        <!-- <ion-item class=" ion-no-padding">
          <div class="align-items-center w-100 d-flex justify-content-sb">
            <ion-text class="bodyM-txt align-items-center d-flex" color="gray100"><ion-icon
                class="ion-color-gray100 bodyXL-txt mr-10" src="../../../../assets/svg/DebtCard.svg"></ion-icon>Card
              Number</ion-text>
          </div>
        </ion-item> -->
        <kendo-textbox class="bodyM-txt ion-color-gray100 mb-10 px-5" color="gray100" placeholder="Card Holder Name">
          <ng-template kendoTextBoxPrefixTemplate>
            <ion-icon
              class="ion-color-gray80 bodyXL-txt mr-10" src="../../../../assets/svg/user.svg"></ion-icon>
            </ng-template>
        </kendo-textbox>
        <div class="d-flex ion-justify-content-between ">
          <!-- <ion-item class=" ion-no-padding w-50 mr-25">
            <div class="align-items-center d-flex justify-content-sb">
              <ion-text class="bodyM-txt align-items-center d-flex" color="gray100"> <ion-icon
                  class="ion-color-gray100 bodyXL-txt mr-10"
                  src="../../../../assets/svg/calendar-dash.svg"></ion-icon>Expiration Date</ion-text>
            </div>
          </ion-item> -->
          <div class="d-flex-m align-items-center Expirationdate w-50 mr-25 mb-10">  
          <ion-icon 
          class="ion-color-gray100 bodyXL-txt mr-10 dateicon pl-5"
          src="../../../../assets/svg/calendar-dash.svg"></ion-icon>
          <kendo-dateinput placeholder="Expiration Date" > 
        
            </kendo-dateinput>
            </div>
            <div class="w-50 mb-10">
            <kendo-textbox class="bodyM-txt ion-color-gray100 px-5" color="gray100" placeholder="CVV">
              <ng-template kendoTextBoxPrefixTemplate>
                <ion-icon
                  class="ion-color-gray80 bodyXL-txt mr-10" src="../../../../assets/svg/credit-card-cvv.svg"></ion-icon>
                </ng-template>
            </kendo-textbox>
            </div>
        </div>
      </div>

      <div class="ion-justify-content-between w-100 d-flex total mb-20">
        <ion-text class="bodyM-txt" color="gray100">Amount</ion-text>
        <div class="paybor">
          <ion-text class="bodyL-txt-bold p-5" color="gray100">50.00</ion-text>
        </div>
      </div>

      <kendo-textbox class="bodyM-txt ion-color-gray100 mb-20 " color="gray100" placeholder="Add Note here..."></kendo-textbox>
  
    </div>

    <div class="py-16 px-40 row w-100 ion-justify-content-center align-items-center border-top">
      <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn mr-20" (click)="onClose()"
      >
        <ion-text>Cancel</ion-text>
      </ion-button>
      <ion-button fill="solid" expand="block" color="ion-color-white" class="filled-icon-btn capitalize info-btn"
        (click)="openconfirmation()" >
        <ion-text>done</ion-text>
      </ion-button>
    </div>
  </div>
</div>
<div>
</div>
</kendo-dialog>
</div>

  <div class="confirmationpopup">
    <kendo-dialog *ngIf="confirmation_popup" class="text-center align-items-center  d-flex">
      <div class="mb-20 py-15">
      <ion-text color="gray100" class="h3-M mb-35">Confirm payment?</ion-text>
      </div>
      <div class="d-flex justify-content-center py-20">
        <ion-button fill="solid" expand="block" color="ion-color-white" class="filled-icon-btn capitalize mr-25"  (click)="openconfirmationclose()">
          <ion-text >yes</ion-text>
        </ion-button>
        <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize"
          (click)="openconfirmationclose()">
          <ion-text>No</ion-text>
        </ion-button>
      </div>


    </kendo-dialog>

  </div>