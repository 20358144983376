<div class="clinic_dashboard_provide_list_main">
  <div class="borderBottom20 p-10">
    <ion-searchbar placeholder="Search Providers" mode="q" (change)="showNoResult()"></ion-searchbar>
  </div>
  <div class="p-10 listMain">
    <div class="provider_list_main" *ngIf="!isNoResult">
      <div class="d-flex provider_list mb-5 align-items-center active pointer">
        <div class="width60"><ion-avatar class="patient_txt default-avatar"  slot="start">All</ion-avatar></div>
        <div class="w-100"><ion-text color="gray100" class="bodyM-txt-bold">All Providers</ion-text></div>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <div class="width60">
          <ion-avatar class="patient_txt default-avatar"  slot="start">
            <img src="https://i.pravatar.cc/150?img=18">
          </ion-avatar>
        </div>
        <div class="w-100"><ion-text color="gray100" class="bodyM-txt-regular"><b>Bigsby </b>, Robert</ion-text></div>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">LB</ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Boseman</b>, Larry</ion-text>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">
          <img src="https://i.pravatar.cc/150?img=9">
        </ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Calhoon</b>, Cindy</ion-text>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">RM</ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Markle</b>, Rebecca</ion-text>
      </div>
    </div>
    <div class="provider_list_main" *ngIf="!isNoResult">
      
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">
          <img src="https://i.pravatar.cc/150?img=18">
        </ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Bigsby</b>, Robert</ion-text>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">LB</ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Boseman</b>, Larry</ion-text>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">
          <img src="https://i.pravatar.cc/150?img=9">
        </ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Calhoon</b>, Cindy</ion-text>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">RM</ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Markle</b>, Rebecca</ion-text>
      </div>
    </div>
    <div class="provider_list_main" *ngIf="!isNoResult">
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">
          <img src="https://i.pravatar.cc/150?img=18">
        </ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Bigsby</b>, Robert</ion-text>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">LB</ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Boseman</b>, Larry</ion-text>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">
          <img src="https://i.pravatar.cc/150?img=9">
        </ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Calhoon</b>, Cindy</ion-text>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">RM</ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Markle</b>, Rebecca</ion-text>
      </div>
    </div>
    <div class="provider_list_main" *ngIf="!isNoResult">
      
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">
          <img src="https://i.pravatar.cc/150?img=18">
        </ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Bigsby</b>, Robert</ion-text>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">LB</ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Boseman</b>, Larry</ion-text>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">
          <img src="https://i.pravatar.cc/150?img=9">
        </ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Calhoon</b>, Cindy</ion-text>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">RM</ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Markle</b>, Rebecca</ion-text>
      </div>
    </div>
    <div class="provider_list_main" *ngIf="!isNoResult">
      
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">
          <img src="https://i.pravatar.cc/150?img=18">
        </ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Bigsby</b>, Robert</ion-text>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">LB</ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Boseman</b>, Larry</ion-text>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">
          <img src="https://i.pravatar.cc/150?img=9">
        </ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Calhoon</b>, Cindy</ion-text>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">RM</ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Markle</b>, Rebecca</ion-text>
      </div>
    </div>
    <div class="provider_list_main" *ngIf="!isNoResult">
      
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">
          <img src="https://i.pravatar.cc/150?img=18">
        </ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Bigsby</b>, Robert</ion-text>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">LB</ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Boseman</b>, Larry</ion-text>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">
          <img src="https://i.pravatar.cc/150?img=9">
        </ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Calhoon</b>, Cindy</ion-text>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">RM</ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Markle</b>, Rebecca</ion-text>
      </div>
    </div>
    <clinic-dashboard-search-noresult *ngIf="isNoResult"></clinic-dashboard-search-noresult>
  </div>
</div>
   