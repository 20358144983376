import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KendoModule } from '../kendo.module';
import { HeaderComponent } from './components/header/header.component';
import { WelcomeHeaderComponent } from './components/welcome-header/welcome-header.component';


const imports = [

   CommonModule,
   IonicModule,
   RouterModule,
   FormsModule,
   KendoModule,
   ReactiveFormsModule,
   
  ];

  const sharedComponents = [
    HeaderComponent,
    WelcomeHeaderComponent
  ]

  @NgModule({
  declarations: [ ...sharedComponents ],
  imports: [...imports],
  exports: [
    ...imports,
    ...sharedComponents
  ],
  })

  export class SharedModule { }
