import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'main-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent  implements OnInit {
  isDesktop: boolean;
  // ismobile:boolean;
  breakpoint: any = window.matchMedia('(min-width: 767px)');
  // mobileview: any = window.matchMedia('(max-width: 768px)');
  constructor() {
    this.isDesktop = !!this.breakpoint.matches;
    // this.ismobile = !!this.mobileview.matches;
   }
  ngOnInit() {}

}
