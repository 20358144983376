<welcome-header></welcome-header>
<div class="mid-container">
  <div class="mid-comment-border mid-header-section">
    <div class="contentBox">
      <form class="verification-box k-form column"  [formGroup]="otpform">
            <ion-text class="bodyXL-txt-bold mb-10" color="gray100">Enter verification code</ion-text>
              <ion-text class="bodyM-txt-italic mb-20" color="gray80">Enter the 6 digit code sent to <span class="no-wrap">(357) 999-4444</span>.  
              </ion-text>
          <div class="ion-text-center " >
            <kendo-formfield>
              <div class="d-flex ">
            <kendo-textbox
            [clearButton]="true"
            class="bodyL-txt w-100 mx-30"
            color="gray100"
            (focus)="enableVerify()"
            #code
            formControlName="code"
            required
          >
          </kendo-textbox>
          </div>
          <kendo-formerror class="bodyM-txt mx-30" *ngIf="isShowError">Incorrect code</kendo-formerror>
          </kendo-formfield>
  
          </div>
            <div class="ion-text-center column w-100">
              <ion-button fill="solid" color="ion-color-white" class="filled-icon-btn capitalize my-20" [disabled]="verifyBtn" (click)="verifyCode(otpform.value)" >
                <ion-text>Verify</ion-text>
                <ion-icon src="../../../../../../../assets/svg/arrow-right.svg" class="ml-5"></ion-icon>
              </ion-button>
  
              <ion-text  class="bodyM-txt-italic" color="gray80" >
                Don’t see the text message? <br></ion-text>
              <ion-text color="highlight bodyM-txt-italic">Send a new code
              </ion-text>
            </div>
      </form>
    </div>
  </div>
</div>