<div class="clinic_dashboard_header_main d-flex justify-content-sb px-20 align-items-center borderBottom20">
  <div class="cursor" >
    <div><ion-text class="bodyL-txt-bold text-blue-color hideOnPreRegister">Patient Self-Registration</ion-text></div>
    <div><ion-text class="bodyL-txt-bold text-blue-color showOnPreRegister">Pre-registered Patient</ion-text></div>
    <div class="d-flex align-items-center hideOnPreRegister pointer" id="nav-popover-trigger2">
      <ion-text class="bodyS-txt-italic mr-3" color="gray80">Downtown Clinic</ion-text>
      <ion-icon src="../../../../../../assets/svg/chevron-down.svg" color="gray80" class=""></ion-icon>
    </div>
  </div>
  <ion-popover class="clinic_list_popup" trigger="nav-popover-trigger2" side="bottom" alignment="end" dismiss-on-select="true" show-backdrop="false" style="--offset-x: 1rem; --offset-y:0.5rem" >
    <ng-template>
      <div class="clinic_location_list_main">
        <div class="clinic_location_list d-flex align-items-center borderBottom-20 px-10 pointer">
          <input type="checkbox" kendoCheckBox value="Downtown Clinic" [checked]="true" />
          <kendo-label class="k-checkbox-label" text="Downtown Clinic"></kendo-label>
        </div>
        <div class="clinic_location_list d-flex align-items-center borderBottom-20 px-10 pointer">
          <input type="checkbox" kendoCheckBox value="Westside Office" />
          <kendo-label class="k-checkbox-label" text="Westside Office"></kendo-label>
        </div>
        <div class="clinic_location_list d-flex align-items-center px-10 pointer">
          <input type="checkbox" kendoCheckBox value="Market Village Associates" />
          <kendo-label class="k-checkbox-label" text="Market Village Associates"></kendo-label>
        </div>
      </div>
    </ng-template>
  </ion-popover>
  <div class="d-flex align-items-center">
    <ion-icon class="title-32-font-size cursor mr-20"  src="../../../../../../assets/svg/dashbaord-lock.svg" [routerLink]="['/confirm-patient']"></ion-icon>
    <ion-avatar class="default-avatar cursor" slot="start" id="signout-trigger">
      <img src="https://i.pravatar.cc/150?img=16" />
    </ion-avatar>
  </div>
  <ion-popover class="signout_popup_main" arrow="false" trigger="signout-trigger" side="bottom" alignment="end" dismiss-on-select="true" show-backdrop="false" style="--offset-x: -0.5rem; --offset-y:0.3rem" >
    <ng-template>
      <div class="signout_popup ">
        <div class="px-30 py-10 cursor borderBottomGray20" routerLink="/practice-list">
          <ion-text class="bodyM-txt" color="gray80">Change Pratice</ion-text>
        </div>
        <div routerLink="/clinic/" class="cursor px-30 py-10">
          <ion-text class="bodyM-txt " color="gray80">Logout</ion-text>
        </div>
      </div>
    </ng-template>
  </ion-popover>
</div>
