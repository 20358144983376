import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor() { }
  doLogin(value: { username: string; password: string; }) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(value.username, value.password)
        .then(
          res => {
              // const authToken = res?.user?.getIdToken();
              resolve(res?.user?.getIdToken());
          },
          err => reject(err)
        );
    });
  }
}
