
<div>
  <main-header></main-header>
</div>
<div class="refered_provider_main" [ngClass]="isMobile == true ? 'px-20' : 'px-25'">
  <div class="py-16" *ngIf="isMobile">
    <ion-text class="h3-Montserrat text-blue-color">Referrals</ion-text>
  </div>
  <div class="" [ngClass]="isMobile == true ? 'mb-16' : 'py-10'">
    <ion-searchbar #searchProvider placeholder="Search Providers" mode="q"></ion-searchbar>
  </div>
  <div class="bor-top refered_provider_grid ">
    <!-- <ng-template #template let-anchor>
      <span style="padding: 5px; border-radius: 5px !important;">{{ anchor.nativeElement.innerText }}</span>
    </ng-template> -->
    <div>
      <kendo-grid class="refered_provider_grid w-100 showScrollOnHover" [data]="referalData"  [pageable]="true"
     [resizable]="true" [reorderable]="true" [columnMenu]="false" [navigable]="true" [pageSize]="20" >

      <kendo-grid-column media="(max-width: 768px)">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="column pointer w-100">
            <div class="column mb-10">
              <ion-text class="overline" color="gray80">name</ion-text>
              <ion-text class="bodyM-txt-medium" color="gray100">{{dataItem.firstName}} {{dataItem.middleName}} {{dataItem.lastName}}</ion-text>
            </div>
            <div class="column mb-10">
              <ion-text class="overline" color="gray80">Address</ion-text>
              <ion-text class="bodyM-txt-medium" color="gray100">{{dataItem.Address}}</ion-text>
            </div>
            <div class="d-flex justify-content-sb">
              <div class="column w-50">
                <ion-text class="overline" color="gray80">phone no</ion-text>
                <ion-text class="bodyM-txt-medium" color="gray100">{{dataItem.phone}}</ion-text>
              </div>
              <div class="column w-50 text-align-right">
                <ion-text class="overline" color="gray80">specialty</ion-text>
                <ion-text class="bodyM-txt-medium" color="gray100">{{dataItem.specialty}}</ion-text>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column class="ellipse_row text-left pointer" field="prefix" title="PREFIX"
          media="(min-width: 768px)" [width]="60">
      </kendo-grid-column>
      <kendo-grid-column  class="ellipse_row text-left pointer"  field="firstName" title="FIRST NAME"
          media="(min-width: 768px)"></kendo-grid-column>
      <kendo-grid-column  class="ellipse_row text-left pointer"  field="middleName" title="MIDDLE NAME"
          media="(min-width: 768px)"></kendo-grid-column>
      <kendo-grid-column  class="ellipse_row text-left pointer"  field="lastName" title="LAST NAME"
          media="(min-width: 768px)"></kendo-grid-column>
      <kendo-grid-column  class="ellipse_row text-left pointer"  field="Address" title="ADDRESS"
          media="(min-width: 768px)"></kendo-grid-column>
      <kendo-grid-column  class="ellipse_row text-left pointer"  field="phone" title="PHONE NO"
          media="(min-width: 768px)"></kendo-grid-column>
      <kendo-grid-column  class="ellipse_row text-left pointer"  field="specialty" title="SPECIALTY"
          media="(min-width: 768px)"></kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>
<kendo-dialog-actions class="border mt-20" *ngIf="!isMobile">
  <div class="py-10 row w-100 ion-justify-content-center align-items-center">
    <ion-button fill="outline" color="highlight"
      class="filled-outline-btn capitalize info-btn mr-20 w125" (click)="closeReferPopup()">
      <ion-text>Cancel</ion-text>
    </ion-button>
    <div>
      <ion-button  fill="solid" expand="block"
        color="ion-color-white" class="filled-icon-btn capitalize info-btn w125">
        <ion-text>ok</ion-text>
      </ion-button>
    </div>
  </div>
</kendo-dialog-actions>
<ion-footer  class="bg-white borderTop20" *ngIf="isMobile">
  <div class="px-30 py-10 row w-100 ion-justify-content-sb align-items-center heading100 bg-white">
    <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn mr-15 w-100" (click)="closeReferPopup()">
      <ion-text>Cancel</ion-text>
    </ion-button>
    <ion-button  fill="solid" expand="block" color="ion-color-white w-100"
      class="filled-icon-btn capitalize info-btn">
      <ion-text>Save</ion-text>
    </ion-button>
  </div>
</ion-footer>