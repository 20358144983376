import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-patient-insurance-add-edit-popup',
  templateUrl: './patient-insurance-add-edit-popup.component.html',
  styleUrls: ['./patient-insurance-add-edit-popup.component.scss'],
})
export class PatientInsuranceAddEditPopupComponent  implements OnInit {
  constructor(private deviceService: DeviceDetectorService) { }

  isMobile!: boolean;
  isTablet!: boolean;
  isDesktopDevice!: boolean;
  deviceInfo: import("ngx-device-detector").DeviceInfo | undefined;

  ngOnInit() {
    this.epicFunction();
  }

  epicFunction() {
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    console.log("Mobile : " +this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log("Tablet : " +this.isTablet);  // returns if the device us a tablet (iPad etc)
    console.log("Desktop : " +this.isDesktopDevice); // returns if the app is running on a Desktop browser.
  }
  public registerForm: FormGroup = new FormGroup({
   
   })
  isopenReplaceinsurancePopup:boolean=false
  openReplaceinsurancePopup() {
   this.isopenReplaceinsurancePopup=true;
  }
  onclose() {
    this.isopenReplaceinsurancePopup = false;
  }
  isfrontphoto:boolean=false;
  istakefrontphoto:boolean=true;
  frontphoto(){
    this.isfrontphoto=true;
    this.istakefrontphoto=false;
  }

  isbackphoto:boolean=false;
  istakebackphoto:boolean=true;
  backphoto(){
    this.isbackphoto=true;
    this.istakebackphoto=false;
  }

  openblank(){
  this.istakefrontphoto=true;
  this.isfrontphoto=false;
  }
  openbackblank(){
    this.istakebackphoto=true;
    this.isbackphoto=false;
    }

}
