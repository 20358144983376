<div class="y-scroll w-100">
  <form class="loginBox ion-justify-content-between text-center" #loginForm="ngForm">
    <!-- icon -->
    <div class="text-center">
      <ion-img class="loginBox-logo" src="assets/img/logo-color.png"></ion-img>
    </div>


    <div class="loginBox-otheracc">
      <ion-text>
        <h2>Login to Your Account</h2>
        <!-- <p>Login using social networks</p> -->
      </ion-text>
      <!-- <div class="icons">
              <span class="red"><i class="fa fa-google-plus" aria-hidden="true"></i></span>
              <span class="blue"><i class="fa fa-facebook" aria-hidden="true"></i></span>
          </div>
          <ion-text>
              <p>Or using your Qprime Account</p>
          </ion-text> -->
    </div>

    <div class="loginBox-fields">
      <ion-item class="mb-20">
        <ion-label for="login-un" position="stacked">Username</ion-label>
        <ion-input id="login-un" placeholder="Enter username" name="username" class="mt-7" ngModel #username="ngModel"
          required autocorrect="off" spellcheck="false" autofocus="true" (keydown.enter)="onSubmit(loginForm)">
        </ion-input>
      </ion-item>
      <ion-item class="">
        <ion-label position="stacked" for="login-pw">Password</ion-label>
        <ion-input id="login-pw" placeholder="Enter password" type="password" class="mt-7" name="password" ngModel
          #password="ngModel" required autocorrect="off" spellcheck="false" (keydown.enter)="onSubmit(loginForm)">
        </ion-input>
      </ion-item>
      <div class="w-100 text-align-right pt-10 mb-30">
        <ion-text class="bodyS-txt pointer m-0 " color="highlight" routerLink="/forgot-password">
          Forgot Password?
        </ion-text>
      </div>

      <ion-button class="loginBox-button mb-30 {{ loginForm.valid ? 'button-solid' : 'button-outline'}}" expand="block"
      type="submit" color="primary" [disabled]="!loginForm.valid" (click)="onSubmit(loginForm)">Login</ion-button>

      <ion-label class="subtitle ion-no-margin column">
        <ion-text *ngIf="!isDesktop" class="text-center mb-5 body2-txt" color="gray80">Version: 13.3</ion-text>
        <a href="https://triarqhealth.com/terms-of-use"  class="text-center body2-txt" color="highlight" target="_blank">Terms of
          Use</a>
      </ion-label>


    </div>




  </form>

  <div class="login-copyright">
    © Copyright TRIARQ Health 2022. All Rights Reserved.
  </div>

</div>
