import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-pre-registration-abandoned',
  templateUrl: './pre-registration-abandoned.component.html',
  styleUrls: ['./pre-registration-abandoned.component.scss'],
})
export class PreRegistrationAbandonedComponent  implements OnInit {
  isDesktop: boolean;
  breakpoint: any = window.matchMedia('(min-width: 767px)');
  constructor(public modalController: ModalController, private bsModalRef: BsModalRef) {
    this.isDesktop = !!this.breakpoint.matches;
  }

  ngOnInit(): void {
  }

  // Add Modal
  public opened = false;

  public close(): void {
    this.opened = false;
  }


  public registerForm: FormGroup = new FormGroup({

  })
  onClose() {
    this.bsModalRef.hide();
  }
}
