import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'preferred-pharmacy-popup',
  templateUrl: './preferred-pharmacy-popup.component.html',
  styleUrls: ['./preferred-pharmacy-popup.component.scss'],
})
export class PreferredPharmacyPopupComponent  implements OnInit {
  @ViewChild(TooltipDirective)
  public tooltipDir!: TooltipDirective;

  @Output() closePharmacy: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor( private deviceService: DeviceDetectorService,  private router: Router) {
    this.epicFunction();
   }

  ngOnInit() {}
  
  public pharmacyData = [
    {
      name: '00001',
      address: '205 Street lorem ipsum 132, My Street, Kingston, New York 12401.',
      city: 'Nashik',
      state: 'NY',
      zip: '10001',
      phone: '123-456-7890',
    },
    {
      name: '00002',
      address: '1168 Heavner Court.',
      city: 'Washington DC',
      state: 'NY',
      zip: '12345',
      phone: '123-456-7890',
    },
    {
      name: '00021',
      address: '205 Street lorem ipsum 132, My Street, Kingston, New York 12401.',
      city: 'Washington DC',
      state: 'NY',
      zip: '12345',
      phone: '123-456-7890',
    },
    {
      name: '00001',
      address: '123 Main St',
      city: 'Nashik',
      state: 'NY',
      zip: '10001',
      phone: '123-456-7890',
    },
    {
      name: '00002',
      address: '1168 Heavner Court.',
      city: 'Washington DC',
      state: 'NY',
      zip: '12345',
      phone: '123-456-7890',
    },
    {
      name: '00021',
      address: '1168 Heavner Court.',
      city: 'Washington DC',
      state: 'NY',
      zip: '12345',
      phone: '123-456-7890',
    },
    {
      name: '00001',
      address: '123 Main St',
      city: 'Nashik',
      state: 'NY',
      zip: '10001',
      phone: '123-456-7890',
    },
    {
      name: '00002',
      address: '1168 Heavner Court.',
      city: 'Washington DC',
      state: 'NY',
      zip: '12345',
      phone: '123-456-7890',
    },
    {
      name: '00021',
      address: '1168 Heavner Court.',
      city: 'Washington DC',
      state: 'NY',
      zip: '12345',
      phone: '123-456-7890',
    },
    {
      name: '00001',
      address: '123 Main St',
      city: 'Nashik',
      state: 'NY',
      zip: '10001',
      phone: '123-456-7890',
    },
    {
      name: '00002',
      address: '1168 Heavner Court.',
      city: 'Washington DC',
      state: 'NY',
      zip: '12345',
      phone: '123-456-7890',
    },
    {
      name: '00021',
      address: '1168 Heavner Court.',
      city: 'Washington DC',
      state: 'NY',
      zip: '12345',
      phone: '123-456-7890',
    },
    {
      name: '00001',
      address: '123 Main St',
      city: 'Nashik',
      state: 'NY',
      zip: '10001',
      phone: '123-456-7890',
    },
    {
      name: '00002',
      address: '1168 Heavner Court.',
      city: 'Washington DC',
      state: 'NY',
      zip: '12345',
      phone: '123-456-7890',
    },
    {
      name: '00021',
      address: '1168 Heavner Court.',
      city: 'Washington DC',
      state: 'NY',
      zip: '12345',
      phone: '123-456-7890',
    },
    {
      name: '00001',
      address: '123 Main St',
      city: 'Nashik',
      state: 'NY',
      zip: '10001',
      phone: '123-456-7890',
    },
    {
      name: '00002',
      address: '1168 Heavner Court.',
      city: 'Washington DC',
      state: 'NY',
      zip: '12345',
      phone: '123-456-7890',
    },
    {
      name: '00021',
      address: '1168 Heavner Court.',
      city: 'Washington DC',
      state: 'NY',
      zip: '12345',
      phone: '123-456-7890',
    },
    {
      name: '00001',
      address: '123 Main St',
      city: 'Nashik',
      state: 'NY',
      zip: '10001',
      phone: '123-456-7890',
    },
    {
      name: '00002',
      address: '1168 Heavner Court.',
      city: 'Washington DC',
      state: 'NY',
      zip: '12345',
      phone: '123-456-7890',
    },
    {
      name: '00021',
      address: '1168 Heavner Court.',
      city: 'Washington DC',
      state: 'NY',
      zip: '12345',
      phone: '123-456-7890',
    },
    {
      name: '00001',
      address: '123 Main St',
      city: 'Nashik',
      state: 'NY',
      zip: '10001',
      phone: '123-456-7890',
    },
    {
      name: '00002',
      address: '1168 Heavner Court.',
      city: 'Washington DC',
      state: 'NY',
      zip: '12345',
      phone: '123-456-7890',
    },
    {
      name: '00021',
      address: '1168 Heavner Court.',
      city: 'Washington DC',
      state: 'NY',
      zip: '12345',
      phone: '123-456-7890',
    },
    {
      name: '00001',
      address: '123 Main St',
      city: 'Nashik',
      state: 'NY',
      zip: '10001',
      phone: '123-456-7890',
    },
    {
      name: '00002',
      address: '1168 Heavner Court.',
      city: 'Washington DC',
      state: 'NY',
      zip: '12345',
      phone: '123-456-7890',
    },
    {
      name: '00021',
      address: '1168 Heavner Court.',
      city: 'Washington DC',
      state: 'NY',
      zip: '12345',
      phone: '123-456-7890',
    },
  ]

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (
      (element.nodeName === "TD" || element.className === "k-column-title") &&
      element.offsetWidth < element.scrollWidth
    ) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  closePharmacyPopup(){
    this.closePharmacy.emit(true);
    if(this.isMobile){
      this.router.navigate(['app/info-edit']);
    }
  }
  
  isMobile!: boolean;
  isTablet!: boolean;
  isDesktopDevice!: boolean;
  osType!: string;
  deviceInfo: import("ngx-device-detector").DeviceInfo | undefined;
  isIOS!: boolean ;
  isAndroid!: boolean ;
  epicFunction(){
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    // this.osType = this.deviceInfo.os;
    // console.log(this.deviceInfo);
    // console.log("Mobile" + this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    // console.log("Tablet" + this.isTablet);  // returns if the device us a tablet (iPad etc)
    // console.log("Desktop" + this.isDesktopDevice); // returns if the app is running on a Desktop browser.
    // console.log("OS" + this.osType); // returns if the app is running on a Desktop browser.
    // if(this.isMobile == true && this.osType == 'iOS'){
    //   this.isIOS = true;
    // }
    // if(this.isMobile == true && this.osType == 'Android'){
    //   this.isAndroid = true;
    // }
  }

}
