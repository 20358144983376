<welcome-header></welcome-header>
<div class="mid-container">
  <ion-grid class="mid-comment-border mid-header-section">
    <div class="contentBox">
      <form class="verification-box column">
          <ion-text class="bodyXL-txt-bold mb-10" color="gray100">Get a verification code</ion-text>
         <ion-text class="bodyM-txt-italic mb-20" color="gray80">To protect your health information, we need to send a verification code to your mobile phone.
              </ion-text>
          <div class="ion-text-center ">
            <kendo-maskedtextbox [style.width.px]="170" class="bodyL-txt p-7" (valueChange)="getMobileNumber($event)" color="gray100" [mask]="mask">
            <!-- [readonly]="true" -->
            </kendo-maskedtextbox>
            <kendo-formerror class="bodyM-txt mx-30" *ngIf="iserrorShow">Enter correct mobile no.</kendo-formerror>
          </div>
            <div class="ion-text-center my-20 w-100">
              <ion-button fill="solid" color="ion-color-white" class="filled-icon-btn capitalize w-100" (click)="sendCode()">
                <ion-text>Send Code</ion-text>
                <ion-icon src="../../../../../../../assets/svg/arrow-right.svg" class="ml-5"></ion-icon>
              </ion-button>
            </div>
      </form>
    </div>
  </ion-grid>
</div>
