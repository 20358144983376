import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from '../shared/shared.module';
import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { PreRegistrationAbandonedComponent } from './pages/pre-registration-abandoned/pre-registration-abandoned.component';
import { PreRegistrationEditOrAddInsuranceCardComponent } from './pages/pre-registration-insurance-edit/pre-registration-edit-or-add-insurance-card/pre-registration-edit-or-add-insurance-card.component';
import { PreRegistrationInsuranceEditComponent } from './pages/pre-registration-insurance-edit/pre-registration-insurance-edit.component';
import { PreRegistrationViewInsuranceCardComponent } from './pages/pre-registration-insurance-edit/pre-registration-view-insurance-card/pre-registration-view-insurance-card.component';
import { PreRegistrationPatientInfoEditComponent } from './pages/pre-registration-patient-info-edit/pre-registration-patient-info-edit.component';
import { PreRegistrationPatientInfoFocusComponent } from './pages/pre-registration-patient-info-focus/pre-registration-patient-info-focus.component';
import { PreRegistrationPaymentComponent } from './pages/pre-registration-payment/pre-registration-payment.component';
import { PreRegistrationTimeoutWarningComponent } from './pages/pre-registration-timeout-warning/pre-registration-timeout-warning.component';
import { PreRegistrationWelcomeComponent } from './pages/pre-registration-welcome/pre-registration-welcome.component';
import { LogoutMessagesComponent } from './pages/timeout/logout-messages/logout-messages.component';
import { ClinicDashboardComponent } from './tablet-in-clinic-view/clinic-dashboard/clinic-dashboard.component';
import { ClinicDashboardHeaderComponent } from './tablet-in-clinic-view/clinic-dashboard/component/clinic-dashboard-header/clinic-dashboard-header.component';
import { ClinicDashboardPatientListComponent } from './tablet-in-clinic-view/clinic-dashboard/component/clinic-dashboard-patient-list/clinic-dashboard-patient-list.component';
import { ClinicDashboardProviderListComponent } from './tablet-in-clinic-view/clinic-dashboard/component/clinic-dashboard-provider-list/clinic-dashboard-provider-list.component';
import { ClinicDashboardSearchNoresultComponent } from './tablet-in-clinic-view/clinic-dashboard/component/clinic-dashboard-search-noresult/clinic-dashboard-search-noresult.component';
import { ClinicFooterComponent } from './tablet-in-clinic-view/clinic-footer/clinic-footer.component';
import { ClinicLockScreenComponent } from './tablet-in-clinic-view/clinic-lock-screen/clinic-lock-screen.component';
import { ClinicPatientLockScreenComponent } from './tablet-in-clinic-view/clinic-patient-lock-screen/clinic-patient-lock-screen.component';
import { PreRegistrationPatientFormComponent } from './pages/pre-registration-patient-form/pre-registration-patient-form.component';
import { ClinicDashboardPreRegisteredPatientComponent } from './tablet-in-clinic-view/clinic-dashboard/component/clinic-dashboard-pre-registered-patient/clinic-dashboard-pre-registered-patient.component';
import { ClinicDashboardPracticeListComponent } from './tablet-in-clinic-view/clinic-dashboard/component/clinic-dashboard-practice-list/clinic-dashboard-practice-list.component';
import { PageNotFoundComponent } from '../alt-views/login/page-not-found/page-not-found.component';
import { ShowWarningComponent } from '../alt-views/login/show-warning/show-warning.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import { InputMaskModule } from '@ngneat/input-mask';
import { PatientAppointmentComponent } from './self-scheduling/components/patient-appointment/patient-appointment.component';
import { SelfSchedulingHeaderComponent } from './self-scheduling/shared/self-scheduling-header/self-scheduling-header.component';
import { PatientInformationComponent } from './self-scheduling/components/patient-information/patient-information.component';
import { PatientAppointmentTimeComponent } from './self-scheduling/components/patient-appointment-time/patient-appointment-time.component';
import { ConfirmAppointmentComponent } from './self-scheduling/components/confirm-appointment/confirm-appointment.component';
import { ConfirmPatientComponent } from './tablet-in-clinic-view/confirm-patient/confirm-patient.component';
import { PatientInformationPopupComponent } from './self-scheduling/components/patient-information-popup/patient-information-popup.component';
import { ClinicWarningComponent } from './tablet-in-clinic-view/clinic-warning/clinic-warning.component';
import { ChooseTimePopupComponent } from './self-scheduling/components/choose-time-popup/choose-time-popup.component';
import { PatientInsurancePopupComponent } from './self-scheduling/components/patient-insurance-popup/patient-insurance-popup.component';
import { BookAppointmentComponent } from './self-scheduling/components/book-appointment/book-appointment.component';
import { PatientInsuranceAddEditPopupComponent } from './self-scheduling/components/patient-insurance-popup/patient-insurance-add-edit-popup/patient-insurance-add-edit-popup.component';
import { PatientInsuranceViewPopupComponent } from './self-scheduling/components/patient-insurance-popup/patient-insurance-view-popup/patient-insurance-view-popup.component';
import { ReferProviderPopupComponent } from './pages/refer-provider-popup/refer-provider-popup.component';
import { PrimaryCareProviderPopupComponent } from './pages/primary-care-provider-popup/primary-care-provider-popup.component';
import { PreferredPharmacyPopupComponent } from './pages/preferred-pharmacy-popup/preferred-pharmacy-popup.component';
@NgModule({
  declarations: [MainComponent, PreRegistrationWelcomeComponent,PreRegistrationPatientInfoFocusComponent, PreRegistrationPatientInfoEditComponent,PreRegistrationInsuranceEditComponent,PreRegistrationViewInsuranceCardComponent,PreRegistrationEditOrAddInsuranceCardComponent, LogoutMessagesComponent,PreRegistrationPaymentComponent,PreRegistrationAbandonedComponent,PreRegistrationTimeoutWarningComponent, ClinicLockScreenComponent, ConfirmPatientComponent, ClinicDashboardComponent,ClinicDashboardHeaderComponent,ClinicDashboardProviderListComponent, ClinicDashboardPatientListComponent,ClinicDashboardSearchNoresultComponent, ClinicFooterComponent, ClinicPatientLockScreenComponent, PreRegistrationPatientFormComponent, ClinicDashboardPreRegisteredPatientComponent, ClinicDashboardPracticeListComponent, PageNotFoundComponent, ShowWarningComponent, ClinicWarningComponent, PatientAppointmentComponent, SelfSchedulingHeaderComponent, PatientInformationComponent,PatientInformationPopupComponent, PatientAppointmentTimeComponent, ConfirmAppointmentComponent, ChooseTimePopupComponent, PatientInsurancePopupComponent, BookAppointmentComponent, PatientInsuranceAddEditPopupComponent, PatientInsuranceViewPopupComponent, ReferProviderPopupComponent, PrimaryCareProviderPopupComponent, PreferredPharmacyPopupComponent],
  imports: [
    CommonModule,
    MainRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ReactiveFormsModule,
    MatFormFieldModule, 
    MatInputModule, 
    MatDatepickerModule,
    MatNativeDateModule, 
    InputMaskModule,
    ModalModule.forRoot()
  ]
})
export class MainModule { }
