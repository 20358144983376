<div class="w-100 ">
  <div class="y-scroll">
    <form class="loginBox ion-justify-content-between text-center" >
        <!-- icon -->
        <div class="text-center">
            <ion-img class="loginBox-logo" src="../../../assets/img/logo-color.png"></ion-img>
        </div>
        
  
        <div class="loginBox-otheracc">
            <ion-text >
              <h2>Recover Your account</h2>
            </ion-text>
        </div>
  
        <div class="loginBox-fields mb-10">
          <ion-item class="ion-margin-top mb-20">
              <ion-label position="stacked" for="email" class="gray100">Email Address</ion-label>
              <ion-input id="login-ed" type="email" name="email" placeholder="Enter Email" class="mt-7" ngModel #email="ngModel" required
                  autocorrect="off" spellcheck="false"></ion-input>
          </ion-item>
        </div>
        <ion-button fill="solid" expand="block" color="ion-color-white" class="filled-icon-btn capitalize info-btn login_btn mb-30">
          <ion-text class="">Recover</ion-text>
        </ion-button>
        <div class="text-center">
          <ion-label class="subtitle ion-no-margin">
            <a href="" target="_blank">Back</a>
          </ion-label>
        </div>
    </form>
  
    <div class="login-copyright">
        © Copyright TRIARQ Health 2022. All Rights Reserved.
    </div>
  
  </div>
</div>