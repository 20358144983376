
<div *ngIf="!isDesktop">
  <self-scheduling-header></self-scheduling-header>
</div>
<div class="main-div pb-20">
  <form class="k-form " [formGroup]="registerForm">
  <div class="column px-30 pt-20 patientInfoMobileHeading" *ngIf="!isDesktop">
    <ion-text class="bodyL-txt-large text-blue-color ion-align-items-center d-flex">
      <span class="bodyXS-txt-bold text-blue-color stepper-circle mr-10">1</span>
      Patient information
    </ion-text>
  </div>
  <div class="note-txt text-align-center pt-15">
    <ion-text class="bodyM-txt-italic" color="gray80">
      Please fill in all of this information
    </ion-text>
  </div>
  <div class="update-info px-30">
      <div class="patient-info">
        <div class="row-new">
          <div class="col-6-sm col-12-xs mt-10">
            <div  [ngClass] ="isMobile == true? '' : 'mr-10' ">
              <label class="k-form" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">First
                Name</label>
              <kendo-textbox class="bodyM-txt txt-field ion-color-gray100" color="gray100" placeholder="*First Name"
                formControlName="lname">
              </kendo-textbox>
            </div>
            
          </div>
          <div class="col-6-sm col-12-xs mt-10">
            <div [ngClass] ="isMobile == true? '' : 'ml-10' ">
              <label class="k-form" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">Preferred Name
                (Optional)</label>
              <kendo-textbox class="bodyM-txt txt-field ion-color-gray100" color="gray100" placeholder="Preferred Name"
                formControlName="mname">
              </kendo-textbox>
            </div>
          </div>
        </div>
        <div class="mt-10 col-12-sm">
          <label class="k-form" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">Last Name</label>
          <kendo-textbox class="bodyM-txt txt-field ion-color-gray100" color="gray100" placeholder="*Last Name"
            formControlName="fname">
          </kendo-textbox>
        </div>
        <div class="mt-10">
          <label class="k-form" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">*Address</label>
          <kendo-textbox class="bodyM-txt txt-field ion-color-gray100" color="gray100" placeholder="Address 1"></kendo-textbox>
          <div class="mt-10">
            <kendo-textbox class="bodyM-txt txt-field ion-color-gray100" color="gray100" placeholder="Address 2"></kendo-textbox>
          </div>
        </div>
        <div class="row-new">
          <div class="dateWidth col-6-sm col-12-xs mt-10">
            <div [ngClass] ="isMobile == true? '' : 'mr-10' ">
              <label class="k-form" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">Date of
                birth</label>
              <kendo-datepicker class="txt-field" calendarType="classic" [value]="value" [adaptiveMode]="adaptiveMode">
              </kendo-datepicker>
            </div>
            
          </div>

          <div class="col-6-sm col-12-xs mt-10 btn-radio">
            <div [ngClass] ="isMobile == true? '' : 'ml-10' ">
              <label class="k-form" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">SEX</label>
              <div class="d-flex mt-8">
                <div class="d-flex checkBoxContainer">
                  <input type="radio" kendoRadioButton />
                  <div class="d-flex align-items-center">
                    <ion-text class="ml-5 mr-25 bodyM-txt" color="gray100">Male</ion-text>
                  </div>
                </div>
                <div class="d-flex checkBoxContainer">
                  <input type="radio" kendoRadioButton />
                  <div class="d-flex align-items-center">
                    <ion-text class="bodyM-txt ml-5" color="gray100">Female</ion-text>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>

        <div class="mt-10 col-12-sm">
          <label class="k-form" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">Email Address</label>
          <kendo-textbox class="bodyM-txt txt-field ion-color-gray100" color="gray100" placeholder="*Email Address"
            formControlName="fname">
          </kendo-textbox>
        </div>
        <div class="row-new">
          <div class="col-6-sm col-12-xs mt-10">
            <div [ngClass] ="isMobile == true? '' : 'mr-10' ">
              <label class="k-form" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">primary phone </label>
            <kendo-textbox class="bodyM-txt txt-field ion-color-gray100" color="gray100" placeholder="(999)999-9999"
              formControlName="lname">
            </kendo-textbox>
            </div>            
          </div>
          <div class="col-6-sm col-12-xs mt-10">
            <div [ngClass] ="isMobile == true? '' : 'ml-10' ">
              <label class="k-form" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">phone type</label>
          
              <kendo-combobox placeholder="*Phone type" [suggest]="true" class="bodyM-txt txt-field">
              </kendo-combobox>
            </div>
          </div>
        </div>
        <div class="row mt-10 mb-10">
          <div class="col-6-sm col-12-xs">
            <div [ngClass] ="isMobile == true? '' : 'mr-10' ">
              <label class="k-form" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">ZIP Code</label>
            <kendo-textbox class="bodyM-txt txt-field ion-color-gray100" color="gray100" placeholder="*ZIP Code">
            </kendo-textbox>
            </div>            
          </div>
          <!-- <div class="mr-20 col-6-md">
            <label class="k-form" class="bodyS-txt ion-text-uppercase ion-color-gray80" color="gray80">ZIP Code</label>
            <kendo-textbox class="bodyM-txt txt-field ion-color-gray100" color="gray100" placeholder="*ZIP Code"
              formControlName="lname">
            </kendo-textbox>
          </div> -->
        
        </div>

      </div>
  </div>
</form>
</div>
<ion-footer *ngIf="!isDesktop">
  <div class="px-30 py-10 row w-100 ion-justify-content-center align-items-center ion-bg-unfocused-color">
    <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn mr-15"
      routerLink="/app/patient-scheduling">
      <ion-text>Cancel</ion-text>
    </ion-button>
    <ion-button fill="solid" expand="block" color="ion-color-white" class="filled-icon-btn capitalize info-btn" routerLink="/app/patient-scheduling">
      <ion-text>Save</ion-text>
    </ion-button>
  </div>
</ion-footer>