import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';


@Component({
  selector: 'otp-login',
  templateUrl: './otp-login.component.html',
  styleUrls: ['./otp-login.component.scss'],
})
export class OtpLoginComponent  implements OnInit {
  isDesktop: boolean;
  ismobile:boolean;
  breakpoint: any = window.matchMedia('(min-width: 768px)');
  mobileview: any = window.matchMedia('(max-width: 768px)');
  mask: string = '(000)-000-0000';
  iserrorShow:boolean = false;

  constructor(private router: Router) {
    this.isDesktop = !!this.breakpoint.matches;
    this.ismobile = !!this.mobileview.matches;
   }
  ngOnInit() {}


  getMobileNumber(event: any){
  }

  sendCode(){
      this.router.navigateByUrl('/verify');
  }
}
