import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'clinic-dashboard-provider-list',
  templateUrl: './clinic-dashboard-provider-list.component.html',
  styleUrls: ['./clinic-dashboard-provider-list.component.scss'],
})
export class ClinicDashboardProviderListComponent  implements OnInit {

  isNoResult:boolean =  false;
  constructor() { }

  ngOnInit() {}
  showNoResult(){
    this.isNoResult = true;
  }

}
