<div class="pre-registration-patient-main main_container" style="margin: 0 auto;">
    <clinic-dashboard-header></clinic-dashboard-header>
    <div class="pre-registration-patient  px-15 py-10">
        <div class="grid-col-3 p-10 mb-10 pre-registration-top-row">
            <div>
                <input type="checkbox" kendoCheckBox (click)="checkAll()" [checked]="markChecked"/>
            </div>
            <div class="">
                <ion-text class="bodyS-txt-bold uppercase" color="gray80">Existing Data</ion-text>
            </div>
            <div class="">
                <ion-text class="bodyS-txt-bold uppercase" color="gray80">Updated Data</ion-text>
            </div>
        </div>
        <div class="mb-10 text-right">
            <ion-text class="bodyS-txt text-HLblue-color pointer" (click)="showUpdated()">{{showText}}</ion-text>
        </div>
        <div class="borderAllGray20 border-radius05 mb-15">
            <div class="py-20 px-10 bg-gray05">
                <ion-text class="h5" color="gray80">Patient Details</ion-text>
            </div>
            <div>
                <div class="p-10">
                    <ion-text class="h5" color="gray80">Name</ion-text>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05"  *ngIf="!isUpdated"> 
                    <div></div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">First Name</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">Sledge</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">Sledge</ion-text>
                    </div>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05" *ngIf="!isUpdated">
                    <div></div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Middle Name</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">R</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">R</ion-text>
                    </div>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05">
                    <div>
                        <input type="checkbox" kendoCheckBox [checked]="markChecked" />
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Last Name</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">Magg</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt-bold" color="gray100">Maggie</ion-text>
                    </div>
                </div>
            </div>
            <div>
                <div class="p-10 borderTopGray20">
                    <ion-text class="h5" color="gray80">Address</ion-text>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05">
                    <div>
                        <input type="checkbox" kendoCheckBox [checked]="markChecked" />
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Address 1</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">12 Cunningham</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt-bold" color="gray100">1234 Cunningham Court</ion-text>
                    </div>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05">
                    <div>
                        <input type="checkbox" kendoCheckBox  [checked]="markChecked"/>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Address 2</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">Goldberg</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt-bold" color="gray100">Goldberg new</ion-text>
                    </div>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05"  *ngIf="!isUpdated">
                    <div></div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">City</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">Bloomfield Township</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">Bloomfield Township</ion-text>
                    </div>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05"  *ngIf="!isUpdated">
                    <div></div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Zip</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">48302</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">48302</ion-text>
                    </div>
                </div>
            </div>
            
            <div>
                <div class="p-10 borderTopGray20">
                    <ion-text class="h5" color="gray80">Contact Details</ion-text>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05"  *ngIf="!isUpdated">
                    <div>  
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Mobile</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">8975412564</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">8975412564</ion-text>
                    </div>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05"  *ngIf="!isUpdated">
                    <div></div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Phone</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80"></ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt"></ion-text>
                    </div>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05"  *ngIf="!isUpdated">
                    <div></div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Email address</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">maggie@mailnator.com</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">maggie@mailnator.com</ion-text>
                    </div>
                </div>
            </div>
            <div>
                <div class="p-10 borderTopGray20">
                    <ion-text class="h5" color="gray80">Emergency Contact</ion-text>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05">
                    <div>
                        <input type="checkbox" kendoCheckBox [checked]="markChecked" />
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Name</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">Cynthia</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt-bold"  color="gray80">Cynthia</ion-text>
                    </div>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05"  *ngIf="!isUpdated">
                    <div></div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Phone</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">7541248632</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt"  color="gray80">7541248632</ion-text>
                    </div>
                </div>
            </div>
        </div>
        <div class="borderAllGray20 border-radius05 mb-15">
            <div class="py-20 px-10 bg-gray05">
                <ion-text class="h5" color="gray80">Insurance Details</ion-text>
            </div>
            <div>
                <div class="p-10 ">
                    <ion-text class="h5" color="gray80">Primary Insurance</ion-text>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05"  *ngIf="!isUpdated">
                    <div></div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Name of insurer</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">Blue Cross of Michigan PPO</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">Blue Cross of Michigan PPO</ion-text>
                    </div>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05">
                    <div>
                        <input type="checkbox" kendoCheckBox [checked]="markChecked" />
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Member number</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">569684165</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt-bold" color="gray100">664784186</ion-text>
                    </div>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05" >
                    <div>
                        <input type="checkbox" kendoCheckBox [checked]="markChecked" />
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Group number</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">15685</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt-bold" color="gray100">15643</ion-text>
                    </div>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05"  *ngIf="!isUpdated">
                    <div></div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Policy holder</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">self</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">self</ion-text>
                    </div>
                </div>
            </div>
            <div>
                <div class="p-10 borderTopGray20">
                    <ion-text class="h5" color="gray80">Secondary Insurance</ion-text>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05">
                    <div>
                        <input type="checkbox" kendoCheckBox  [checked]="markChecked" />
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Name of insurer</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">12 Cunningham</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt-bold" color="gray100">1234 Cunningham Court</ion-text>
                    </div>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05">
                    <div>
                        <input type="checkbox" kendoCheckBox [checked]="markChecked" />
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Member number</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">569684165</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt-bold" color="gray100">664784186</ion-text>
                    </div>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05">
                    <div>
                        <input type="checkbox" kendoCheckBox [checked]="markChecked" />
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Group number</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">15685</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt-bold" color="gray100">15643</ion-text>
                    </div>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05"  *ngIf="!isUpdated">
                    <div></div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Policy holder</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">Someone Else</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">Someone Else</ion-text>
                    </div>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05"  *ngIf="!isUpdated">
                    <div></div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Subscriber name</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">Boseman Larry</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">Boseman Larry</ion-text>
                    </div>
                </div>
                <div class="grid-col-4 p-10 borderBottomGray05"  *ngIf="!isUpdated">
                    <div></div>
                    <div>
                        <ion-text class="bodyS-txt" color="primary100">Date of birth</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">3-27-2000</ion-text>
                    </div>
                    <div>
                        <ion-text class="bodyS-txt" color="gray80">3-27-2000</ion-text>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pre-registration-patient-footer p-20 borderTopGray20 text-center ion-header-bg-color" >
        <ion-button fill="clear"  color="highlight" class="filled-clear-btn capitalize" routerLink="/clinic-dashboard">
            <ion-text>Close</ion-text>
        </ion-button>
        <ion-button fill="outline"  color="highlight" class="filled-outline-btn capitalize mx-20">
            <ion-text>Reject</ion-text>
        </ion-button>
        <ion-button fill="solid"  color="ion-color-white" class="filled-icon-btn capitalize">
            <ion-text>Accept</ion-text>
        </ion-button>
    </div>
</div>