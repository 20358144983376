<div class="patient_form_content">
  <div class="text-center w-100 p-10 borderBottom" *ngIf="!isDesktop">
    <ion-text class="h3-Montserrat text-blue-color">{{MobilePatientformTitle}}</ion-text>
  </div>
  <div class="patient_form"  id="formcontainer">
    <div class="patient_health_history" *ngIf="isPatientHealthHistory">

      <div class="mb-10 px-30">
        <div class="column pt-20">
          <ion-text class="h4-M text-blue-color mb-5">Do you have or have you ever had:</ion-text>
          <ion-text class="bodyS-txt mb-5" color="gray100">Check any that are “YES”</ion-text>
        </div>
        <div>
          <div class="d-flex checkBoxContainer">
            <input type="checkbox" kendoCheckBox  (change)="showBoldText($event)"/>
            <div class="d-flex align-items-center">
              <ion-text class="ml-5" color="gray100" [ngClass]="isBold == true ? 'bodyM-txt-bold' : 'bodyM-txt'">Hospitalization or injury</ion-text>
            </div>
          </div>
          <div class="d-flex checkBoxContainer">
            <input type="checkbox" kendoCheckBox />
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray100">Heart problems, or cardiac stent within the last six months</ion-text>
            </div>
          </div>
          <div class="d-flex checkBoxContainer">
            <input type="checkbox" kendoCheckBox  />
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray100">History of infective endocarditis</ion-text>
            </div>
          </div>
          <div class="d-flex checkBoxContainer">
            <input type="checkbox" kendoCheckBox  />
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray100">Artificial heart valve, repaired heart defect (PFO)</ion-text>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-15 px-30">
        <div class="column pt-20">
          <ion-text class="h4-M text-blue-color mb-5">When was your most recent physical exam?</ion-text>
        </div>
        <div class="d-flex">
          <div class="dateWidth mr-15">
            <kendo-datepicker calendarType="classic" [value]="value"  [adaptiveMode]="adaptiveMode" >
            </kendo-datepicker>
          </div>
          <div class="d-flex align-items-center checkBoxContainer">
            <input type="checkbox" kendoCheckBox  />
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray80">I’m not sure</ion-text>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-15 px-30">                       
        <div class="column pt-20">
          <ion-text class="h4-M text-blue-color mb-5">Describe any current medical treatment, impending surgery, genetic/developmental delay, or other treatment that may possibly affect your visit today.</ion-text>
        </div>
        <div class="pt-10">
          <ion-textarea rows="4" cols="20" placeholder="Type answer here" class="bodyS-txt-italic"></ion-textarea>
        </div>
      </div>
      <div class="pb-15 px-30" [ngClass]="isDesktop == true ? 'bg-white': 'bg-required' ">
        <div class="column pt-20">
          <ion-text class="h4-M text-blue-color mb-5">What is your estimate of your general health? <span class="h4-M ion-color-error100">*</span></ion-text>
          <ion-text class="bodyXS-txt-bold  ion-color-error100" *ngIf="!isDesktop">Required</ion-text>
        </div>
        <div>
          <div class="d-flex checkBoxContainer">
            <input type="radio" kendoRadioButton (change)="showBoldText($event)"/>
            <div class="d-flex align-items-center">
              <ion-text class="ml-5" color="gray100" [ngClass]="isBold == true ? 'bodyM-txt-bold' : 'bodyM-txt'">Excellent</ion-text>
            </div>
          </div>
          <div class="d-flex checkBoxContainer">
            <input type="radio" kendoRadioButton />
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray100">Good</ion-text>
            </div>
          </div>
          <div class="d-flex checkBoxContainer">
            <input type="radio" kendoRadioButton />
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray100">Fair</ion-text>
            </div>
          </div>
          <div class="d-flex checkBoxContainer">
            <input type="radio" kendoRadioButton />
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray100">Poor</ion-text>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-20 px-30">                       
        <div class="column pt-20">
          <ion-text class="h4-M text-blue-color mb-5">Are you interested in receiving a flu vaccination shot during your visit today?</ion-text>
        </div>
        <div class="">
          <kendo-combobox class="bodyM-txt " [data]="optionList" color="gray100"></kendo-combobox>
        </div>
      </div>
    </div>
    <div class="family_medical_history" *ngIf="isFamilyHealthHistory">
      <div class="mb-10 px-30">
        <div class="column pt-20">
          <ion-text class="h4-M text-blue-color mb-5 line-20">Has anyone in your family had any of the following conditions?</ion-text>
          <ion-text class="bodyS-txt mb-5" color="gray100">Check if yes and indicate relationship to you</ion-text>
        </div>
        <div class="">
          <div class="checkBoxContainer">
            <div class="d-flex align-items-start">
              <input type="checkbox" kendoCheckBox (change)="showTextbox($event)"/>
              <div class="w-100">
                <div class=""><ion-text class="bodyM-txt ml-5" [ngClass]="isBold == true ? 'bodyM-txt-bold' : 'bodyM-txt'" color="gray100">Acid Reflux</ion-text></div>
                <div class="mt-10" *ngIf="isTextbox">
                  <kendo-textbox class="bodyM-txt" placeholder="Type relationship to you here"color="gray100" ></kendo-textbox>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center checkBoxContainer">
            <input type="checkbox" kendoCheckBox/>
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray100">Alcohol Addiction</ion-text>
            </div>
          </div>
          <div class="d-flex align-items-center checkBoxContainer">
            <input type="checkbox" kendoCheckBox  />
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray100">Allergy Problems</ion-text>
            </div>
          </div>
          <div class="d-flex align-items-center checkBoxContainer">
            <input type="checkbox" kendoCheckBox  />
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray100">Anemia</ion-text>
            </div>
          </div>
          <div class="d-flex align-items-center checkBoxContainer">
            <input type="checkbox" kendoCheckBox  />
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray100">Anxiety</ion-text>
            </div>
          </div>
          <div class="d-flex align-items-center checkBoxContainer">
            <input type="checkbox" kendoCheckBox  />
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray100">Artery/Vein Problems</ion-text>
            </div>
          </div>
          <div class="d-flex align-items-center checkBoxContainer">
            <input type="checkbox" kendoCheckBox  />
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray100">Arthritis</ion-text>
            </div>
          </div>
          <div class="d-flex align-items-center checkBoxContainer">
            <input type="checkbox" kendoCheckBox  />
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray100">Asthma</ion-text>
            </div>
          </div>
          <div class="d-flex align-items-center checkBoxContainer">
            <input type="checkbox" kendoCheckBox  />
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray100">Autoimmune Disease</ion-text>
            </div>
          </div>
          <div class="d-flex align-items-center checkBoxContainer">
            <input type="checkbox" kendoCheckBox  />
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray100">Bipolar Disorder</ion-text>
            </div>
          </div>
          <div class="d-flex align-items-center checkBoxContainer">
            <input type="checkbox" kendoCheckBox  />
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray100">Bladder Irritability</ion-text>
            </div>
          </div>
          <div class="d-flex align-items-center checkBoxContainer">
            <input type="checkbox" kendoCheckBox  />
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray100">Blood Clots</ion-text>
            </div>
          </div>
          <div class="d-flex align-items-center checkBoxContainer">
            <input type="checkbox" kendoCheckBox  />
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray100">Cancer</ion-text>
            </div>
          </div>
          <div class="d-flex align-items-center checkBoxContainer">
            <input type="checkbox" kendoCheckBox  />
            <div class="d-flex align-items-center">
              <ion-text class="bodyM-txt ml-5" color="gray100">Cataracts</ion-text>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="consent_form" *ngIf="isConsentForm">
      <div class="mb-10 px-30">
        <div class="column pt-20">
          <ion-text class="h4-M text-blue-color mb-5 line-20">Please read the following terms. Check the box at the bottom and type your name to agree to these terms.</ion-text>
        </div>
        <div class="p-10">
          <ul class="mainList">
            <li class="bodyM-txt mb-5">I [PATIENT NAME] give permission for [PRACTICE NAME] to give me medical treatment.</li>
            <li class="bodyM-txt mb-5">I give permission for [PRACTICE NAME] to prescribe medication based on my symptoms and diagnosis. I agree to only take the medication as prescribed and to not share my medication with anyone else.</li>
            <li class="bodyM-txt mb-5">I have informed [PRACTICE NAME] of all my known allergies</li>
            <li class="bodyM-txt mb-5">I have informed [PRACTICE NAME] of all medications I am currently taking, including prescriptions, over-the-counter remedies, herbal therapies and supplements, aspirin and any recreational or alcohol use.</li>
            <li class="bodyM-txt mb-5">I understand:
              <ul class="subList">
                <li class="bodyM-txt mb-5">I have the right to refuse any procedure or treatment.</li>
                <li class="bodyM-txt mb-5">I have the right to remain silent.</li>
                <li class="bodyM-txt mb-5">I have the right to stray from the truth when my doctor asks about my diet and exercise habits.</li>
                <li class="bodyM-txt mb-5">I have the right to discuss all medical treatments with my clinician.</li>
                <li class="bodyM-txt mb-5">there are no guarantees about the results of any treatment I receive.</li>
                <li class="bodyM-txt">any photos taken during my visit are for treatment or healthcare record purposes. I understand that these photos, images, and/or videos will be the property of the attending healthcare provider and will be protected in accordance with federal and state privacy laws.</li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="checkBoxContainer">
          <div class="d-flex align-items-start">
            <input type="checkbox" kendoCheckBox (change)="showBoldText($event)"/>
            <ion-text class="bodyM-txt ml-5" [ngClass]="isBold == true ? 'bodyM-txt-bold' : 'bodyM-txt'" color="gray100">I agree to the above terms  <span class="h4-M ion-color-error100">*</span></ion-text>
          </div>
        </div>
        <div class="px-5 mb-20">
          <ion-text class="bodyXS-regular uppercase" color="gray80" *ngIf="isDigitalSign">digital signature</ion-text>
          <ion-text class="bodyXS-regular uppercase" color="gray80" *ngIf="!isDigitalSign">full name</ion-text>
          <div class="signWidth">
            <kendo-textbox class="bodyM-txt" placeholder="Type your full name to sign"color="gray100" ></kendo-textbox>
          </div>
        </div>
      </div>
    </div>
    <div class="billing_form" *ngIf="isBillingForm">
      <div class="mb-10 px-30">
        <div class="column pt-20">
          <ion-text class="h4-M text-blue-color mb-5 line-20">Please read the following terms. Check the box at the bottom and type your name to agree to these terms.</ion-text>
        </div>
        <div class="p-10">
          <ul class="mainList">
            <li class="bodyM-txt mb-5">I [PATIENT NAME] allow [PRACTICE NAME] to file for insurance benefits to pay for the care I receive.</li>
            <li class="bodyM-txt mb-5">I authorize [PRACTICE NAME] to accept payment directly from my insurance company for services rendered by the provider.</li>
            <li class="bodyM-txt mb-5">I understand:
              <ul class="subList">
                <li class="bodyM-txt mb-5">[PRACTICE NAME] will have to send my medical record information to my insurance company.</li>
                <li class="bodyM-txt mb-5">I must pay my share (or my dependent’s share, if I am responsible for their medical care) of the costs.</li>
                <li class="bodyM-txt mb-5">[PRACTICE NAME] does not accept monopoly money, or hotels, as payment.</li>
                <li class="bodyM-txt mb-5">I must pay for the cost of these services if my insurance does not pay or I do not have insurance.</li>
              </ul>
            </li>
            <li class="bodyM-txt mb-5">I understand that [PRACTICE NAME] may refer my account to a collections agency in the event that I do not pay my past due within 180 days.</li>
          </ul>
        </div>
        <div class="checkBoxContainer">
          <div class="d-flex align-items-start">
            <input type="checkbox" kendoCheckBox (change)="showBoldText($event)"/>
            <ion-text class="bodyM-txt ml-5" [ngClass]="isBold == true ? 'bodyM-txt-bold' : 'bodyM-txt'" color="gray100">I agree to the above terms  <span class="h4-M ion-color-error100">*</span></ion-text>
          </div>
        </div>
        <div class="px-5 mb-20">
          <ion-text class="bodyXS-regular uppercase" color="gray80" >full name</ion-text>
          <div class="signWidth">
            <kendo-textbox class="bodyM-txt" placeholder="Type your full name to sign"color="gray100" ></kendo-textbox>
          </div>
        </div>
      </div>
    </div>
    <div class="form_summary" *ngIf="isFormSummary">
      <div class="mb-10 px-30">
        <div class="column pt-20 ">
          <ion-text class="h4-M text-blue-color text-center">{{count}} of 4 Forms completed</ion-text>
          <ion-text class="bodyS-txt-italic mb-5" color="gray100">Press a form if you need to change something or press Done in you’re finished</ion-text>
        </div>
        <div>
          <div class="d-flex align-items-center form-icon-container">
            <span class="form-icon-main">
              <ion-icon class="form-icon" src="../../../../assets/svg/consent.svg" ></ion-icon>
              <span class="success-circle" *ngIf="isCompleted">
                <ion-icon name="checkmark-outline" class=" ion-color-white" color=""></ion-icon>
              </span>
            </span>
            <ion-text class="h5-regular ml-15 cursor" color="highlight" (click)="toggleComplete()">Patient health history</ion-text>
          </div>
          <div class="d-flex align-items-center form-icon-container">
            <span class="form-icon-main">
              <ion-icon class="form-icon" src="../../../../assets/svg/consent.svg" ></ion-icon>
              <span class="success-circle">
                <ion-icon name="checkmark-outline" class=" ion-color-white" color=""></ion-icon>
              </span>
            </span>
            <ion-text class="h5-regular ml-15 cursor" color="highlight">Family Medical History</ion-text>
          </div>
          <div class="d-flex align-items-center form-icon-container">
            <span class="form-icon-main">
              <ion-icon class="form-icon" src="../../../../assets/svg/consent.svg" ></ion-icon>
              <span class="success-circle">
                <ion-icon name="checkmark-outline" class=" ion-color-white" color=""></ion-icon>
              </span>
            </span>
            <ion-text class="h5-regular ml-15 cursor" color="highlight">Consent form</ion-text>
          </div>
          <div class="d-flex align-items-center form-icon-container">
            <span class="form-icon-main">
              <ion-icon class="form-icon" src="../../../../assets/svg/consent.svg" ></ion-icon>
              <span class="success-circle">
                <ion-icon name="checkmark-outline" class=" ion-color-white" color=""></ion-icon>
              </span>
            </span>
            <ion-text class="h5-regular ml-15 cursor" color="highlight">Billing agreement</ion-text>
          </div>
        </div>
      </div>
    </div>
  </div>
  <kendo-dialog-actions style="border-top: 1px solid #CCD4D8;" class="boxShadowBottom">
    <div class="w-100 patientFormStepper px-30 py-15">
      <kendo-stepper class="mb-10" [steps]="steps" [(currentStep)]="current">
      </kendo-stepper>
      <div *ngIf="isDesktop">
        <div class="w-100 mt-15 text-center" *ngIf="isFormDone">  
          <ion-button fill="solid" class="filled-icon-btn capitalize btn" (click)="hideFormPopup()">
            <ion-text color="white">Done</ion-text>
          </ion-button>
        </div>
      </div>
      <div *ngIf="!isDesktop">
        <div class="w-100 mt-15 text-center" *ngIf="isFormDone">  
          <ion-button fill="solid" class="filled-icon-btn capitalize btn" routerLink="/app/info-focus">
            <ion-text color="white">Done</ion-text>
          </ion-button>
        </div>
      </div>
      <div class=" w-100 d-flex mt-15 align-items-center justify-content-sb" *ngIf="!isFormDone">  
        <ion-button fill="outline"  color="highlight"class="filled-outline-btn capitalize btn" (click)="prev()" [disabled]="isBackDisable">
          <ion-icon src="../../../../assets/svg/arrow-left.svg" color="gray100"></ion-icon>
          <ion-text  class="ml-10">Back</ion-text>
        </ion-button>
        <ion-button fill="clear" class="filled-clear-btn capitalize btn" (click)="hideFormPopup()">
          <ion-text color="highlight" class="capitalize">skip</ion-text>
        </ion-button>
        <ion-button fill="solid" class="filled-icon-btn capitalize btn" (click)="next()">
          <ion-text color="white">Next</ion-text>
          <ion-icon src="../../../../assets/svg/arrow-right.svg" color="white" class="ml-10"></ion-icon>
        </ion-button>
      </div>
    </div>
  </kendo-dialog-actions>
</div>