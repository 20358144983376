<div>
  <main-header></main-header>
</div>
<div class="pharmacy_provider_main px-25">
  <div class="py-16" *ngIf="isMobile">
    <ion-text class="h3-Montserrat text-blue-color">Pharmacy</ion-text>
  </div>
  <div class="" [ngClass]="isMobile == true ? 'mb-16' : 'py-10 mb-5'">
    <div class="mb-10">
      <kendo-textbox class="bodyM-txt  ion-color-gray100" color="gray100" placeholder="Name">
      </kendo-textbox>
    </div>
    <div class="d-flex">
      <div class="w-100 mr-25">
        <kendo-textbox class="bodyM-txt  ion-color-gray100" color="gray100" placeholder="City">
        </kendo-textbox>
      </div>
      <div class="w-100 mr-25">
        <kendo-textbox class="bodyM-txt  ion-color-gray100" color="gray100" placeholder="State">
        </kendo-textbox>
      </div>
      <div class="w-100">
        <kendo-textbox class="bodyM-txt  ion-color-gray100" color="gray100" placeholder="Zip">
        </kendo-textbox>
      </div>
    </div>
  </div>
  <div class="bor-top refered_provider_grid ">
    <ng-template #template let-anchor>
      <span style="padding: 5px; border-radius: 5px !important;">{{ anchor.nativeElement.innerText }}</span>
    </ng-template>
    <div>
      <kendo-grid class="refered_provider_grid w-100 showScrollOnHover" [data]="pharmacyData"  [pageable]="true"
     [resizable]="true" [reorderable]="true" [columnMenu]="false" [navigable]="true" [pageSize]="10">

     <kendo-grid-column media="(max-width: 768px)">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="column pointer w-100">
          <div class="column mb-10">
            <ion-text class="overline" color="gray80">name</ion-text>
            <ion-text class="bodyM-txt-medium" color="gray100">{{dataItem.name}}</ion-text>
          </div>
          <div class="column mb-10">
            <ion-text class="overline" color="gray80">Address</ion-text>
            <ion-text class="bodyM-txt-medium" color="gray100">{{dataItem.address}}  {{dataItem.city}} {{dataItem.state}} {{dataItem.zip}} </ion-text>
          </div>
          <div class="column w-50">
            <ion-text class="overline" color="gray80">phone no</ion-text>
            <ion-text class="bodyM-txt-medium" color="gray100">{{dataItem.phone}}</ion-text>
          </div>
        </div>
      </ng-template>
    </kendo-grid-column>

      <kendo-grid-column class="ellipse_row text-left pointer" field="name" title="NAME"
          media="(min-width: 768px)" [width]="150">
      </kendo-grid-column>
      <kendo-grid-column  class="ellipse_row text-left pointer"  field="address" title="ADDRESS"
          media="(min-width: 768px)" [width]="150"></kendo-grid-column>
      <kendo-grid-column  class="ellipse_row text-left pointer"  field="city" title="CITY"
          media="(min-width: 768px)" [width]="100"></kendo-grid-column>
      <kendo-grid-column  class="ellipse_row text-left pointer"  field="state" title="STATE"
          media="(min-width: 768px)" [width]="60"></kendo-grid-column>
      <kendo-grid-column  class="ellipse_row text-left pointer"  field="zip" title="ZIP"
          media="(min-width: 768px)" [width]="75"></kendo-grid-column>
      <kendo-grid-column  class="ellipse_row text-left pointer"  field="phone" title="PHONE"
          media="(min-width: 768px)" [width]="130"></kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>
<kendo-dialog-actions class="border mt-20" *ngIf="!isMobile">
  <div class="py-10 row w-100 ion-justify-content-center align-items-center">
    <ion-button fill="outline" color="highlight"
      class="filled-outline-btn capitalize info-btn mr-20 w125" (click)="closePharmacyPopup()">
      <ion-text>Cancel</ion-text>
    </ion-button>
    <div>
      <ion-button  fill="solid" expand="block"
        color="ion-color-white" class="filled-icon-btn capitalize info-btn w125">
        <ion-text>ok</ion-text>
      </ion-button>
    </div>
  </div>
</kendo-dialog-actions>
<ion-footer  class="bg-white borderTop20" *ngIf="isMobile">
  <div class="px-30 py-10 row w-100 ion-justify-content-sb align-items-center heading100 bg-white">
    <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn mr-15 w-100" (click)="closePharmacyPopup()">
      <ion-text>Cancel</ion-text>
    </ion-button>
    <ion-button  fill="solid" expand="block" color="ion-color-white  w-100 "
      class="filled-icon-btn capitalize info-btn">
      <ion-text>Save</ion-text>
    </ion-button>
  </div>
</ion-footer>