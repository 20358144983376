<div class="timeout_warning_dialog">
    <kendo-dialog>
        <div class="timeout_warning_main text-center">
            <div class="mb-10">
                <ion-text class="bodyL-txt-medium" color="gray100">Are you still there?</ion-text>
            </div>
            <div class=" mb-20">
                <ion-text class="bodyS-txt" color="gray80">You’ve been inactive for while. For your security, we will automatically log you out in:</ion-text>
            </div>
            <div class="mb-20">
                <ion-text class="timefont" color="gray60">1 <span class="light-font-weight">min </span>49 <span class="light-font-weight">secs</span></ion-text>
            </div>
            <div class="mb-20">
                <ion-progress-bar color="primary" value="0.7"></ion-progress-bar>
            </div>
            <div class="d-flex ion-align-items-center ion-justify-content-between timeoutlink">
                <!-- Tablet-in-clinic patient-view -->
                    <!-- <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize w-100 mr-10"
                        routerLink="/app/logout-messages" (click)="onClose()">
                        <ion-text>Lock Tablet</ion-text>
                    </ion-button> -->
                <!-- End of Tablet-in-clinic patient-view -->
                <ion-button fill="solid" color="ion-color-white" class="filled-icon-btn capitalize w-100"
                    routerLink="/app/patient-lock" (click)="onClose()">
                    <ion-text>Stay Connected</ion-text>
                </ion-button>
                <ion-text class="mx-5"></ion-text>
                <ion-button fill="outline" color="highlight" class="capitalize filled-outline-btn w-100"
                    routerLink="/app/logout-messages" (click)="onClose()">
                    <ion-text>Log out</ion-text>
                </ion-button>
            </div>

        </div>
    </kendo-dialog>
</div>