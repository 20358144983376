import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  isDesktop: boolean;
  breakpoint: any = window.matchMedia('(min-width: 768px)');

  constructor() {
    this.isDesktop = !!this.breakpoint.matches;
  }
}
