import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-pre-registration-timeout-warning',
  templateUrl: './pre-registration-timeout-warning.component.html',
  styleUrls: ['./pre-registration-timeout-warning.component.scss'],
})
export class PreRegistrationTimeoutWarningComponent  implements OnInit {

  constructor(public modalController: ModalController, private bsModalRef: BsModalRef) { }

  ngOnInit() {}
  onClose() {
    this.bsModalRef.hide();
  }
}
