import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '../alt-views/login/page-not-found/page-not-found.component';
import { ShowWarningComponent } from '../alt-views/login/show-warning/show-warning.component';
import { MainComponent } from './main.component';
import { PreRegistrationAbandonedComponent } from './pages/pre-registration-abandoned/pre-registration-abandoned.component';
import { PreRegistrationEditOrAddInsuranceCardComponent } from './pages/pre-registration-insurance-edit/pre-registration-edit-or-add-insurance-card/pre-registration-edit-or-add-insurance-card.component';
import { PreRegistrationInsuranceEditComponent } from './pages/pre-registration-insurance-edit/pre-registration-insurance-edit.component';
import { PreRegistrationViewInsuranceCardComponent } from './pages/pre-registration-insurance-edit/pre-registration-view-insurance-card/pre-registration-view-insurance-card.component';
import { PreRegistrationPatientInfoEditComponent } from './pages/pre-registration-patient-info-edit/pre-registration-patient-info-edit.component';
import { PreRegistrationPatientInfoFocusComponent } from './pages/pre-registration-patient-info-focus/pre-registration-patient-info-focus.component';
import { PreRegistrationPaymentComponent } from './pages/pre-registration-payment/pre-registration-payment.component';
import { PreRegistrationTimeoutWarningComponent } from './pages/pre-registration-timeout-warning/pre-registration-timeout-warning.component';
import { PreRegistrationWelcomeComponent } from './pages/pre-registration-welcome/pre-registration-welcome.component';
import { LogoutMessagesComponent } from './pages/timeout/logout-messages/logout-messages.component';
import { PatientAppointmentComponent } from './self-scheduling/components/patient-appointment/patient-appointment.component';
import { PatientInformationComponent } from './self-scheduling/components/patient-information/patient-information.component';
import { ClinicPatientLockScreenComponent } from './tablet-in-clinic-view/clinic-patient-lock-screen/clinic-patient-lock-screen.component';
import { PatientInformationPopupComponent } from './self-scheduling/components/patient-information-popup/patient-information-popup.component';
import { PreRegistrationPatientFormComponent } from './pages/pre-registration-patient-form/pre-registration-patient-form.component';
import { PatientAppointmentTimeComponent } from './self-scheduling/components/patient-appointment-time/patient-appointment-time.component';
import { ConfirmAppointmentComponent } from './self-scheduling/components/confirm-appointment/confirm-appointment.component';
import { ChooseTimePopupComponent } from './self-scheduling/components/choose-time-popup/choose-time-popup.component';
import { PatientInsurancePopupComponent } from './self-scheduling/components/patient-insurance-popup/patient-insurance-popup.component';
import { BookAppointmentComponent } from './self-scheduling/components/book-appointment/book-appointment.component';
import { PatientInsuranceAddEditPopupComponent } from './self-scheduling/components/patient-insurance-popup/patient-insurance-add-edit-popup/patient-insurance-add-edit-popup.component';
import { PatientInsuranceViewPopupComponent } from './self-scheduling/components/patient-insurance-popup/patient-insurance-view-popup/patient-insurance-view-popup.component';
import { PreferredPharmacyPopupComponent } from './pages/preferred-pharmacy-popup/preferred-pharmacy-popup.component';
import { PrimaryCareProviderPopupComponent } from './pages/primary-care-provider-popup/primary-care-provider-popup.component';
import { ReferProviderPopupComponent } from './pages/refer-provider-popup/refer-provider-popup.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'welcome',
        component: PreRegistrationWelcomeComponent,
      },
      {
        path: 'info-focus',
        component: PreRegistrationPatientInfoFocusComponent,
      },
      {
        path: 'info-edit',
        component: PreRegistrationPatientInfoEditComponent,
      },
      {
        path: 'insurance-edit',
        component: PreRegistrationInsuranceEditComponent,
      },
      {
        path: 'view-insurance-card',
        component: PreRegistrationViewInsuranceCardComponent,
      },
      {
        path: 'Edit-or-Add-insurance-card',
        component: PreRegistrationEditOrAddInsuranceCardComponent,
      },
      {
        path: 'logout-messages',
        component: LogoutMessagesComponent,
      },
      {
        path: 'patient-lock',
        component: ClinicPatientLockScreenComponent,
      },
      {
        path: 'payment',
        component: PreRegistrationPaymentComponent,
      },
      {
        path: 'Abandoned',
        component: PreRegistrationAbandonedComponent,
      },
      {
        path: 'Timeout-warning',
        component: PreRegistrationTimeoutWarningComponent,
      },
      {
        path: 'patient-form',
        component: PreRegistrationPatientFormComponent,
      },
      {
        path: 'page-not-found',
        component: PageNotFoundComponent,
      },
      {
        path: 'show-warning',
        component: ShowWarningComponent,
      },
      {
        path: 'patient-appointment',
        component: PatientAppointmentComponent,
      },
      {
        path: 'patient-scheduling',
        component: PatientInformationComponent
      },
      {
        path: 'patient-info-popup',
        component: PatientInformationPopupComponent
      },
      {
        path: 'patient-insurance-popup',
        component: PatientInsurancePopupComponent
      },
      {
        path: 'patient-insurance-add-edit-popup',
        component: PatientInsuranceAddEditPopupComponent
      },
      {
        path: 'patient-insurance-view-popup',
        component: PatientInsuranceViewPopupComponent
      },
      {
        path: 'patient-appointment-time',
        component: PatientAppointmentTimeComponent
      },
      {
        path: 'confirm-appointment',
        component: ConfirmAppointmentComponent
      },
      {
        path: 'choose-time',
        component: ChooseTimePopupComponent
      },
      {
        path: 'booked-appointment',
        component: BookAppointmentComponent
      },
      {
        path:'referrals',
        component: ReferProviderPopupComponent,
      },
      {
        path:'physicians',
        component: PrimaryCareProviderPopupComponent,
      },
      {
        path:'pharmacy',
        component: PreferredPharmacyPopupComponent,
      },

    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
