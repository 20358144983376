<div class="confirm_appointment_main scroll_main">
  <div>
    <self-scheduling-header></self-scheduling-header>
  </div>
  <div class="container p-0 ">
    <div class="confirm_appointment_container px-50 py-30 scroll_body">
      <div class="mb-10"  *ngIf="isDesktopDevice || isTablet">
        <ion-text class="h3-Montserrat text-blue-color">Appointment</ion-text>
      </div>
      <div class="mb-20 text-center" *ngIf="isMobile">
        <ion-text class="h3-Montserrat text-blue-color">Confirm Details</ion-text>
      </div>
      <div class="row-new">
        <div class="col-6-lg col-6-md col-12-sm">
          <div class="d-flex mb-10">
            <div class="mr-10">
              <ion-icon class="h3" src="../../../../../assets/svg/user2.svg" color="gray80"></ion-icon>
            </div>
            <div class="column">
              <ion-text class="h4-M" color="gray100">Charlotte “Charlie” Nedermeir</ion-text>
              <ion-text class="bodyM-txt" color="gray60" >63y Female (born 02/13/1954)</ion-text>
            </div>
          </div>
          <div class="d-flex mb-10">
            <div class="mr-10">
              <ion-icon class="h3" src="../../../../../assets/svg/clock.svg" color="gray80"></ion-icon>
            </div>
            <div class="column">
              <ion-text class="h4-M pointer" color="gray100" (click)="openChooseTimePopup()">9:15 AM</ion-text>
              <ion-text class="bodyM-txt" color="gray60" >Please arrive 15 minutes early</ion-text>
            </div>
          </div>
          <div class="d-flex mb-10">
            <div class="mr-10">
              <ion-icon class="h3" src="../../../../../assets/svg/calendar.svg" color="gray80"></ion-icon>
            </div>
            <div class="column">
              <ion-text class="h4-M" color="gray100">Wednesday March 13th, 2024</ion-text>
              <ion-text class="bodyM-txt" color="gray60" >Annual Wellness Exam</ion-text>
            </div>
          </div>
        </div>
        <div class="col-6-lg col-6-md col-12-sm">
          <div class="d-flex mb-10">
            <div class="mr-10">
              <ion-icon class="h3" src="../../../../../assets/svg/doctor.svg" color="gray80"></ion-icon>
            </div>
            <div class="column">
              <ion-text class="h4-M" color="gray100">Dr. John Smithsonian</ion-text>
              <ion-text class="bodyM-txt" color="gray60">Urologist</ion-text>
            </div>
          </div>
          <div class="d-flex mb-10">
            <div class="mr-10">
              <ion-icon class="h3" src="../../../../../assets/svg/map-pin.svg" color="gray80"></ion-icon>
            </div>
            <div class="column">
              <ion-text class="h4-M" color="gray100">Downtown Partners</ion-text>
              <ion-text class="bodyM-txt" color="gray60" >5275 S Main Street, Seattle, WA 85471</ion-text>
            </div>
          </div>
          <div class="d-flex mb-10">
            <div class="mr-10">
              <ion-icon class="h3" src="../../../../../assets/svg/Phone 1.svg" color="gray80"></ion-icon>
            </div>
            <div class="column">
              <ion-text class="h4-M" color="gray100">(734) 455-8888</ion-text>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-10 mt-15">
        <ion-text class="h3-Montserrat text-blue-color">Additional notes</ion-text>
      </div>
      <div class="mb-25 additionalNoteBox tab-mb-20">
        <ion-textarea rows="3" cols="20" placeholder="Anything you’d like the office to know?" class="bodyL-txt-italic w-100" color="gray60"></ion-textarea>
      </div>
      <div class="mt-20 mb-10">
        <ion-text class="h3-Montserrat text-blue-color">Message from our office</ion-text>
      </div>
      <div class="mb-20">
        <ion-text class="font20-regular d-inline-block mb-10" color="gray100">We ask all of our patients to arrive 15 minutes before their appointment to ensure timely service. </ion-text>
        <ion-text class="font20-regular" color="gray100">Please also bring your drivers license, or other official ID card, your insurance card, and a list of any medications you currently take.</ion-text>
      </div>
      <div class="mt-20 mb-20 tab-mb-15">
        <div class="help_alert_main bg-cool-gray d-flex align-items-center">
          <div class="mr-15">
            <ion-icon class="bodyXXL-txt questionIcon" src="../../../../../assets/svg/question_circle2.svg"></ion-icon>
          </div>
          <div class="column">
            <ion-text class="bodyL-txt text-blue-color mb-5">What happens when I hit <b>Book Appointment?</b></ion-text>
            <ion-text class="bodyM-txt" color="gray100">Our office will be notified and you will receive a confirmation email and/or text message.</ion-text>
          </div>
        </div>
      </div>
      <div class="row-new " [ngClass]="isTablet == true ? 'captcha_tablet' : ''">
        <div class="col-7-lg col-6-md col-12-sm"></div>
        <div class="col-5-lg col-6-md col-12-sm captach_sm_grid">
          <div class="captach_main d-flex align-items-center justify-content-sb">
            <div class="d-flex align-items-center">
              <input type="checkbox" kendoCheckBox (change)="onChange($event)" />
              <div class="d-flex align-items-center">
                <ion-text class="bodyS-txt-medium ml-15" color="black">I’m not a robot</ion-text>
              </div>
            </div>
            <div class="text-center">
              <img class="d-inline-block" src="../../../../../assets/svg/captcha.svg">
              <ion-text class="term_condition_txt pointer">Privacy - Terms</ion-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white">
    <div class="schedule-footer">
      <div class="w-100 scheduleStepper px-30 py-20" style="border-top: 1px solid #CCD4D8;">
        <kendo-stepper class="" [steps]="steps" [(currentStep)]="current">
        </kendo-stepper>
        <div class="w-100 text-right d-flex align-items-center justify-content-sb mt-15">
          <ion-button fill="outline" class="filled-outline-btn capitalize btn m-0" [ngClass]="isMobile == true ? 'w-100 mr-15' : ''" (click)="prev()">
            <ion-icon src="../../../../assets/svg/arrow-left.svg" color="white" class="mr-10"></ion-icon>
            <ion-text color="highlight">{{btn1text}}</ion-text>
          </ion-button>
          <ion-button fill="solid" class="filled-icon-btn capitalize btn m-0"  [ngClass]="isMobile == true ? 'w-100' : ''" (click)="next()">
            <ion-text color="white">{{btn2text}}</ion-text>
            <ion-icon src="../../../../assets/svg/arrow-right.svg" color="white" class="ml-10"></ion-icon>
          </ion-button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="choose_time_popup">
  <kendo-dialog *ngIf="isChooseTimePopup">
    <choose-time-popup (isChooseTimePopupClose)="closeChooseTimePopup($event)"></choose-time-popup>
  </kendo-dialog>
</div>