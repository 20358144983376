<welcome-header></welcome-header>
<div class="mid-container bgGray">
  <ion-grid class="mid-comment-border mid-header-section">
    <div class="contentBox"> 
      <div class="column loggedout-content align-items-center">
        <ion-text class="h1-Montserrat" color="gray100">You’re logged out</ion-text>
        <ion-text class="h1-Montserrat" color="gray100">Your session timed out to protect your information</ion-text>

        <ion-text class="bodyXL-txt ion-color-gray100 py-50 bodyL-txt-mobile middle-txt"><span class="just">Just </span> <span class="bodyXL-txt-bold bodyL-txt-bold-mobile">follow the original
            link</span> you were sent to continue the pre-registration process.</ion-text>
        <ion-text class="bodyXL-txt ion-color-highlight bodyL-txt-bold-mobile pointer" *ngIf="!opened" (click)="open()">What if I don’t have the
          link?</ion-text>
      </div>
    </div>

    <!-- <div class="contentBox">
      <div class="column loggedout-content align-items-center">
        <ion-text class="h1 ion-color-gray100 h3-M-mobile">You're logged out</ion-text>
        <ion-text class="bodyXL-txt ion-color-gray100 py-50 bodyL-txt-mobile middle-txt"><span class="bodyXL-txt-bold">Follow the original link</span> you were sent to continue the pre-registration process.</ion-text>
        <ion-text class="bodyXL-txt ion-color-highlight bodyL-txt-bold-mobile" *ngIf="!opened" (click)="open()">What if I don’t have the
          link?</ion-text>
      </div>
    </div> -->
  </ion-grid>
</div>

<div class="note-popup">
  <kendo-dialog *ngIf="opened" (close)="close()" class="kendo-dialog" [minWidth]="250" [width]="400">
    <kendo-dialog-titlebar>
    </kendo-dialog-titlebar>
    <div>
      <ion-text class="h4-M ion-color-gray80 bodyM-txt-mobile"><span>To get a new link</span> to pre-register for your appointment, contact your provider:</ion-text>
    </div>
    <div class="column mt-20 px-72 popup-txt">
      <ion-text class="bodyXL-txt ion-color-gray80">Downtown Clinic</ion-text>
      <ion-text class="bodyL-txt ion-color-gray80 py-10">2699 North Main Street Ann Arbor, MI 48103</ion-text>
      <ion-text class="bodyL-txt ion-color-gray80 pb-34 hypertxt pointer"><a href="tel:+734455-8888">(734) 455-8888</a></ion-text>
    </div>

  </kendo-dialog>
</div>