import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StepType } from '@progress/kendo-angular-layout';
import { PreRegistrationAbandonedComponent } from '../pre-registration-abandoned/pre-registration-abandoned.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PreRegistrationTimeoutWarningComponent } from '../pre-registration-timeout-warning/pre-registration-timeout-warning.component';
import { Platform } from '@ionic/angular';
import { PreRegistrationPaymentComponent } from '../pre-registration-payment/pre-registration-payment.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pre-registration-patient-info-focus',
  templateUrl: './pre-registration-patient-info-focus.component.html',
  styleUrls: ['./pre-registration-patient-info-focus.component.scss'],
})
export class PreRegistrationPatientInfoFocusComponent implements OnInit {
  isshowinsurance: boolean = false;
  ishideinsurance: boolean = true;
  isshowinsurance2: boolean = false;
  ishideinsurance2: boolean = true;

  isshowinsurance3: boolean = false;
  ishideinsurance3: boolean = true;

  isshowinsurance4: boolean = false;
  ishideinsurance4: boolean = true;

  isshowbtn: boolean = false;
  ishidebtn: boolean = true;

  isshowpay: boolean = false;
  ishidepay: boolean = true;

  bsModalRef!: BsModalRef;

  isFormFillOut: boolean = false;
  isFormFillOutBtn: boolean = false;

  isPatientFormOpened: boolean = false;
  isshowpaymentbtn:boolean=false;
  //   constructor() { }

  //   ngOnInit() {
 
  //   }
  showinsurance() {
    this.isshowinsurance = true;
    this.ishideinsurance = false;
  }

  showinsurance2() {
    this.isFormFillOutBtn = true;
    this.isshowinsurance2 = true;
    this.ishideinsurance2 = false;
  }

  showinsurance3() {
    this.isshowinsurance3 = true;
    this.ishideinsurance3 = false;
    this.isInClinicTabDoneFooter = false;
  }

  showinsurance4() {
    this.isshowinsurance4 = true;
    this.ishideinsurance4 = false;
  }

  showbtn(){
    this.isshowbtn = true;
    this.ishidebtn = false;
  }

  showpay(){
    this.isshowpay = true;
    this.ishidepay = false;
  }
  // showBasicEdit(){
  //   const showEdit = document.getElementById('patientEditBasicInfo');
  //   if(showEdit != null){
  //     if(showEdit.classList.contains('focus')){
  //       showEdit.classList.remove('focus');
  //       showEdit.classList.add('item-background-color')
  //     }
  //   }
  // }
  status: boolean = true;
  status2: boolean = false;
  status3: boolean = false;
  status4:boolean = false;

  unfocused() {
    this.status = !this.status;
    this.status2 = !this.status2;
  }

  unfocused2() {
    this.status2 = !this.status2;
    this.status3 = !this.status3;
  }
  
  unfocused3() {
    this.status3 = !this.status3;
  }

  // }   
  public stepType: StepType = "indicator";
  public stepTypes: Array<StepType> = ["indicator", "label", "full"];
  public current = 0;
  isshowcontinue: boolean = false;
  ishidecontinue: boolean = true;
  isshownote: boolean = false;
  ishidetext: boolean = true;
  isDesktop: boolean;
  
  
  breakpoint: any = window.matchMedia('(min-width: 767px)');


  isMobile!: boolean;
  isTablet!: boolean;
  isDesktopDevice!: boolean;
  deviceInfo: import("ngx-device-detector").DeviceInfo | undefined;

  constructor(private deviceService: DeviceDetectorService, private router: Router, private platform: Platform, private modalService: BsModalService ,public modalController: ModalController,) {
    this.isDesktop = !!this.breakpoint.matches;
    this.platform.keyboardDidShow.subscribe(() => {
      this.isInputFocus();
    });
  
    this.platform.keyboardDidHide.subscribe(() => {
      // Move input back to original location
      this.isInputNotFocus();
    });

    this.epicFunction();
  }

  ngOnInit() {
    
  }
  
  ngOnChanges() {
   
  }


  public steps = [
    { label: "Confirm your patient information", isValid: true, text: "1", },
    { label: "Update your insurance", isValid: false, text: "2", },
    { label: "Make a payment", isValid: true, text: "3", },
  ];

  public prev(): void {
    if (this.current > 0) {
      this.current -= 1;
    }
  }

  public next(): void {
    if (this.current < this.steps.length - 1) {
      this.current += 1;
    }
  }

  showcontinue() {
    this.isshowcontinue = true;
    this.ishidecontinue = false;
  }
  shownote() {
    this.isshownote = true;
    this.isshowcontinue = false;
    this.ishidetext = false;
  }
  editInfoOpened: boolean = false;
  isInfo: boolean = false;
  ispayment:boolean = false;
  isinsurance:boolean=false;
  isshowfooter:boolean=false;
  isabandoned:boolean=false;
  addstatus:boolean=true;
  isInClinicTabDoneFooter:boolean=true;
  openInfoPopup() {
    this.editInfoOpened = true;
    this.isInfo = true;
    this.ispayment =false;
    this.isinsurance=false;
    this.isabandoned=false;
    this.isshowfooter=true;
    this.addstatus=true;
    
  }
  openInsurancePopup(){
    this.editInfoOpened = true;
    this.isinsurance=true;
    this.isInfo = false;
    this.ispayment=false;
    this.isabandoned=false;
    this.isshowfooter=true;
    this.addstatus=true;
  } 
  openPaymentPopup(){
    this.editInfoOpened = true;
    this.isInfo = false;
    this.isinsurance=false;
    this.isabandoned=false;
    this.ispayment =true;
    this.isshowfooter=false;
    this.addstatus=false;
  }
  openAbandonedPopup(){
    this.editInfoOpened = true;
    this.isinsurance=false;
    this.isInfo = false;
    this.ispayment=false;
    this.isabandoned=true;
    this.isshowfooter=false;
  }
  onclose() {
    this.editInfoOpened = false;
  }
  onClose() {
    this.bsModalRef.hide();
  }
  //Btn Show
  mobilebtnshow(){
    var mobileshow = document.getElementById('mobileBtn');
    if(mobileshow != null){
      mobileshow.classList.add('showMobileBtn')
    }
  }
  mobilebtnshowpay(){
    var mobileshow = document.getElementById('mobileBtn2');
    if(mobileshow != null){
      mobileshow.classList.add('showMobileBtn')
    }
  }
  public showModal(data: any): void {
    this.bsModalRef = this.modalService.show(PreRegistrationTimeoutWarningComponent, {
      animated: true,
      backdrop: 'static',
      class: 'modal-sm'
    });
  }
  public showModal2(data:any):void{
    this.bsModalRef = this.modalService.show(PreRegistrationAbandonedComponent, {
      animated: true,
      backdrop: 'static',
      class: 'modal-sm'
    });
  }

  showFillFormSuccess(){
    this.isFormFillOut = true;
    this.showPatientForm();
  }

  hideFillFormSuccess(){
    this.isFormFillOut = false;
    this.showPatientForm();
  }
  showPaymentBtn(){
    this.status3 = false;
    this.status4 = true;
    this.isFormFillOut = true;
    this.isFormFillOutBtn = false;
    this.mobilebtnshowpay();
    
  }

  showPatientForm(){
    this.isPatientFormOpened = true;
  }

  hidePatientForm(){
    this.isPatientFormOpened = false;
  }
  hideformpopup(e:any){
    this.hidePatientForm()
  }

  isInputFocus(){
    const d = document.querySelector('.editInfo_popup .k-dialog') as HTMLInputElement;
    if(d != null){
      d.style.transition = 'all .2s ease-in-out'
      d.style.transform = "translateY(-100px)"
    } 
  }
  isInputNotFocus(){
    const d = document.querySelector('.editInfo_popup .k-dialog') as HTMLInputElement;
    if(d != null){
      d.style.transition = 'all .2s ease-in-out'
      d.style.transform = "translateY(0px)"
    } 
  }
  onClosepayment() {
    this.bsModalRef.hide();
  }
  public openpaymentpopup(data: any): void {
    this.bsModalRef = this.modalService.show(PreRegistrationPaymentComponent, {
      animated: true,
      backdrop: 'static',
      class: 'modal-sm'
    });
  }

  showpaymentbtn(){
    this.isshowpaymentbtn=true;
  }

  isPatientCardOpened:boolean = false;
  openPatientCard() {
    this.isPatientCardOpened = true;
  }
  closePatientCard(e: any){
    if(e == false){
      this.isPatientCardOpened = false;
    }
  }
  public accountList: Array<string> = [
    "Account number 1",
    "Account number 2"
  ];

  isReferPopup: boolean = false;
  openReferPopup(){
    this.isReferPopup = true;
    this.editInfoOpened = false;
  }
  closeReferPopup(){
    this.isReferPopup = false;
    this.editInfoOpened = false;
  }
  isPhysiciansPopup: boolean = false;
  openPhysiciansPopup(){
    this.isPhysiciansPopup = true;
    this.editInfoOpened = false;
  }
  closePhysicainPopup(){
    this.isPhysiciansPopup = false;
    this.editInfoOpened = false;
  }

  isPharmacyPopup: boolean = false;
  openPharmacyPopup(){
    this.isPharmacyPopup = true;
    this.editInfoOpened = false;
  }
  closePharmacyPopup(){
    this.isPharmacyPopup = false;
    this.editInfoOpened = false;
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    console.log("Mobile" +this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log("Tablet" +this.isTablet);  // returns if the device us a tablet (iPad etc)
    console.log("Desktop" +this.isDesktopDevice); // returns if the app is running on a Desktop browser.
  }
}
