import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clinic-dashboard',
  templateUrl: './clinic-dashboard.component.html',
  styleUrls: ['./clinic-dashboard.component.scss'],
})
export class ClinicDashboardComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
