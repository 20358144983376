<welcome-header></welcome-header>
<div class="mid-container main-div welcome_main main_container">
  <ion-grid class="mid-comment-border mid-header-section">
    <!-- <div class="px-30 py-50 heading" *ngIf="isDesktop">
      <ion-text class="h1 text-blue-color">Welcome, Charlotte</ion-text>
    </div> -->
    <div class="middle-div">
      <div class="p-20 ion-justify-content-center" *ngIf="!isDesktop">
        <div class="ion-text-center register-card column mb-20">
          <ion-text class="h3 text-green-color mb-5">You're pre-registered!</ion-text>
          <ion-text class="bodyM-txt ion-color-gray80" color="gray80">Be sure to let the front desk know
            when you’ve arrived for your appointment</ion-text>
        </div>
        <div class="ion-text-center">
          <ion-text class="bodyM-txt-bold text-blue-color mb-20">Here are your appointment details</ion-text>
        </div>
      </div>
      <div class="middle-div-mobile my-30">
        <div class="column align-items-right w-100" *ngIf="!isDesktop">
          <ion-text class="h3-M text-blue-color">Welcome, Charlotte</ion-text>
          <ion-text class="bodyS-txt ion-color-gray80 montserrat" *ngIf="ishidetext" color="gray80">Here are your
            appointment details</ion-text>
        </div>
      </div>
      <ion-col class="welcome-list column align-items-center">
         <!-- Tablet-in-clinic patient-view -->
         <div class="welcome_lock_view">
          <div class="d-flex justify-content-sb align-items-center py-30 pb-30 mb-50 borderBottom">
            <ion-icon src="../../../../assets/svg/Clock-Time.svg" class="mr-10"></ion-icon>
            <div class="d-flex align-items-baseline column">
              <div><ion-text class="number-txt montserrat" color="">Your appointment is scheduled for</ion-text></div>
              <div><ion-text class="time-text montserrat" color="secondary100">8:00am</ion-text></div>
              <div><ion-text class="montserrat" color="gray02">w/ <b>Dr Jessica Smith</b></ion-text></div>
            </div>
          </div>
        </div>
        <!--End of Tablet-in-clinic patient-view -->

        <ion-text class="bodyL-txt-light text-blue-color mb-25 tabletFont" *ngIf="isDesktop">Here are your appointment details</ion-text>
        <div class="box-wid">
          <div class="d-flex mb-15 middle-div-mobile">
            <div class="column welcome-lefticon-section align-items-center mr-15">
              <ion-icon class="ion-color-gray80 bodyXXL-txt mb-10" src="../../../../assets/svg/add-calender.svg"></ion-icon>
              <a class="bodyS-txt link-color" *ngIf="!isDesktop">Add to Calendar</a>
            </div>
            <div class="column welcome-right-section">
              <ion-text class="bodyL-txt text-color bodyM-txt-mobile" color="gray100">Wednesday, Dec 20, 2023</ion-text>
              <div class="time column pt-10">
                <ion-text class="bodyXXL-txt text-color line-45" color="gray100">8:00<span
                    class="h3 medium-font-weight text-color ml-5"> AM</span></ion-text>
                <a class="bodyS-txt link-color" *ngIf="isDesktop">Add to Calendar</a>
              </div>
            </div>
          </div>

          <hr class="seperator">

          <div class="d-flex mb-15 middle-div-mobile">
            <div class="column welcome-lefticon-section align-items-center mr-15">
              <ion-icon class="ion-color-gray80 bodyXXL-txt mb-10"
                src="../../../../assets/svg/open-maps.svg"></ion-icon>
              <a class="bodyS-txt link-color hide_desktop_patient_at_home" *ngIf="!isDesktop">Open in Maps</a>
            </div>
            <div class="column welcome-right-section">
              <ion-text class="bodyXL-txt-bold bodyL-txt-bold-mobile mb-5" color="gray100">Dr Jessica Smith</ion-text>
              <ion-text class="bodyL-txt bodyM-txt-mobile" color="gray100">
                <div class="column">
                  <ion-text> Downtown Heart Clinic </ion-text>
                  <ion-text> 2699 North Main Street</ion-text>
                  <ion-text> Suite 174 </ion-text>
                  <ion-text> Ann Arbor, MI 48103 </ion-text>
                </div>
                <a class="bodyS-txt link-color hide_desktop_patient_at_home" *ngIf="isDesktop">Open in Maps</a>
              </ion-text>
            </div>
          </div>
        </div>

        <hr class="seperator">

        <div class="column ion-text-center mb-20">
          <ion-text class="bodyM-txt ion-color-gray100 mb-5" color="gray100">Need to reschedule or cancel? </ion-text>
          <ion-text class="bodyM-txt ion-color-gray100" color="gray100">Call us at <a href="tel:(734)455-8888"
              class="link">(734) 455-8888 </a> </ion-text>
        </div>
      </ion-col>
      <ion-col class="column align-items-center ion-justify-content-center ">

        <!-- <div class="ion-text-center" *ngIf="isshownote">
    <ion-text class="bodyM-txt-bold text-blue-color mb-20">Here are your appointment details</ion-text>
  </div> -->

        <hr class="seperator">
        <div class="column stepper-card">
          <div class="stepper note" *ngIf="isDesktop">
            <div class="ion-text-center column mb-15">
              <ion-text class="h3-success text-green-color mb-15 tabletFont">You're pre-registered!</ion-text>
              <ion-text class="bodyM-txt" color="gray80">Be sure to let the front desk know
                when you’ve arrived for your appointment</ion-text>
            </div>
          </div>
          <div *ngIf="isDesktop" class="ion-text-center mb-15">
            <ion-button *ngIf="ishidecontinue" fill="solid" color="ion-color-white" class="filled-icon-btn capitalize"
              routerLink="/app/info-focus">
              <ion-text>Close</ion-text>
            </ion-button>
          </div>
          <div class="text-center"><ion-text class="h3-Montserrat text-blue-color">Ready to continue?</ion-text></div>
            <div class="text-center"><ion-text class="h3-Montserrat text-blue-color">We’d love it if you’d take care of a few things while you wait.</ion-text></div>
          <div class="column mb-5">
            
            <ion-text class="bodyM-txt-bold stepper-head text-blue-color tabletFont" *ngIf="ishidecontinue">Save time at
              check in by doing this today:</ion-text>
            <ion-text class="bodyM-txt-bold stepper-head text-blue-color tabletFont" *ngIf="isshowcontinue">Continue your
              registration process:</ion-text>

            <div class="stepper mt-30">
              <kendo-stepper *ngIf="ishidetext" class="bodyM-txt" [steps]="steps" [(currentStep)]="current"
                stepType="full" orientation="vertical">
              </kendo-stepper>
            </div>
            <div *ngIf="isDesktop" class="ion-text-center">
              <ion-button *ngIf="ishidecontinue" fill="solid" color="ion-color-white" class="filled-icon-btn capitalize"
                routerLink="/app/info-focus">
                <ion-text> Start</ion-text>
                <ion-icon src="../../../../../../../assets/svg/arrow-right.svg" class="ml-5"></ion-icon>
              </ion-button>
            </div>
          </div>
        </div>
      </ion-col>
    </div>
  </ion-grid>
</div>
<!-- Tablet-in-clinic patient-view -->
<div class="welcome_footer">
  <clinic-footer></clinic-footer>
</div>
<!--End of Tablet-in-clinic patient-view -->
<ion-footer class="align-items-center ion-background-color" *ngIf="!isDesktop">
  <div class="m-20" *ngIf="ishidecontinue">
    <ion-button fill="solid" expand="block" color="ion-color-white" class="filled-icon-btn capitalize"
      routerLink="/app/info-focus">
      <ion-text> Start</ion-text>
      <ion-icon src="../../../../../../../assets/svg/arrow-right.svg" class="ml-5"></ion-icon>
    </ion-button>
  </div>
</ion-footer>