import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { OtpLoginComponent } from './alt-views/login/otp-login/otp-login.component';
import { MainComponent } from './main/main.component';
import { OtpVerificationComponent } from './alt-views/login/otp-verification/otp-verification.component';
import { ClinicLockScreenComponent } from './main/tablet-in-clinic-view/clinic-lock-screen/clinic-lock-screen.component';
import { ConfirmPatientComponent } from './main/tablet-in-clinic-view/confirm-patient/confirm-patient.component';
import { ClinicDashboardComponent } from './main/tablet-in-clinic-view/clinic-dashboard/clinic-dashboard.component';
import { AuthGuard } from './alt-views/service/auth.guard';
import { ClinicauthGuard } from './alt-views/service/clinicauth.guard';
import { ClinicDashboardPreRegisteredPatientComponent } from './main/tablet-in-clinic-view/clinic-dashboard/component/clinic-dashboard-pre-registered-patient/clinic-dashboard-pre-registered-patient.component';
import { ClinicDashboardPracticeListComponent } from './main/tablet-in-clinic-view/clinic-dashboard/component/clinic-dashboard-practice-list/clinic-dashboard-practice-list.component';
import { LoginComponent } from './alt-views/login/login.component';
import { ForgotPasswordComponent } from './alt-views/login/forgot-password/forgot-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path:'app',
    canActivate:[AuthGuard],
    loadChildren: () =>
    import('./main/main.module').then( (m) => m.MainModule),

  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent

  },
  {
    path: 'mobile',
    component: OtpLoginComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'verify',
    component: OtpVerificationComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'clinic',
    component: ClinicLockScreenComponent
  },
  {
    path: 'practice-list',
    component: ClinicDashboardPracticeListComponent,
    canActivate:[ClinicauthGuard]
  },
  {
    path: 'confirm-patient',
    component: ConfirmPatientComponent,
    canActivate:[ClinicauthGuard]
  },
  {
    path: 'clinic-dashboard',
    component: ClinicDashboardComponent,
    canActivate:[ClinicauthGuard]
  },
  {
    path: 'pre-register-patient',
    component: ClinicDashboardPreRegisteredPatientComponent,
    canActivate:[ClinicauthGuard]
  },
  {
    path:'**',
    redirectTo: 'app',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
