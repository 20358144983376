import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
@Component({
  selector: 'app-pre-registration-patient-info-edit',
  templateUrl: './pre-registration-patient-info-edit.component.html',
  styleUrls: ['./pre-registration-patient-info-edit.component.scss'],
})
export class PreRegistrationPatientInfoEditComponent  implements OnInit {
  inactive: boolean = false;
  ismorecontact:boolean=false;
  hidebtn:boolean=true;
  isDesktop: boolean;
  breakpoint: any = window.matchMedia('(min-width: 767px)');

  public mask !: string;
  public mask2 !: string;
  public mask3 !: string;

  
  isMobile!: boolean;
  isTablet!: boolean;
  isDesktopDevice!: boolean;
  deviceInfo: import("ngx-device-detector").DeviceInfo | undefined;

  @Output() openRefer: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openPhysician: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openPharmacy: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private deviceService: DeviceDetectorService, private router: Router) {
    this.isDesktop = !!this.breakpoint.matches;
    this.epicFunction();
  }

  // public mask = "(999) 000-0000";
  // public mask = "Phone";

  ngOnInit(): void {
  }
  
  // Add Modal
  public opened = false;

  public close(): void {
    this.opened = false;
  }


  public registerForm: FormGroup = new FormGroup({
    lname:new FormControl(),
    mname:new FormControl(),
    fname:new FormControl(),
    email:new FormControl(),
    mobile:new FormControl(),
    home:new FormControl(),
    office:new FormControl(),
    emergency_contact:new FormControl(),
    emergency_mobile:new FormControl(),
  })

  showmore(){
    this.ismorecontact=true;
    this.hidebtn=false;
  }
  focus2() {
    this.mask  = "(999) 000-0000";
  }
  focus3() {
    this.mask2  = "(999) 000-0000";
  }
  focus4() {
    this.mask3  = "(999) 000-0000";
  }
  zipList:any = [
    {
      zip: 12861,
      city: 'Darlington'
    },
    {
      zip: 12861,
      city: 'Darlington ',
     
    },
    {
      zip: 12861,
      city: 'Darlington',
      
    }
  ]
  
  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    console.log("Mobile" +this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log("Tablet" +this.isTablet);  // returns if the device us a tablet (iPad etc)
    console.log("Desktop" +this.isDesktopDevice); // returns if the app is running on a Desktop browser.
  }
  openReferPopup(e: Event){
    this.openRefer.emit(true);
    this.close();
    if(this.isMobile){
      this.router.navigate(['/app/referrals']);
    }
  }

  openPhysiciansPopup(e: Event){
    this.openPhysician.emit(true);
    this.close();
    if(this.isMobile){
      this.router.navigate(['/app/physicians']);
    }
  }

  openPharmacyPopup(e: Event){
    this.openPharmacy.emit(true);
    this.close();
    if(this.isMobile){
      this.router.navigate(['/app/pharmacy']);
    }
  }
}