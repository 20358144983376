import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clinic-dashboard-pre-registered-patient',
  templateUrl: './clinic-dashboard-pre-registered-patient.component.html',
  styleUrls: ['./clinic-dashboard-pre-registered-patient.component.scss'],
})
export class ClinicDashboardPreRegisteredPatientComponent  implements OnInit {

  constructor() { }
  showText:string = 'show updated';
  isUpdated:boolean = false;
  markChecked:boolean = false;


  ngOnInit() {}
  showUpdated(){
    if(this.showText == 'show all'){
      this.showText = 'show updated';
      this.isUpdated = false;
    }else{
      this.showText = 'show all';
      this.isUpdated = true;
    }  
  }

  checkAll(){
    this.markChecked = !this.markChecked;
  }


}
