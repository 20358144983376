import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ClinicauthGuard implements CanActivate {


  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      let isLoggedIn = sessionStorage.getItem('isClinicLoggedIn');
      console.log("isLoggedIn --",isLoggedIn)
      if (isLoggedIn) {
        return true;
      } else {
        this.router.navigate(['/clinic']);
        return false;
      }
  }

}
