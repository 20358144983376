import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'self-scheduling-header',
  templateUrl: './self-scheduling-header.component.html',
  styleUrls: ['./self-scheduling-header.component.scss'],
})
export class SelfSchedulingHeaderComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
