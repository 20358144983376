
<kendo-dialog class="paymentpopup">
<div class="main-div ion-justify-content-center align-items-center note-box">
        <div class="payment-info mb-8 px-30 column">
            <ion-text class="h3-M bodyL-txt-bold-mobile tabletFont" color="gray100">
              Hey, you have an unfinished section.
            </ion-text>
            <ion-text class="h3-M bodyL-txt-mobile py-20 tabletFont" color="gray100">
              Do you want to go back?
            </ion-text>
          </div>
            <div class="btn-box w-100">
              <ion-button fill="solid"color="ion-color-white" class="filled-icon-btn capitalize w-100 mb-25">
                <ion-text>Yes, take me back</ion-text>
              </ion-button>
              <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize  w-100 " (click)="onClose()">
                <ion-text>No, I want to finish later</ion-text>
              </ion-button>
            </div>
  </div>
  </kendo-dialog>
