import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss'],
})
export class OtpVerificationComponent  implements OnInit {

  verifyBtn: boolean = false;
  isShowError:boolean=false;

  constructor(private router: Router) { }

  public otpform: FormGroup = new FormGroup({
    code:new FormControl(),
    fullName:new FormControl()
  });
  ngOnInit() {}
  enableVerify(){
    // this.verifyBtn = false;
  }

  verifyCode(formdata: any){

      this.router.navigateByUrl('/app/welcome');
  }



}
