<div class="" *ngIf="!isDesktop">
  <!-- <main-header></main-header> -->
  <div class="text-center py-20 w-100">
    <ion-text class="h3-M text-blue-color bodyL-txt-large-mobile tabletFont">Replace insurance card photo</ion-text>
  </div>
  <!-- <div class="text-center py-20 w-100">
    <ion-text class="h3-M text-blue-color">Add insurance card photo</ion-text>
  </div> -->
</div>
<div class="insurance-content">
  <div class="insurance_card d-flex flex-direction-column justify-content-center align-items-center ">
    <div class="text-center tfornt mb-52" *ngIf="istakefrontphoto" (click)="frontphoto()">
      <div class="column justify-content-center align-items-center h-100">
        <ion-icon class="h1 pb-24" src="../../../../../assets/svg/camera.svg"></ion-icon>
        <ion-text class="bodyL-txt text-HLblue-color">Take front photo</ion-text>
      </div>
    </div>

    <!-- <div class="text-center tfornt mb-52">
      <div class="column justify-content-center align-items-center h-100 d-block">
        <kendo-fileselect [multiple]="false"  class="bodyL-txt text-HLblue-color">
          <kendo-fileselect-messages dropFilesHere="" select="Upload front photo">
          </kendo-fileselect-messages>
      </kendo-fileselect>
      </div>
    </div> -->

    <div class="text-center mb-20" *ngIf="isfrontphoto">
      <div class="column justify-content-end align-items-end h-100">
        <ion-img class="img-card pb-8" src="../../../../../assets/svg/Front_Card.svg"></ion-img>
        <ion-icon class="h3 ion-color-gray80 mr-10" src="../../../../../assets/svg/trash-2.svg" (click)="openblank()"></ion-icon>
      </div>
    </div>

    <div class="text-center tfornt"  *ngIf="istakebackphoto" (click)="backphoto()">
      <div class="column justify-content-center align-items-center h-100">
        <ion-icon class="h1 pb-24" src="../../../../../assets/svg/camera.svg"></ion-icon>
        <ion-text class="bodyL-txt text-HLblue-color">Take back photo</ion-text>
      </div>
    </div>

    <div class="text-center mb-20" *ngIf="isbackphoto">
      <div class="column justify-content-end align-items-end h-100">
        <ion-img class="img-card pb-8" src="../../../../../assets/svg/Back_Card.svg"></ion-img>
        <ion-icon class="h3 ion-color-gray80 mr-10" src="../../../../../assets/svg/trash-2.svg" (click)="openbackblank()"></ion-icon>
      </div>
    </div>

    <!-- <div class="text-center tfornt">
      <div class="column justify-content-center align-items-center h-100">
        <kendo-fileselect [multiple]="false"  class="bodyL-txt text-HLblue-color">
          <kendo-fileselect-messages dropFilesHere="" select="Upload back photo">
          </kendo-fileselect-messages>
      </kendo-fileselect>
      </div>
    </div> -->
  </div>
</div>

<!-- <ion-footer class="ion-bg-unfocused-color page-bottom">
  <div class="px-30 py-10 row w-100 ion-justify-content-center align-items-center">
    <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn mr-15"
      routerLink="/app/insurance-edit">
      <ion-text>Cancel</ion-text>
    </ion-button>
    <ion-button fill="solid" expand="block" color="ion-color-white" class="filled-icon-btn capitalize info-btn">
      <ion-text>Done</ion-text>
    </ion-button>
  </div>
</ion-footer> -->