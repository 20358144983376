import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
@Component({
  selector: 'clinic-patient-lock-screen',
  templateUrl: './clinic-patient-lock-screen.component.html',
  styleUrls: ['./clinic-patient-lock-screen.component.scss'],
})
export class ClinicPatientLockScreenComponent  implements OnInit {

  @ViewChild('passwordInput')
  private passwordInput!: TextBoxComponent; 
  isIncorrect:boolean = true;
  isResetPasswordForm:boolean = true;
  isResetPassword:boolean = true;
  isForgetLink:boolean = true;
  public maxlength = 1;
  public currentDate = new Date();

  constructor() { }

  ngOnInit() {}
  public ngAfterViewInit() {
    this.passwordInput.input.nativeElement.type = 'password';
  }

  showIncorrectPin(){
    this.isIncorrect = false;
  }
  showForgetPass(){
    this.isResetPassword = false;
  }
  showBacktoLogin(){
    this.isResetPasswordForm = false;
  }
  showForgetLink(){
    this.isForgetLink = false;
  }
  backtoLogin(){
    this.isResetPassword = true;
  }
}
