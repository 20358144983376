import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-pre-registration-payment',
  templateUrl: './pre-registration-payment.component.html',
  styleUrls: ['./pre-registration-payment.component.scss'],
})
export class PreRegistrationPaymentComponent implements OnInit {
  inactive: boolean = false;
  ismorecontact: boolean = false;
  hidebtn: boolean = true;
  isDesktop: boolean;
  confirmation_popup:boolean=false;
  breakpoint: any = window.matchMedia('(min-width: 767px)');
  constructor(public modalController: ModalController, private bsModalRef: BsModalRef) {
    this.isDesktop = !!this.breakpoint.matches;
  }

  // public mask = "(999) 000-0000";
  // public mask = "Phone";

  ngOnInit(): void {
  }

  // Add Modal
  public opened = false;

  public close(): void {
    this.opened = false;
  }
  editInfoOpened: boolean = false;
  // onclose() {
  //   this.editInfoOpened = false;
  // }
  onClose() {
    this.bsModalRef.hide();
  }

  public registerForm: FormGroup = new FormGroup({

  })

  showmore() {
    this.ismorecontact = true;
    this.hidebtn = false;
  }

  public openconfirmation(): void {
    this.confirmation_popup=true;
  }

  openconfirmationclose(){
    this.confirmation_popup=false;
  }
  zipList:any = [
    {
      zip: 12861,
      city: 'Darlington'
    },
    {
      zip: 12861,
      city: 'Darlington ',
     
    },
    {
      zip: 12861,
      city: 'Darlington',
      
    }
  ]
  }