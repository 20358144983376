import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-confirm-patient',
  templateUrl: './confirm-patient.component.html',
  styleUrls: ['./confirm-patient.component.scss'],
})
export class ConfirmPatientComponent  implements OnInit {
  isConfirm:boolean = true;

  constructor() { }
  public confirmationform: FormGroup = new FormGroup({
    dob:new FormControl(),
  });
  ngOnInit() {}
  enableConfirm(){
    this.isConfirm = false;
  }
}
