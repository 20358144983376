import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AdaptiveMode } from "@progress/kendo-angular-dateinputs";
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-patient-information-popup',
  templateUrl: './patient-information-popup.component.html',
  styleUrls: ['./patient-information-popup.component.scss'],
})
export class PatientInformationPopupComponent  implements OnInit {
  inactive: boolean = false;
  ismorecontact:boolean=false;
  hidebtn:boolean=true;
  isDesktop: boolean;
  breakpoint: any = window.matchMedia('(min-width: 767px)');

  public mask !: string;
  public mask2 !: string;
  public mask3 !: string;


  constructor(private deviceService: DeviceDetectorService) {
    this.isDesktop = !!this.breakpoint.matches;
  }

  isMobile!: boolean;
  isTablet!: boolean;
  isDesktopDevice!: boolean;
  deviceInfo: import("ngx-device-detector").DeviceInfo | undefined;

  // public mask = "(999) 000-0000";
  // public mask = "Phone";

  ngOnInit(): void {
    this.epicFunction();
  }
  epicFunction() {
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    console.log("Mobile : " +this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log("Tablet : " +this.isTablet);  // returns if the device us a tablet (iPad etc)
    console.log("Desktop : " +this.isDesktopDevice); // returns if the app is running on a Desktop browser.
  }
  // Add Modal
  public opened = false;

  public close(): void {
    this.opened = false;
  }


  public registerForm: FormGroup = new FormGroup({
    lname:new FormControl(),
    mname:new FormControl(),
    fname:new FormControl(),
    email:new FormControl(),
    mobile:new FormControl(),
    home:new FormControl(),
    office:new FormControl(),
    emergency_contact:new FormControl(),
    emergency_mobile:new FormControl(),
  })

  showmore(){
    this.ismorecontact=true;
    this.hidebtn=false;
  }
  focus2() {
    this.mask  = "(999) 000-0000";
  }
  focus3() {
    this.mask2  = "(999) 000-0000";
  }
  focus4() {
    this.mask3  = "(999) 000-0000";
  }
  zipList:any = [
    {
      zip: 12861,
      city: 'Darlington'
    },
    {
      zip: 12861,
      city: 'Darlington ',
     
    },
    {
      zip: 12861,
      city: 'Darlington',
      
    }
  ]
  public adaptiveMode: AdaptiveMode = "none";
  public value: Date = new Date();

  isTextbox:boolean = false;
  isDigitalSign:boolean = true;
  isBold:boolean = false;

  showTextbox(e:any){
    if(e.target.checked){
      this.isTextbox = true;
      this.isBold = true;
    }else{
      this.isTextbox = false;
      this.isBold = false;
    }
  }
  showBoldText(e:any){
    if(e.target.checked){
      this.isBold = true;
      this.isDigitalSign = false;
    }else{
      this.isBold = false;
      this.isDigitalSign = true;
    }
  }
}
