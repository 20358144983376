import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'clinic-dashboard-patient-list',
  templateUrl: './clinic-dashboard-patient-list.component.html',
  styleUrls: ['./clinic-dashboard-patient-list.component.scss'],
})
export class ClinicDashboardPatientListComponent  implements OnInit {
  isNoResult:boolean =  false;
  constructor() { }

  ngOnInit() {}
  showNoResult(){
    this.isNoResult = true;
  }
}
